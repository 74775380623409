// eslint-disable-next-line no-restricted-imports
import {useToast} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {mixpanel} from '../../../utils/mixpanel';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {useCurrentAssetOverview} from '../../../hooks/current-asset-overview';
import {useGeolocation} from '../../../hooks/geolocation';
import {SellPieces} from '../overlays/sell-pieces';
import {ConfirmPrice} from '../overlays/confirm-price';
import {ConfirmAllSaleDetails} from '../overlays/confirm-all-sale-details';
import {RegisterPaymentDetails} from '../overlays/register-payment-details';
import {TransactionDrawerUnavailable} from './transaction-drawer-unavailable';
import {SELL_DRAWER_STEPS} from '../../../shared/consts';
import {
  useUserQuery,
  useAddListingMutation,
  useGetUserAssetsByIdQuery,
  useGetAllListingsByUserIdQuery,
  useAddToMailingListMutation,
  ListType,
  useUserTransactionsQuery,
  TransactionType,
} from '../../../graphql/generated';
import {
  SellPiecesForm,
  ConfirmPriceForm,
  PaymentRegistrationForm,
  termsAndConditionsForm,
} from '../forms/formValidationSchema';
import {SaleTermsAndConditions} from '../overlays/sell-terms-and-conditions';

// interface SellDrawerProps {}
export const SellDrawer: React.FC = () => {
  const {countryCode} = useGeolocation();
  const {drawerProduct, setDrawerLoading, resetDrawer} =
    useTransactionDrawerContext();
  const asset = useCurrentAssetOverview(drawerProduct as string);
  const user = useUserQuery();
  const userListings = useGetUserAssetsByIdQuery({
    variables: {assetId: drawerProduct as string},
    fetchPolicy: 'no-cache',
  });
  const [addListing] = useAddListingMutation();
  const allUserListings = useGetAllListingsByUserIdQuery({
    variables: {userId: user.data?.user.id},
    fetchPolicy: 'no-cache',
  });
  const {data: userTransactions} = useUserTransactionsQuery();
  const [addToMailingList] = useAddToMailingListMutation();
  const toast = useToast();
  const router = useRouter();

  // How we keep track of which of the forms to show
  const [step, setStep] = useState<SELL_DRAWER_STEPS | ''>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [maxItemsToSell, setMaxItemsToSell] = useState<number>(0);
  const [userPieces, setUserPieces] = useState<any[]>([]);
  const [hasPaidStripeFee, setHasPaidStripeFee] = useState<boolean>(false);
  const [collectPaymentDetails, setCollectPaymentDetails] =
    useState<boolean>(true);

  // How we keep track of each of the forms data
  const [sellFormData, setSellFormData] = useState<SellPiecesForm>(
    {} as SellPiecesForm,
  );
  const [confirmPrice] = useState<ConfirmPriceForm>({} as ConfirmPriceForm);
  const [paymentForm, setPaymentForm] = useState<PaymentRegistrationForm>(
    {} as PaymentRegistrationForm,
  );

  // Staring logic happens once the user and asset have been loaded
  useEffect(
    () => {
      if (!asset.loading && !user.loading && !userListings.loading) {
        allUserListings.refetch({userId: user.data?.user.id});
        setStep(SELL_DRAWER_STEPS.SELL);
        setMaxItemsToSell(
          userListings.data?.getUserAssetsById?.quantityAvailable || 0,
        );
        if (userListings.data?.getUserAssetsById?.pieces.length) {
          setUserPieces(userListings.data?.getUserAssetsById?.pieces);
        }
        if (setDrawerLoading) setDrawerLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [asset.loading, user.loading, userListings.loading],
  );

  useEffect(() => {
    if (!allUserListings.loading) {
      const {data} = allUserListings;
      if (data?.getAllListingsByUserId) {
        data.getAllListingsByUserId.forEach((item: any) => {
          if (item) {
            setCollectPaymentDetails(false);
          }
        });
      }
    }
  }, [allUserListings, allUserListings.loading]);

  useEffect(() => {
    if (
      userTransactions?.user?.transactions &&
      Array.isArray(userTransactions?.user?.transactions)
    ) {
      const saleTransaction = userTransactions.user.transactions.some(
        (itm) => itm.type === TransactionType.Sale,
      );
      if (saleTransaction) setHasPaidStripeFee(true);
    }
  }, [userTransactions]);

  const handleSellPiecesSubmit = (data: SellPiecesForm) => {
    mixpanel.track(`set price and quantity for listing for ${drawerProduct}`);
    setSellFormData(data);
    if (asset.lastPurchasedPrice) {
      if (data.amount < Number(asset.lastPurchasedPrice)) {
        setStep(SELL_DRAWER_STEPS.CONFIRM_PRICE);
      } else {
        setStep(
          collectPaymentDetails
            ? SELL_DRAWER_STEPS.PAYMENT_DETAILS
            : SELL_DRAWER_STEPS.CONFIRM,
        );
      }
    } else {
      setStep(
        collectPaymentDetails
          ? SELL_DRAWER_STEPS.PAYMENT_DETAILS
          : SELL_DRAWER_STEPS.CONFIRM,
      );
    }
  };

  const handleConfirmPriceSubmit = async (data: ConfirmPriceForm) => {
    mixpanel.track(`set price guard for ${drawerProduct}`);
    if (!data) return;
    setIsLoading(true);

    if (data.amount !== sellFormData.amount) {
      setSellFormData({
        ...sellFormData,
        amount: data.amount,
      });
    }

    setStep(
      collectPaymentDetails
        ? SELL_DRAWER_STEPS.PAYMENT_DETAILS
        : SELL_DRAWER_STEPS.CONFIRM,
    );

    setIsLoading(false);
  };

  const handlePaymentSubmit = async (data: PaymentRegistrationForm) => {
    mixpanel.track(`payment receipt information captured for ${drawerProduct}`);
    if (!data) return;
    setIsLoading(true);
    setPaymentForm(data);
    setIsLoading(false);
    setStep(SELL_DRAWER_STEPS.CONFIRM);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePostListing = async (data: termsAndConditionsForm) => {
    setIsLoading(true);
    if (sellFormData && sellFormData && paymentForm) {
      const listingDetailsToSubmit = {
        assetId: drawerProduct as string,
        price: sellFormData.amount,
        quantity: sellFormData.quantity,
        pieces: sellFormData.pieces,
        // address info
        countryCode: paymentForm.country,
        city: paymentForm.townOrCity,
        postcode: paymentForm.postCode,
        lineOne: paymentForm.addressLine1,
        lineTwo: paymentForm.addressLine2,
        // bankInfo
        accountNumber: paymentForm.accountNumber || '',
        routingNumber: paymentForm.routingNumber || '',
        IBAN: paymentForm.accountType === 'iban' ? paymentForm.swiftIban : '',
        // termsAndConditionsInfo
        crossBorderCharge: data.crossBorderCharge,
        currencyConversionAndStripe: data.currencyConversionAndStripe,
        fundsTimeline: data.fundsTimeline,
        noRefunds: data.noRefunds,
        sellingDirectToBuyer: data.sellingDirectToBuyer,
        termsAndConditions: data.termsAndConditions,
      };

      await addListing({
        variables: listingDetailsToSubmit,
      })
        .then(async (success) => {
          if (success) {
            if (user.data?.user.email && countryCode) {
              await addToMailingList({
                variables: {
                  email: user.data?.user.email,
                  countryCode,
                  listType: ListType.MARKETPLACE_SELLER,
                },
              });
              await addToMailingList({
                variables: {
                  email: user.data?.user.email,
                  countryCode,
                  listType: ListType.MARKETPLACE_USER,
                },
              });
            }
            mixpanel.track('Post on Marketplace');
            router.push('/my-account#my-listings');
            toast({
              status: 'success',
              position: 'bottom',
              title: 'Posted on the Marketplace!',
              description:
                'You can always access your listings from My Account',
              duration: 3000,
            });
            if (resetDrawer) resetDrawer();
          }
        })
        .catch((error) => {
          if (error && error.message) {
            setIsLoading(false);
            toast({
              status: 'error',
              title: 'Failed to post on Marketplace',
              description: error.message,
              duration: 3000,
            });
          }
        });
    }
  };

  return (
    <>
      {step === SELL_DRAWER_STEPS.SELL && !asset.loading && (
        <SellPieces
          isLoading={isLoading}
          defaultValues={sellFormData}
          asset={asset}
          onSubmit={handleSellPiecesSubmit}
          maxPieces={maxItemsToSell}
          pieces={userPieces}
        />
      )}
      {step === SELL_DRAWER_STEPS.CONFIRM_PRICE && (
        <ConfirmPrice
          isLoading={isLoading}
          defaultValues={confirmPrice}
          onSubmit={handleConfirmPriceSubmit}
          onBackClick={() => setStep(SELL_DRAWER_STEPS.SELL)}
          priceToConfirm={sellFormData.amount}
          lastPurchasePrice={
            asset.lastPurchasedPrice ? asset.lastPurchasedPrice : ''
          }
        />
      )}
      {step === SELL_DRAWER_STEPS.PAYMENT_DETAILS && (
        <RegisterPaymentDetails
          isLoading={isLoading}
          defaultValues={paymentForm}
          onSubmit={handlePaymentSubmit}
          onBackClick={() => setStep(SELL_DRAWER_STEPS.SELL)}
        />
      )}
      {step === SELL_DRAWER_STEPS.CONFIRM && (
        <ConfirmAllSaleDetails
          isLoading={isLoading}
          onSubmit={() => setStep(SELL_DRAWER_STEPS.TERMS_AND_CONDITIONS)}
          saleDetails={sellFormData}
          includeStripeFee={!hasPaidStripeFee}
          onBackClick={() => {
            setStep(
              user.data?.user.stripeAccountId
                ? SELL_DRAWER_STEPS.SELL
                : SELL_DRAWER_STEPS.PAYMENT_DETAILS,
            );
          }}
        />
      )}

      {step === SELL_DRAWER_STEPS.TERMS_AND_CONDITIONS && (
        <SaleTermsAndConditions
          isLoading={isLoading}
          onSubmit={handlePostListing}
          onBackClick={() => {
            setStep(SELL_DRAWER_STEPS.CONFIRM);
          }}
        />
      )}

      <TransactionDrawerUnavailable isActive={!(maxItemsToSell > 0)} />
    </>
  );
};

import React, {useState, useEffect, useRef, useCallback} from 'react';
import {VStack, Button, Select, Input} from '@chakra-ui/react';
import {InputCover} from './inputCover';
import {countries} from '../../../utils/country';
import {
  useValidPostcodeLazyQuery,
  useAddressLookupLazyQuery,
} from '../../../graphql/generated';
import {mixpanel} from '../../../utils/mixpanel';

interface InputPostcodeLookupProps {
  currentCountryCode?: string;
  stripePostCode?: string;
  countrySelectLabel?: string;
  addressSelectLabel?: string;
  buttonStyle?: string;
  onAddressChange?: (address: string) => void;
  onManualAddressSelect: (val: boolean) => void;
  onCountryChange: (val: string) => void;
}

export const InputPostcodeLookup: React.FC<InputPostcodeLookupProps> = ({
  currentCountryCode,
  onManualAddressSelect,
  onCountryChange,
  onAddressChange,
  stripePostCode,
  countrySelectLabel = 'Billing Address',
  addressSelectLabel,
  buttonStyle,
}) => {
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const [country, setCountry] = useState(currentCountryCode);
  const [postCode, setPostCode] = useState(stripePostCode);
  const [isValidPostcode, setIsValidPostCode] = useState(false);
  const [validatePostcode, postCodeData] = useValidPostcodeLazyQuery();
  const [addressLookup, addressData] = useAddressLookupLazyQuery();

  const handlePostcodeChange = useCallback(
    async (val: string) => {
      validatePostcode({variables: {code: val}});
      setPostCode(val);
    },
    [validatePostcode],
  );

  useEffect(() => {
    if (country) {
      onCountryChange(country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (stripePostCode) {
      handlePostcodeChange(stripePostCode);
    }
  }, [handlePostcodeChange, stripePostCode, validatePostcode]);

  useEffect(() => {
    // checks to see if the postcode is valid or is a test postcode
    if (
      postCode &&
      (postCodeData.data?.validPostcode || postCode.toUpperCase() === 'XX2 00X')
    ) {
      setIsValidPostCode(true);
      addressLookup({variables: {code: postCode}});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCodeData, postCode]);

  return (
    <VStack spacing={buttonStyle === 'aqua' ? 6 : 4} align="stretch">
      <InputCover
        label={countrySelectLabel}
        id="country"
        errorMessage="Please select a country"
        isInvalid={false}
      >
        <Select
          onChange={(e) => {
            setCountry(e.target.value);
          }}
          variant="basic"
          id="country"
          appearance="none"
          _focus={{appearance: 'none'}}
          defaultValue={country}
        >
          {countries.map(({name, code}) => {
            return (
              <option key={code} value={code}>
                {name}
              </option>
            );
          })}
        </Select>
      </InputCover>

      {!addressData.data?.addressLookup && (
        <InputCover
          label={addressSelectLabel || 'Search by post code'}
          id="PostCodeLookup"
          errorMessage="Postcode is invalid"
          isInvalid={false}
          visuallyHideLabel={!addressSelectLabel}
        >
          <Input
            variant="basic"
            id="postCodeLookup"
            name="postCodeLookup"
            type="text"
            placeholder="Search by post code"
            appearance="none"
            onChange={(e) => handlePostcodeChange(e.target.value)}
            _focus={{appearance: 'none'}}
            isDisabled={isValidPostcode}
          />
        </InputCover>
      )}
      {addressData.data?.addressLookup && (
        <InputCover
          label="Select Address"
          id="addressSelect"
          errorMessage="Please select a addressSelect"
          isInvalid={false}
          visuallyHideLabel={!addressSelectLabel}
        >
          <Select
            ref={selectRef}
            variant="basic"
            id="addressSelect"
            appearance="none"
            _focus={{appearance: 'none'}}
            onChange={(e) =>
              onAddressChange &&
              onAddressChange(
                `${e.target.value},${addressData.data?.addressLookup.postCode}`,
              )
            }
          >
            <option>Select address</option>
            {addressData.data?.addressLookup.addresses &&
              addressData.data?.addressLookup.addresses.map((address) => {
                return (
                  <option key={address} value={address as string}>
                    {address}
                  </option>
                );
              })}
          </Select>
        </InputCover>
      )}

      <Button
        onClick={() => {
          mixpanel.track('enter address manually');
          onManualAddressSelect(true);
        }}
        color={buttonStyle === 'aqua' ? 'aqua.500' : 'inherit'}
      >
        Enter my address manually
      </Button>
    </VStack>
  );
};

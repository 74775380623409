import {
  Box,
  Button,
  Input,
  Text,
  InputGroup,
  Flex,
  Image,
  InputLeftElement,
  Checkbox,
  InputRightElement,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useState} from 'react';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {InputCover} from '../forms/inputCover';
import {assetInfo} from '../../../utils/own-a-piece-hero';
import {AssetOverviewProps} from '../../../hooks/current-asset-overview';

import {
  sellPiecesFormSchema,
  SellPiecesForm,
} from '../forms/formValidationSchema';
import {UserPieces} from '../../../graphql/generated';

interface SellPiecesProps {
  onSubmit: (data: SellPiecesForm) => void;
  asset: AssetOverviewProps;
  pieces: any[];
  defaultValues?: SellPiecesForm;
  isLoading?: boolean;
  maxPieces?: number;
}

export const SellPieces: React.FC<SellPiecesProps> = ({
  onSubmit,
  asset,
  defaultValues,
  isLoading = false,
  pieces,
}) => {
  // const {currency, asset.pricing?.postFix, formatPrice} = useGeolocation();
  const [userPieces, setUserPieces] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [totalPieces, setTotalPieces] = useState<number>(0);

  const {setValue, register, handleSubmit, formState, trigger} =
    useForm<SellPiecesForm>({
      resolver: yupResolver(sellPiecesFormSchema),
      reValidateMode: 'onChange',
      mode: 'all',
      defaultValues: {
        ...defaultValues,
        quantity: defaultValues?.quantity ? defaultValues?.quantity : 0,
      },
    });

  useEffect(() => {
    if (pieces) {
      setUserPieces(pieces);
      setCheckedItems(
        pieces.map((piece) => {
          if (!defaultValues?.pieces) return false;

          return defaultValues.pieces.indexOf(piece.id) !== -1;
        }),
      );

      if (defaultValues?.pieces) setTotalPieces(defaultValues.pieces.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieces]);

  const {errors} = formState;

  const onFormSubmit = (data: SellPiecesForm) => onSubmit(data);

  const checkAll = () => {
    setValue('quantity', userPieces.length);

    setValue(
      'pieces',
      userPieces.map((piece) => piece.id),
    );

    setCheckedItems(userPieces.map(() => true));
    setTotalPieces(userPieces.length);
    trigger();
  };

  return (
    <Box background="white">
      {asset && asset.assetId && asset.assetName && (
        <TransactionDrawerHeader
          title={asset.assetName}
          img={assetInfo[asset.assetId].imgSrc}
          intro="All listings are removed from the marketplace if they do not sell after 30 days."
        />
      )}

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box mt={6}>
          <Box mt="42px">
            <Text textStyle="h6" mb={3}>
              Set Price
            </Text>
            <InputCover
              label="Price per piece"
              id="pricePerPiece"
              errorMessage={
                errors.amount?.message
                  ? `Price must be greater than ${asset.pricing?.symbol}0${asset.pricing?.postFix}`
                  : ''
              }
              isInvalid={!!errors.amount}
            >
              <InputGroup
                sx={{
                  '.chakra-input__left-element, , .chakra-input__right-element':
                    {
                      height: '100%',
                    },
                }}
              >
                <InputLeftElement pointerEvents="none">
                  {asset.pricing?.symbol}
                </InputLeftElement>
                <Input
                  {...register('amount')}
                  id="amount"
                  type="number"
                  variant="marketplace"
                  placeholder={
                    asset.lastPurchasedPrice
                      ? `${asset.lastPurchasedPrice}${asset.pricing?.postFix} (Your last purchase price)`
                      : ''
                  }
                />
                {asset.pricing?.postFix && (
                  <InputRightElement pointerEvents="none">
                    {asset.pricing?.postFix}
                  </InputRightElement>
                )}
              </InputGroup>
            </InputCover>
          </Box>

          <Box mt="42px">
            <Text textStyle="h6" mb={3}>
              Choose pieces to sell
            </Text>
            <Flex justify="center" align="center" flexWrap="wrap" pb="100px">
              <Flex w="100%" justify="space-between" mb="8px">
                <Text textStyle="body4" color="tone3">
                  {totalPieces} Selected
                </Text>
                <Text textStyle="body4" color="tone3">
                  You bought for
                </Text>
                <Text
                  textStyle="body4"
                  color="aqua.500"
                  cursor="pointer"
                  onClick={checkAll}
                >
                  Select all
                </Text>
              </Flex>
              <Flex
                w="100%"
                justify="center"
                align="center"
                flexWrap="wrap"
                overflowY="auto"
                pb="40px"
              >
                {userPieces.map((piece: UserPieces, index: number) => {
                  return (
                    <Flex
                      w="100%"
                      justify="space-between"
                      h="70px"
                      mb="8px"
                      px="18px"
                      borderRadius="16px"
                      backgroundColor="gray.300"
                      border="1px solid #EDEFF1"
                      align="center"
                      key={piece.id}
                    >
                      <Box>
                        <Image
                          w="32px"
                          h="32px"
                          src={`/assets/${asset.assetId}.png`}
                          alt={asset.assetName}
                        />
                      </Box>
                      <Text>
                        {piece.price?.symbol}
                        {piece.price?.formatPrice
                          ? piece.price.value.toFixed(2)
                          : piece.price.value}{' '}
                        {piece.price?.postFix}
                      </Text>
                      <Box>
                        <Checkbox
                          size="lg"
                          colorScheme="aqua"
                          borderColor="tone2"
                          _focus={{outline: 'none'}}
                          isChecked={checkedItems[index]}
                          onChange={(e) => {
                            const items = checkedItems;
                            items[index] = e.target.checked;
                            setCheckedItems(() => {
                              return [...items];
                            });

                            const totalItems = checkedItems.filter(
                              (item) => item === true,
                            ).length;
                            setValue('quantity', totalItems);
                            setTotalPieces(totalItems);

                            const allPieces: string[] = [];
                            checkedItems.map((value, pieceIndex) => {
                              if (value)
                                allPieces.push(userPieces[pieceIndex].id);

                              return value;
                            });

                            setValue('pieces', allPieces);
                            trigger();
                          }}
                        />
                      </Box>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </Box>
        </Box>
        <TransactionDrawerFooter>
          <Button
            colorScheme="aqua"
            height="56px"
            width="100%"
            type="submit"
            mt="22px"
            disabled={!formState.isValid}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </TransactionDrawerFooter>
      </form>
    </Box>
  );
};

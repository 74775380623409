import {
  Box,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {useGeolocation} from '../../../hooks/geolocation';
import {InputCover} from '../forms/inputCover';

import {
  confirmPriceFormSchema,
  ConfirmPriceForm,
} from '../forms/formValidationSchema';

interface ConfirmPriceProps {
  onSubmit: (data: ConfirmPriceForm) => void;
  onBackClick: () => void;
  defaultValues?: ConfirmPriceForm;
  isLoading?: boolean;
  lastPurchasePrice?: string;
  priceToConfirm?: number;
}

export const ConfirmPrice: React.FC<ConfirmPriceProps> = ({
  onSubmit,
  onBackClick,
  defaultValues,
  isLoading = false,
  lastPurchasePrice,
  priceToConfirm,
}) => {
  const {currency, currencyPostfix} = useGeolocation();
  const {register, handleSubmit, formState, watch} = useForm({
    resolver: yupResolver(confirmPriceFormSchema),
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues,
  });

  const amountFieldValue = watch('amount');

  const onFormSubmit = (data: ConfirmPriceForm) => onSubmit(data);

  return (
    <Box background="white">
      <TransactionDrawerHeader
        title={`Set price to ${currency}${priceToConfirm}${currencyPostfix}?`}
        intro="The price you set is below your last purchase price. Please re-enter the price you would like to sell at."
        backLink
        onBackClick={() => onBackClick && onBackClick()}
      />

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box mt={6} pb="190px">
          <Box mt="42px">
            <InputCover label="Price per piece" id="pricePerPiece">
              <InputGroup
                sx={{
                  '.chakra-input__left-element, .chakra-input__right-element': {
                    height: '100%',
                  },
                }}
              >
                <InputLeftElement pointerEvents="none">
                  {currency}
                </InputLeftElement>
                <Input
                  {...register('amount')}
                  id="amount"
                  type="number"
                  variant="marketplace"
                  placeholder={
                    lastPurchasePrice
                      ? `${lastPurchasePrice} (Your last purchase price)`
                      : ''
                  }
                />
                {currencyPostfix && (
                  <InputRightElement pointerEvents="none">
                    {currencyPostfix}
                  </InputRightElement>
                )}
              </InputGroup>
            </InputCover>
          </Box>
        </Box>
        <TransactionDrawerFooter variation="no-shadow">
          <Button
            colorScheme="aqua"
            height="56px"
            display="block"
            width="100%"
            type="submit"
            disabled={!formState.isValid}
            isLoading={isLoading}
          >
            {amountFieldValue
              ? `Continue at ${currency}${Number(amountFieldValue).toFixed(
                  2,
                )}${currencyPostfix} per Piece`
              : 'Continue'}
          </Button>

          <Button
            mt={2}
            colorScheme="aqua"
            height="56px"
            width="100%"
            variant="outline"
            onClick={() => onBackClick && onBackClick()}
            disabled={isLoading}
          >
            Back
          </Button>
        </TransactionDrawerFooter>
      </form>
    </Box>
  );
};

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useEffect, useState} from 'react';
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  useToast,
} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {useCurrentAssetOverview} from '../../../hooks/current-asset-overview';
import {
  useGetActiveListingsQuery,
  useAddOfferMutation,
  usePayOfferMutation,
  Listing,
  useGetAllListingsByUserIdQuery,
  useUserQuery,
} from '../../../graphql/generated';
import {NoPiecesAvailable} from '../overlays/no-pieces-available';
import {QuickBuyPayment} from '../overlays/quick-buy-payment';
import {QuickBuyPieces} from '../overlays/quick-buy-pieces';
import {TransactionDrawerSessionExpired} from './transaction-drawer-session-expired';
import {stripPublicKey} from '../../../utils/utils';
import {ReviewPaymentDetails} from '../overlays/review-payment-details';
import {AddressForm} from '../forms/formValidationSchema';
import {MARKETPLACE_SESSION_TIMEOUT, Routes} from '../../../shared/consts';
import {mixpanel} from '../../../utils/mixpanel';
import {useIFrameModalContext} from '../../../context/iframe-modal';

const stripePromise = loadStripe(stripPublicKey);
let setIntervalCache: any;
const clearTimer = () => setIntervalCache && clearInterval(setIntervalCache);

// interface QuickBuyDrawerProps {}
export const QuickBuyDrawer: React.FC = () => {
  const {
    drawerQuantity,
    drawerProduct,
    drawerListing,
    openDrawer,
    setDrawerLoading,
    resetDrawer,
    setPreventExit,
    updateQuantity,
  } = useTransactionDrawerContext();

  const {openModal} = useIFrameModalContext();
  const asset = useCurrentAssetOverview(drawerProduct as string);
  const user = useUserQuery();
  const allUserListings = useGetAllListingsByUserIdQuery({
    variables: {userId: user.data?.user.id},
    fetchPolicy: 'no-cache',
  });

  const assetListing = useGetActiveListingsQuery({
    variables: {assetId: drawerProduct as string},
    fetchPolicy: 'no-cache',
  });

  const router = useRouter();

  const [addOffer] = useAddOfferMutation();
  const [payOffer] = usePayOfferMutation();
  const toast = useToast();

  const [addressFormData, setAdressFormData] = useState<AddressForm>(
    {} as AddressForm,
  );
  const [stripeCardId, setStripeId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [basket, setBasket] = useState<Listing[] | []>([]);
  const [maxItems, setMaxItems] = useState(0);
  const [step, setStep] = useState<
    'buy' | 'payment' | 'paymentReview' | 'noItems'
  >('buy');

  const [sessionExpireTime, setSessionExpireTime] = useState(0);
  const [sessionIsExpired, setSessionIsExpired] = useState(false);
  const [sessionExpireWarning, setSessionExpireWarning] = useState(false);
  const [noUserListings, setNoUserListings] = useState<number | undefined>();

  useEffect(() => {
    if (!assetListing.loading && !asset.loading && openDrawer) {
      if (!assetListing.data?.getActiveListings) {
        setStep('noItems');
      }

      const filteredListings = assetListing.data?.getActiveListings?.filter(
        (listing) => listing && listing.id === drawerListing,
      );

      const allListings = drawerListing
        ? filteredListings
        : assetListing.data?.getActiveListings;

      if (allListings && asset.totalAssetPieces) {
        const maxListings = allListings?.length
          ? allListings.reduce((prev, curr) => {
              return curr ? prev + (curr.quantityAvailable || 0) : 0;
            }, 0)
          : 0;
        setMaxItems(maxListings);
      }
      if (setDrawerLoading) setDrawerLoading(asset.loading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetListing.loading, asset.loading, openDrawer, resetDrawer]);

  useEffect(() => {
    if (assetListing.loading && asset.loading && !openDrawer) return;
    let totalNumberOfProductsInTheBasket = 0;

    const filteredListings = assetListing.data?.getActiveListings?.filter(
      (listing) => listing && listing.id === drawerListing,
    );

    const allListings = drawerListing
      ? filteredListings
      : assetListing.data?.getActiveListings;

    if (drawerQuantity && drawerQuantity > 0 && allListings) {
      const itemsForTheBasket = allListings
        .map((item) => {
          const numberOfPiecesInItem = item?.quantityAvailable || 0;

          // if the total basket is less than needed, then lets look for more
          if (totalNumberOfProductsInTheBasket < drawerQuantity) {
            // First lets look to see if this items quantity is
            const quantityNeeded =
              drawerQuantity - totalNumberOfProductsInTheBasket;

            // Is there items still needed;
            if (quantityNeeded <= 0) return null;

            // If the pieces in item is not more that needed then add them all;
            if (numberOfPiecesInItem <= quantityNeeded) {
              totalNumberOfProductsInTheBasket += numberOfPiecesInItem;
              return {
                ...item,
                quantity: numberOfPiecesInItem,
              };
            }

            if (numberOfPiecesInItem > quantityNeeded) {
              totalNumberOfProductsInTheBasket += quantityNeeded;
            }

            return {
              ...item,
              quantity: quantityNeeded,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      if (setBasket) setBasket(itemsForTheBasket as []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerQuantity]);

  useEffect(() => {
    if (!allUserListings.loading) {
      const {data} = allUserListings;
      if (data?.getAllListingsByUserId) {
        setNoUserListings(data.getAllListingsByUserId[0]?.quantityAvailable);
      }
    }
  }, [allUserListings, allUserListings.loading]);

  useEffect(() => {
    if (sessionExpireTime) {
      clearTimer();
      let hasDisplayedSessionMessage = false;

      setIntervalCache = setInterval(() => {
        const now = new Date().getTime();
        const diff = sessionExpireTime - now;
        const timeRemaining = diff / 60000;

        if (
          timeRemaining < 2 &&
          timeRemaining > 1 &&
          !hasDisplayedSessionMessage
        ) {
          setSessionExpireWarning(true);
          hasDisplayedSessionMessage = true;
        } else if (timeRemaining < 0) {
          setSessionExpireWarning(false);
          setSessionIsExpired(true);
          clearTimer();
        }
      }, 30000);
    }

    return () => clearTimer();
  }, [sessionExpireTime]);

  useEffect(() => {
    if (setPreventExit) {
      if (['payment', 'paymentReview'].includes(step)) {
        setPreventExit(true);
      } else {
        setPreventExit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleQuickBuyPiecesSubmit = () => {
    mixpanel.track(`proceed to payment ${drawerProduct}`);
    setIsLoading(true);
    const addOfferDetails = {
      assetId: drawerProduct as string,
      quantity: drawerQuantity as number,
      listingId: drawerListing as string,
    };

    addOffer({variables: addOfferDetails})
      .then((success) => {
        if (success.data?.addOffer === 'Success!') {
          const now = new Date();
          setSessionExpireTime(
            new Date(
              now.getTime() + MARKETPLACE_SESSION_TIMEOUT * 60000,
            ).getTime(),
          );
          setStep('payment');
          setIsLoading(false);
        }
      })
      .catch((error: {message: any}) => {
        if (error && error.message) {
          setIsLoading(false);
          toast({
            status: 'error',
            title: 'Failed to reserve Pieces for purchase',
            description: error.message,
            duration: 3000,
          });
        }

        if (resetDrawer && updateQuantity) {
          resetDrawer();
          updateQuantity(0);
        }
      });
  };

  const handlePurchase = () => {
    setIsLoading(true);

    const payOfferDetails = {
      assetId: drawerProduct as string,
      cardToken: stripeCardId,
      countryCode: addressFormData.country,
      city: addressFormData.townOrCity,
      postcode: addressFormData.postCode,
      lineOne: addressFormData.addressLine1,
      lineTwo: addressFormData.addressLine2,
    };

    payOffer({
      variables: payOfferDetails,
    })
      .then((result) => {
        if (result.data && result.data.payOffer) {
          if (resetDrawer) resetDrawer();
          mixpanel.track(
            `qucikbuy purchase ${drawerQuantity} ${drawerProduct}`,
          );

          const {status, url, paymentId} = result.data.payOffer;

          switch (status) {
            case 'validation_required': {
              if (url) {
                if (openModal) {
                  openModal('stripe', url, {paymentId});
                }
              } else {
                toast({
                  status: 'error',
                  title: 'Failed to make the purchase on Marketplace',
                  description: 'Please contact admin.',
                  duration: 3000,
                });
              }

              break;
            }

            default:
            case 'success': {
              router.push(Routes.QUEUE);
              break;
            }
          }
        }
      })
      .catch((error) => {
        if (error && error.message) {
          if (error.message === 'Session has expired please start again.') {
            setSessionIsExpired(true);
          } else {
            toast({
              status: 'error',
              title: 'Failed to make the purchase on Marketplace',
              description: error.message,
              duration: 3000,
            });
          }
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      {step === 'buy' && (
        <QuickBuyPieces
          asset={asset}
          maxPieces={maxItems}
          basketItems={basket}
          onSubmit={() => handleQuickBuyPiecesSubmit()}
          isLoading={isLoading}
          userListings={noUserListings}
        />
      )}
      {step === 'payment' && (
        <Elements stripe={stripePromise}>
          <QuickBuyPayment
            defaultValues={addressFormData}
            onSubmit={(formData, stripeId) => {
              mixpanel.track(`submits card payment details ${drawerProduct}`);
              setAdressFormData(formData);
              setStripeId(stripeId);
              setStep('paymentReview');
            }}
            onBackClick={() => {
              setStep('buy');
            }}
          />
        </Elements>
      )}
      {step === 'paymentReview' && (
        <Box>
          <ReviewPaymentDetails
            isLoading={isLoading}
            basketItems={basket}
            onBackClick={() => {
              setStep('payment');
            }}
            onSubmit={() => handlePurchase()}
          />
        </Box>
      )}
      {step === 'noItems' && (
        <Box>
          <NoPiecesAvailable />
        </Box>
      )}

      <TransactionDrawerSessionExpired
        isActive={sessionIsExpired}
        title="Session Expired"
        details="Your session has expired and the Pieces you were holding have been lost."
        ctaLabel="Return to Quick Buy"
        onReturnClick={() => {
          setSessionIsExpired(false);
          setStep('buy');
          setSessionExpireTime(0);
        }}
      />

      <TransactionDrawerSessionExpired
        isActive={sessionExpireWarning}
        title="Your session is about to expire"
        details="Your session will expire in <strong>1 minute</strong>"
        ctaLabel="Return to Payment"
        onReturnClick={() => {
          setSessionExpireWarning(false);
          if (step !== 'payment') setStep('payment');
        }}
      />
    </>
  );
};

import {Box} from '@chakra-ui/react';
import React from 'react';

interface TransactionDrawerFooterProps {
  variation?: 'top-compact' | 'no-shadow';
  children: React.ReactNode;
}

export const TransactionDrawerFooter: React.FC<
  TransactionDrawerFooterProps
> = ({children, variation}) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      width="100%"
      px="48px"
      py="40px"
      boxShadow={
        variation === 'no-shadow' ? '' : '0px 12px 34px rgba(0, 0, 0, 0.1)'
      }
      background="white"
      pt={variation === 'top-compact' ? 4 : ''}
    >
      {children}
    </Box>
  );
};

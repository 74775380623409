import {useEffect, useState, useMemo} from 'react';
import {useGeoLookupQuery} from '../graphql/generated';
import {
  SUPPORTED_COUNTRIES_BY_STRIPE,
  TRUSTPILOT_COUNTRIES,
} from '../shared/consts';

type UseGeolocationHook = {
  currency?: string;
  currencyCode?: string;
  currencyPostfix?: string;
  formatPrice?: boolean;
  isLoadingGeolocation: boolean;
  country?: string;
  countryCode?: string;
  canSellOnTheMarketplace: boolean;
  canBuyOnTheMarketplace: boolean;
  paymentMethod: string;
  triggerKYCAmount: number;
  stripeMonthlyFee: number;
  countrySupportedByStripe: boolean;
  showTrustpilotWidget: boolean;
};

export const useGeolocation = (): UseGeolocationHook => {
  const {data, loading} = useGeoLookupQuery();

  const [currency, setCurrency] = useState<{
    currencyPostfix?: string;
    currency?: string;
    currencyCode?: string;
    formatPrice?: boolean;
  }>({});

  const [country, setCountry] = useState<{
    country?: string;
    countryCode?: string;
  }>({});

  const [market, setMarket] = useState<{
    canSellOnTheMarketplace: boolean;
    canBuyOnTheMarketplace: boolean;
    stripeMonthlyFee: number;
    countrySupportedByStripe: boolean;
  }>({
    canSellOnTheMarketplace: true,
    canBuyOnTheMarketplace: true,
    stripeMonthlyFee: 2,
    countrySupportedByStripe: true,
  });

  const [triggerKYCAmount, setTriggerKYCAmount] = useState<number>(0);
  const [showTrustpilotWidget, setShowTrustpilotWidget] =
    useState<boolean>(false);

  const [paymentMethod, setPaymentMethod] = useState<string>('none');

  const [isLoadingGeolocation, setIsLoadingGeolocation] = useState(true);

  useEffect(() => {
    if (!loading && data?.getGeolocation) {
      setCurrency({
        currency: data?.getGeolocation.currencySymbol,
        currencyCode: data?.getGeolocation.currencyCode,
        currencyPostfix: data?.getGeolocation.currencyPostfix || '',
        formatPrice: data?.getGeolocation.formatPrice,
      });
      setCountry({
        country: data?.getGeolocation.countryName,
        countryCode: data?.getGeolocation.countryCode,
      });
      setMarket({
        canSellOnTheMarketplace: data?.getGeolocation.canSellOnTheMarketplace,
        canBuyOnTheMarketplace: data?.getGeolocation.canBuyOnTheMarketplace,
        stripeMonthlyFee: data?.getGeolocation.stripeMonthlyFee,
        countrySupportedByStripe: SUPPORTED_COUNTRIES_BY_STRIPE.includes(
          data?.getGeolocation.countryCode,
        ),
      });
      setPaymentMethod(data.getGeolocation.paymentMethod);
      setIsLoadingGeolocation(loading);
      setTriggerKYCAmount(data.getGeolocation.triggerKYCAmount);
      setShowTrustpilotWidget(
        TRUSTPILOT_COUNTRIES.includes(data?.getGeolocation.countryCode),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return useMemo(() => {
    return {
      ...currency,
      ...country,
      ...market,
      paymentMethod,
      isLoadingGeolocation,
      triggerKYCAmount,
      showTrustpilotWidget,
    };
  }, [
    country,
    currency,
    isLoadingGeolocation,
    market,
    paymentMethod,
    triggerKYCAmount,
    showTrustpilotWidget,
  ]);
};

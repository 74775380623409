import {useState, useEffect} from 'react';
import {
  Box,
  Text,
  Input,
  SimpleGrid,
  VStack,
  Button,
  Select,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {InputCover} from '../forms/inputCover';
import {countries} from '../../../utils/country';
import {InputPostcodeLookup} from '../forms/inputPostcodeLookup';
import {
  paymentRegistrationFormSchema,
  PaymentRegistrationForm,
} from '../forms/formValidationSchema';
import {useGeolocation} from '../../../hooks/geolocation';
import {useValidIbanLazyQuery} from '../../../graphql/generated';

export interface RegisterPaymentDetailsProps {
  onSubmit: (data: PaymentRegistrationForm) => void;
  onBackClick?: () => void;
  defaultValues?: PaymentRegistrationForm;
  isLoading?: boolean;
}

export const RegisterPaymentDetails: React.FC<RegisterPaymentDetailsProps> = ({
  onBackClick,
  onSubmit,
  defaultValues,
  isLoading = false,
}) => {
  const {countryCode, paymentMethod, isLoadingGeolocation} = useGeolocation();
  const [showFullAddress, setShowFullAddress] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<string>(paymentMethod);
  const [validIBAN, IBANData] = useValidIbanLazyQuery();
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    trigger,
    watch,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(paymentRegistrationFormSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues,
  });

  const paymentType = watch('accountType');

  useEffect(() => {
    if (!isLoadingGeolocation) {
      setValue('accountType', paymentMethod);
      setDefaultPaymentMethod(paymentMethod);
    }
  }, [isLoadingGeolocation, paymentMethod, setValue]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!IBANData.loading && IBANData.data) {
      if (IBANData.data.validIBAN) return clearErrors('swiftIban');

      setError('swiftIban', {
        message:
          'Your IBAN does not match the expected length requirements for your country',
      });
    }
  }, [IBANData, clearErrors, setError]);

  useEffect(() => {
    if (defaultValues && defaultValues.addressLine1?.length) {
      setShowFullAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (data: PaymentRegistrationForm) => onSubmit(data);

  const addressChangeHandler = (address: string) => {
    // console.log(address);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [line1, line2, line3, line4, locality, town, county, postCode] =
      address.split(',');
    setShowFullAddress(true);
    setValue('addressLine1', `${line1} ${line2}`);
    setValue('addressLine2', `${line3} ${line4}`);
    setValue('townOrCity', town);
    setValue('postCode', postCode);
    trigger(['country', 'addressLine1', 'addressLine2', 'postCode']);
  };

  const handleChangeIBAN = (e: any) => {
    const {value} = e.target;
    validIBAN({
      variables: {
        iban: value,
      },
    });
  };

  return (
    <Box>
      <TransactionDrawerHeader
        title="Payment"
        intro="What account would you like to receive payments? We do not store your card information."
        backLink
        onBackClick={() => onBackClick && onBackClick()}
      />
      <Box mt={6} pb="190px">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box mb="15px">
            <InputCover label="Choose account type" id="accountType">
              <Select
                variant="marketplace"
                {...register('accountType')}
                id="accountType"
                appearance="none"
                _focus={{appearance: 'none'}}
                defaultValue={defaultPaymentMethod}
              >
                <option value="none">Select option</option>
                <option value="sortcode">Account Number & Sort Code</option>
                <option value="iban">IBAN</option>
                <option value="routing">Account Number & Routing Number</option>
                {/* <option value="swift">Swift / BIC</option> */}
              </Select>
            </InputCover>
          </Box>

          <Box>
            {/* Card details */}

            {['sortcode', 'routing'].includes(paymentType) && (
              <VStack spacing="15px" align="stretch">
                <InputCover
                  label="Account number"
                  id="accountNumber"
                  errorMessage={formState.errors.accountNumber?.message}
                  isInvalid={!!formState.errors.accountNumber}
                >
                  <Input
                    variant="marketplace"
                    {...register('accountNumber')}
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    placeholder="e.g 283628465"
                  />
                </InputCover>

                <InputCover
                  label={
                    paymentType === 'sortcode' ? 'Sort code' : 'Routing number'
                  }
                  id="routingNumber"
                  errorMessage={formState.errors.routingNumber?.message}
                  isInvalid={!!formState.errors.routingNumber}
                >
                  <Input
                    variant="marketplace"
                    {...register('routingNumber')}
                    id="routingNumber"
                    type="text"
                    placeholder="e.g 24-15-68"
                  />
                </InputCover>
              </VStack>
            )}

            {['iban', 'swift'].includes(paymentType) && (
              <VStack spacing="15px" align="stretch">
                <InputCover
                  label={
                    paymentType === 'swift'
                      ? 'Swift / BIC Number'
                      : 'IBAN number'
                  }
                  id="swiftIban"
                  errorMessage={formState.errors.swiftIban?.message}
                  isInvalid={!!formState.errors.swiftIban}
                >
                  <Input
                    variant="marketplace"
                    {...register('swiftIban')}
                    id="swiftIban"
                    type="text"
                    placeholder={
                      paymentType === 'swift'
                        ? 'e.g BUKBGB22'
                        : 'e.g GB98 MIDL 0700 9312 3456'
                    }
                    onChange={handleChangeIBAN}
                  />
                </InputCover>
              </VStack>
            )}

            {paymentType !== 'none' && (
              <>
                <Box display={!showFullAddress ? 'block' : 'none'} mt="25px">
                  <InputPostcodeLookup
                    onManualAddressSelect={(val) => setShowFullAddress(val)}
                    currentCountryCode={countryCode}
                    onCountryChange={(val: string) => {
                      setValue('country', val);
                      setShowFullAddress(val !== 'GB');
                      setSelectedCountry(val);
                    }}
                    onAddressChange={addressChangeHandler}
                  />
                </Box>

                {/* Billing address part 2 */}
                <VStack
                  spacing="12px"
                  mt="25px"
                  display={showFullAddress ? 'block' : 'none'}
                >
                  <Text color="tone3" mb={-3}>
                    Billing Address
                  </Text>

                  <InputCover
                    label="Country"
                    id="country"
                    errorMessage="Please select a country"
                    isInvalid={false}
                    visuallyHideLabel
                  >
                    <Select
                      variant="marketplace"
                      {...register('country')}
                      id="country"
                      appearance="none"
                      _focus={{appearance: 'none'}}
                      defaultValue="GB"
                      onChange={(e) => {
                        setShowFullAddress(e.target.value !== 'GB');
                        setSelectedCountry(e.target.value);
                      }}
                    >
                      {countries.map(({name, code}) => {
                        return (
                          <option key={name} value={code}>
                            {name}
                          </option>
                        );
                      })}
                    </Select>
                  </InputCover>

                  <InputCover
                    label="Address Line 1"
                    id="addressLine1"
                    errorMessage="Please enter Address Line 1"
                    isInvalid={false}
                    visuallyHideLabel
                  >
                    <Input
                      variant="marketplace"
                      {...register('addressLine1')}
                      id="addressLine1"
                      type="text"
                      placeholder="Address Line 1"
                    />
                  </InputCover>
                  <InputCover
                    label="Address Line 2"
                    id="addressLine2"
                    errorMessage="Please enter Address Line 2"
                    isInvalid={false}
                    visuallyHideLabel
                  >
                    <Input
                      variant="marketplace"
                      {...register('addressLine2')}
                      id="addressLine2"
                      type="text"
                      placeholder="Address Line 2"
                      isInvalid={false}
                    />
                  </InputCover>

                  <SimpleGrid columns={2} spacing="10px">
                    <InputCover
                      label="Town or City"
                      id="townOrCity"
                      errorMessage="Please enter Town or City"
                      isInvalid={false}
                      visuallyHideLabel
                    >
                      <Input
                        variant="marketplace"
                        {...register('townOrCity')}
                        id="townOrCity"
                        type="text"
                        placeholder="Town or City"
                      />
                    </InputCover>
                    <InputCover
                      label="Post Code"
                      id="postCode"
                      errorMessage="Please enter Post Code"
                      isInvalid={false}
                      visuallyHideLabel
                    >
                      <Input
                        variant="marketplace"
                        {...register('postCode')}
                        id="postCode"
                        type="text"
                        placeholder={
                          selectedCountry !== 'US' ? 'Post Code' : 'Zip Code'
                        }
                      />
                    </InputCover>
                  </SimpleGrid>
                </VStack>
              </>
            )}
          </Box>

          <TransactionDrawerFooter variation="top-compact">
            <Button
              colorScheme="aqua"
              height="56px"
              width="100%"
              type="submit"
              isDisabled={!formState.isValid}
              isLoading={isLoading}
            >
              Review listing
            </Button>
          </TransactionDrawerFooter>
        </form>
      </Box>
    </Box>
  );
};

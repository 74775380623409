import '../utils/polyfill-from-entries';

import {DefaultSeo} from 'next-seo';
import {ApolloProvider} from '@apollo/client';
import {ChakraProvider} from '@chakra-ui/react';
import {AppProps} from 'next/app';
import Head from 'next/head';
import {ReactElement, ReactNode} from 'react';
import {NextPage} from 'next';
import {BasketProvider} from '../hooks/basket-context';
import {CognitoProvider} from '../hooks/cognito';
import {client} from '../graphql/client';
import {theme} from '../style/theme';
import {useLinkTrackingEffect, usePageViewEffect} from '../utils/mixpanel';
import {seoConfig} from '../seo.config';
import {useInitPixelEffect} from '../utils/facebook-pixel';
import {GlobalAnalyticsSetup} from '../components/global/global-analytics-setup';
import {TransactionDrawerProvider} from '../context/transaction-drawer';
import {TransactionDrawer} from '../components/marketplace/transaction-drawer/transaction-drawer';
import {IFrameModalProvider} from '../context/iframe-modal';
import {IFrameModal} from '../components/modals/iframe';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ShowpieceApp: React.FC<AppProps> = ({
  Component,
  pageProps,
}: AppPropsWithLayout) => {
  useLinkTrackingEffect();
  useInitPixelEffect();
  usePageViewEffect();

  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head>
        <script
          async
          type="text/javascript"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: /* js */ `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NRSWDQN');`,
          }}
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Allison&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital@1&family=EB+Garamond:wght@400;600&family=PT+Serif:wght@400;700&family=Spectral&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        {process.env.STAGE === 'production' ? (
          <meta
            name="facebook-domain-verification"
            content="74sxxnxuj21vvjibqglhq8ikd5g76w"
          />
        ) : (
          <meta
            name="facebook-domain-verification"
            content="crscrgzo8twl3qolmtyuz4f9c0ktsi"
          />
        )}
        <meta name="theme-color" content="#ffffff" />
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/25252510.js"
        />
        <script
          type="text/javascript"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: /* js */ `var cpm = {};
            (function(h,u,b){
            var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
            e.async=true;e.src='https://cookiehub.net/c2/9fe03e9e.js';
            e.onload=function(){u.cookiehub.load(b);}
            d.parentNode.insertBefore(e,d);
            })(document,window,cpm);
            `,
          }}
        />
        <script
          type="text/javascript"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: /* js */ `
            !function (w, d, t) {
		          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
              ttq.load('C675BJ0T2DVVO2LQV41G');
              ttq.page();
            }(window, document, 'ttq');
            `,
          }}
        />
        {process.env.STAGE === 'production' ? (
          <script
            defer
            data-domain="showpiece.com"
            src="https://plausible.io/js/plausible.js"
          />
        ) : (
          <>
            <script
              defer
              data-domain="showpiece-staging.vercel.app"
              src="https://plausible.io/js/plausible.js"
            />
            <script
              defer
              data-domain="showpiece-staging.vercel.app"
              src="https://plausible.io/js/script.local.js"
            />
          </>
        )}
        {/* <script */}
        {/*  type="text/javascript" */}
        {/*  src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" */}
        {/*  async */}
        {/* /> */}
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
          (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2698097,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Head>
      <noscript
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NRSWDQN"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <DefaultSeo {...seoConfig} />
      <ApolloProvider client={client}>
        <CognitoProvider>
          <ChakraProvider theme={theme}>
            <BasketProvider>
              <IFrameModalProvider>
                <TransactionDrawerProvider>
                  <GlobalAnalyticsSetup />
                  {getLayout(<Component {...pageProps} />)}
                  <TransactionDrawer />
                  <IFrameModal />
                </TransactionDrawerProvider>
              </IFrameModalProvider>
            </BasketProvider>
          </ChakraProvider>
        </CognitoProvider>
      </ApolloProvider>
    </>
  );
};

export default ShowpieceApp;

import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {useEffect, useState} from 'react';
import {
  Box,
  Text,
  Input,
  SimpleGrid,
  VStack,
  Button,
  Select,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {InputCover} from '../forms/inputCover';
import {countries} from '../../../utils/country';
import {InputPostcodeLookup} from '../forms/inputPostcodeLookup';
import {addressFormSchema, AddressForm} from '../forms/formValidationSchema';
import {useGeolocation} from '../../../hooks/geolocation';

export interface QuickBuyPaymentProps {
  onSubmit?: (address: AddressForm, stripeId: string) => void;
  onBackClick?: () => void;
  defaultValues?: AddressForm;
  isLoading?: boolean;
}

export const QuickBuyPayment: React.FC<QuickBuyPaymentProps> = ({
  onBackClick,
  defaultValues,
  isLoading = false,
  onSubmit,
}) => {
  const {currencyCode} = useGeolocation();
  const [showFullAddress, setShowFullAddress] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [stripeError, setStripeError] = useState('');
  const [stripePostCode, setStripePostCode] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [cardElement, setCardElement] = useState<any>();
  const {setValue, register, handleSubmit, formState, trigger, reset} = useForm(
    {
      resolver: yupResolver(addressFormSchema),
      reValidateMode: 'onChange',
      mode: 'all',
      defaultValues,
    },
  );

  const stripe = useStripe();
  const elements = useElements();

  const addressChangeHandler = (address: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [line1, line2, line3, line4, locality, town, county, postCode] =
      address.split(',');
    setShowFullAddress(true);
    setValue('addressLine1', `${line1} ${line2}`);
    setValue('addressLine2', `${line3} ${line4}`);
    setValue('townOrCity', town);
    setValue('postCode', postCode);
    trigger();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (elements) {
      const card = elements.getElement('card');
      if (card) {
        card.on('change', (event) => {
          if (event.value.postalCode) {
            setStripePostCode(event.value.postalCode);
          }
        });
      }
      setCardElement(card);
    }
  }, [elements, isLoading]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFormSubmit = (data: AddressForm) => {
    setIsSending(true);
    if (!stripe || !elements) return null;

    if (!cardElement) return null;

    if (!currencyCode) return null;

    stripe
      .createToken(cardElement, {currency: currencyCode.toUpperCase()})
      .then((result) => {
        if (result.error && result.error.message) {
          setStripeError(result.error.message);
        }

        if (result.token && result.token.id) {
          if (onSubmit) onSubmit(data, result.token.id);
        }

        reset();
        setIsSending(false);
        cardElement.clear();
      });
    return '';
  };

  return (
    <Box>
      <TransactionDrawerHeader
        title="Payment"
        intro="Your pieces are being held! Please enter your card details to pay for the purchase. We do not store your card details."
        backLink={!!onBackClick}
        onBackClick={() => {
          reset();
          if (onBackClick) onBackClick();
        }}
      />
      <Box mt={6} pb="190px">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box>
            {/* Card details */}
            <Box
              bg="white"
              borderRadius="14px"
              border="1px solid"
              borderColor="tone2"
              padding="15px 16px"
            >
              <CardElement />
            </Box>
            <Text
              color="stampRed"
              textStyle="body6_1c"
              fontFamily="Poppins"
              mt={1}
              mb={1}
              h={7}
            >
              {stripeError}
            </Text>
            <Text textStyle="body6" mt="20px">
              We need to capture your full address to process this payment.
            </Text>

            {/* Billing address part 1 */}
            <Box display={!showFullAddress ? 'block' : 'none'} mt="5px">
              <InputPostcodeLookup
                onManualAddressSelect={(val) => setShowFullAddress(val)}
                currentCountryCode="GB"
                onCountryChange={(val: string) => {
                  setValue('country', val);
                  setShowFullAddress(val !== 'GB');
                  setSelectedCountry(val);
                }}
                onAddressChange={addressChangeHandler}
                stripePostCode={stripePostCode}
              />
            </Box>

            {/* Billing address part 2 */}
            <VStack
              spacing="12px"
              mt="25px"
              display={showFullAddress ? 'block' : 'none'}
            >
              <Text color="tone3" mb={-3}>
                Billing Address
              </Text>

              <InputCover
                label="Country"
                id="country"
                errorMessage="Please select a country"
                isInvalid={false}
                visuallyHideLabel
              >
                <Select
                  variant="marketplace"
                  {...register('country')}
                  id="country"
                  appearance="none"
                  _focus={{appearance: 'none'}}
                  defaultValue="GB"
                  onChange={(e) => {
                    setShowFullAddress(e.target.value !== 'GB');
                    setSelectedCountry(e.target.value);
                  }}
                >
                  {countries.map((item) => {
                    return (
                      <option key={item.name} value={item.code}>
                        {item.name}
                      </option>
                    );
                  })}
                </Select>
              </InputCover>

              <InputCover
                label="Address Line 1"
                id="addressLine1"
                errorMessage="Please enter Address Line 1"
                isInvalid={false}
                visuallyHideLabel
              >
                <Input
                  variant="marketplace"
                  {...register('addressLine1')}
                  id="addressLine1"
                  type="text"
                  placeholder="Address Line 1"
                />
              </InputCover>
              <InputCover
                label="Address Line 2"
                id="addressLine2"
                errorMessage="Please enter Address Line 2"
                isInvalid={false}
                visuallyHideLabel
              >
                <Input
                  variant="marketplace"
                  {...register('addressLine2')}
                  id="addressLine2"
                  type="text"
                  placeholder="Address Line 2"
                />
              </InputCover>

              <SimpleGrid columns={2} spacing="10px">
                <InputCover
                  label="Town or City"
                  id="townOrCity"
                  errorMessage="Please enter Town or City"
                  isInvalid={false}
                  visuallyHideLabel
                >
                  <Input
                    variant="marketplace"
                    {...register('townOrCity')}
                    id="townOrCity"
                    type="text"
                    placeholder="Town or City"
                  />
                </InputCover>
                <InputCover
                  label="Post Code"
                  id="postCode"
                  errorMessage="Please enter Post Code"
                  isInvalid={false}
                  visuallyHideLabel
                >
                  <Input
                    variant="marketplace"
                    {...register('postCode')}
                    id="postCode"
                    type="text"
                    placeholder={
                      selectedCountry !== 'US' ? 'Post Code' : 'Zip Code'
                    }
                  />
                </InputCover>
              </SimpleGrid>
            </VStack>

            {/* Footer */}
          </Box>

          <TransactionDrawerFooter variation="top-compact">
            <Button
              colorScheme="aqua"
              height="56px"
              width="100%"
              type="submit"
              isDisabled={!formState.isValid}
              isLoading={isLoading || isSending}
            >
              Complete Payment
            </Button>
          </TransactionDrawerFooter>
        </form>
      </Box>
    </Box>
  );
};

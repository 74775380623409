/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {Box, Button, Checkbox, Link} from '@chakra-ui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {assetInfo} from '../../../utils/own-a-piece-hero';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {
  termsAndConditionsForm,
  termsAndConditionsFormSchema,
} from '../forms/formValidationSchema';

interface SaleTermsAndConditionsProps {
  onSubmit: (data: termsAndConditionsForm) => void;
  onBackClick?: () => void;
  isLoading?: boolean;
}

export const SaleTermsAndConditions: React.FC<SaleTermsAndConditionsProps> = ({
  onSubmit,
  onBackClick,
  isLoading = false,
}) => {
  const {drawerProduct} = useTransactionDrawerContext();

  const onFormSubmit = (data: termsAndConditionsForm) => onSubmit(data);

  const {register, handleSubmit, formState} = useForm<termsAndConditionsForm>({
    resolver: yupResolver(termsAndConditionsFormSchema),
    reValidateMode: 'onChange',
    mode: 'all',
  });

  return (
    <Box background="white">
      <TransactionDrawerHeader
        title="Before you continue..."
        intro="Please read the below carefully!"
        backLink={!!onBackClick}
        onBackClick={() => onBackClick && onBackClick()}
      />
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box mt="50px" pb="200px">
          <Checkbox
            id="sellingDirectToBuyer"
            {...register('sellingDirectToBuyer')}
            name="sellingDirectToBuyer"
            colorScheme="aqua"
            aria-label="Selling Direct To Buyer"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I understand that I will be selling this piece directly to the
            buyer.
          </Checkbox>
          <Checkbox
            id="crossBorderCharge"
            {...register('crossBorderCharge')}
            name="crossBorderCharge"
            colorScheme="aqua"
            aria-label="Cross Border Charges"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I consent to Showpiece increasing the price for international buyers
            to account for currency conversion costs
          </Checkbox>

          <Checkbox
            id="currencyConversionAndStripe"
            {...register('currencyConversionAndStripe')}
            name="currencyConversionAndStripe"
            colorScheme="aqua"
            aria-label="Currency Conversions"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I understand that Showpiece use{' '}
            <Link
              href="https://www.stripe.com"
              isExternal
              color="aqua.500"
              textDecoration="underline"
            >
              Stripe
            </Link>{' '}
            as a payment service provider to facilitate marketplace transactions
            and currency conversions.
          </Checkbox>

          <Checkbox
            id="noRefunds"
            {...register('noRefunds')}
            name="noRefunds"
            colorScheme="aqua"
            aria-label="No refunds"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I understand that all successful marketplace transactions are final
            and that no refunds are available.
          </Checkbox>
          <Checkbox
            id="fundsTimeline"
            {...register('fundsTimeline')}
            name="fundsTimeline"
            colorScheme="aqua"
            aria-label="Funds Timeline"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I understand that it may take 5-10 days for funds to appear in my
            account.
          </Checkbox>
          <Checkbox
            id="termsAndConditions"
            {...register('termsAndConditions')}
            name="termsAndConditions"
            colorScheme="aqua"
            aria-label="Terms & conditions"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 1,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I agree to the{' '}
            <Link
              href={assetInfo[drawerProduct as string].marketplaceTandCs}
              isExternal
              color="aqua.500"
              textDecoration="underline"
            >
              Terms and Conditions
            </Link>
            .
          </Checkbox>
        </Box>

        <TransactionDrawerFooter variation="no-shadow">
          <Button
            colorScheme="aqua"
            height="56px"
            width="100%"
            type="submit"
            isLoading={isLoading}
            isDisabled={!formState.isValid}
          >
            Post on marketplace
          </Button>
        </TransactionDrawerFooter>
      </form>
    </Box>
  );
};

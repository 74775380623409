/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {Box, Button, Checkbox, Link} from '@chakra-ui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {assetInfo} from '../../../utils/own-a-piece-hero';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {
  BuyerTermsAndConditionsForm,
  buyerTermsAndConditionsFormSchema,
} from '../forms/formValidationSchema';

interface BuyerTermsAndConditionsProps {
  onSubmit: (data: BuyerTermsAndConditionsForm) => void;
  onBackClick?: () => void;
  isLoading?: boolean;
}

export const BuyerTermsAndConditions: React.FC<
  BuyerTermsAndConditionsProps
> = ({onSubmit, onBackClick, isLoading = false}) => {
  const {drawerProduct} = useTransactionDrawerContext();

  const onFormSubmit = (data: BuyerTermsAndConditionsForm) => onSubmit(data);

  const {register, handleSubmit, formState} =
    useForm<BuyerTermsAndConditionsForm>({
      resolver: yupResolver(buyerTermsAndConditionsFormSchema),
      reValidateMode: 'onChange',
      mode: 'all',
    });

  return (
    <Box background="white">
      <TransactionDrawerHeader
        title="Before you continue..."
        intro="Please read the below carefully!"
        backLink={!!onBackClick}
        onBackClick={() => onBackClick && onBackClick()}
      />
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box mt="50px" pb="200px">
          <Checkbox
            id="directFromSeller"
            {...register('directFromSeller')}
            name="directFromSeller"
            colorScheme="aqua"
            aria-label="Funds Timeline"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I understand that I am buying this piece directly from the seller.
          </Checkbox>
          <Checkbox
            id="noRefunds"
            {...register('noRefunds')}
            name="noRefunds"
            colorScheme="aqua"
            aria-label="No refunds"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I understand that all successful marketplace transactions are final
            and that no refunds are available from the seller.
          </Checkbox>
          <Checkbox
            id="currencyConversionAndStripe"
            {...register('currencyConversionAndStripe')}
            name="currencyConversionAndStripe"
            colorScheme="aqua"
            aria-label="Currency Conversions"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I understand that Showpiece use{' '}
            <Link
              href="https://www.stripe.com"
              isExternal
              color="aqua.500"
              textDecoration="underline"
            >
              Stripe
            </Link>{' '}
            as a payment service provider to facilitate marketplace
            transactions.
          </Checkbox>
          <Checkbox
            id="termsAndConditions"
            {...register('termsAndConditions')}
            name="termsAndConditions"
            colorScheme="aqua"
            aria-label="Terms & conditions"
            alignItems="flex-start"
            textStyle="body2"
            mb="16px"
            sx={{
              '.chakra-checkbox__control': {
                marginTop: 3,
              },
              '.chakra-checkbox__label': {
                fontSize: '16px',
              },
            }}
          >
            I confirm agreement to the{' '}
            <Link
              href={assetInfo[drawerProduct as string].marketplaceTandCs}
              isExternal
              color="aqua.500"
              textDecoration="underline"
            >
              Marketplace Terms and Conditions
            </Link>
            .
          </Checkbox>
        </Box>

        <TransactionDrawerFooter variation="no-shadow">
          <Button
            colorScheme="aqua"
            height="56px"
            width="100%"
            type="submit"
            isLoading={isLoading}
            isDisabled={!formState.isValid}
          >
            Continue to Payment
          </Button>
        </TransactionDrawerFooter>
      </form>
    </Box>
  );
};

import {extendTheme, theme as baseTheme} from '@chakra-ui/react';
import {StyleFunctionProps} from '@chakra-ui/theme-tools';

const CHARCOAL = '#1B1C1C';
const TONE_1 = '#F6F6F6';
const TONE_2 = '#C9CFD3';
const TONE_3 = '#8F9696';
const TONE_4 = '#CBD5E0';
const TONE_5 = '#C8CFCE';
const TONE_6 = '#718096';
const BACKGROUND = '#F5F7F7'; //
const SUPERLIGHT = '#F3F4F6';
const WHITE = '#fff';
const BLUE = '#0052FF';
const INFO_BLUE = '#0C418A';
const RACING_GREEN = '#1F292E';
const INFO_GREEN = '#0E6762';
const LIGHT_GREY = '#C3C5C5';
const LIGHT_GREY_BORDER = '#EDEFF1';
const BUTTON_GREY = '#C5CAD6';
const MID_GREY = '#464750';
const DARK_GREY = '#34353F';
const ATHENS_GREY = '#E4E9EC'; //
const SAND = '#F2EBD8';
const MIDNIGHT = '#1D1D28';
const ACCENT_GREEN = '#0BA66E';
const HOVER_GREEN = '#F4F9FA';
const STAMP_RED = '#E0787E';
const CARD_GREY = '#C4C4C4';
const CALC_BTN_GREY = '#F9FAFA';
const ROYAL_NAVY = '#07304B';
const PEACH = '#D69A6A';
const QUEEN_BLUE = '#7BC9E1';
const LOULOU = '#380835';
const BOOK = '#7BE1B0';
const BOOK_YELLOW = '#E2BA6B';
const BANKSY_PRIMARY = '#00061A';

// Swatches generated here: https://themera.vercel.app/
const colors = {
  charcoal: CHARCOAL,
  tone1: TONE_1,
  tone2: TONE_2,
  tone3: TONE_3,
  tone4: TONE_4,
  tone5: TONE_5,
  tone6: TONE_6,
  background: BACKGROUND,
  superlight: SUPERLIGHT,
  racingGreen: RACING_GREEN,
  buttonGrey: BUTTON_GREY,
  calcBtnGrey: CALC_BTN_GREY,
  white: WHITE,
  blue: BLUE,
  infoBlue: INFO_BLUE,
  midGrey: MID_GREY,
  darkGrey: DARK_GREY,
  athensGrey: ATHENS_GREY,
  sand: SAND,
  midnight: MIDNIGHT,
  accentGreen: ACCENT_GREEN,
  infoGreen: INFO_GREEN,
  stampRed: STAMP_RED,
  cardGrey: CARD_GREY,
  hoverGreen: HOVER_GREEN,
  toggleGreen: '#34C759',
  royalNavy: ROYAL_NAVY,
  peach: PEACH,
  queenBlue: QUEEN_BLUE,
  loulou: LOULOU,
  book: BOOK,
  bookYellow: BOOK_YELLOW,
  banksyPrimary: BANKSY_PRIMARY,
  gray: {
    1000: LIGHT_GREY_BORDER,
    900: CHARCOAL,
    800: CHARCOAL,
    700: TONE_1,
    600: TONE_2,
    500: TONE_3,
    400: TONE_3,
    300: BACKGROUND,
    200: LIGHT_GREY,
    100: SUPERLIGHT,
    50: WHITE,
  },
  red: {
    '50': '#FFE5F0',
    '100': '#FFB8D4',
    '200': '#FF8AB9',
    '300': '#FF5C9D',
    '400': '#FF2E82',
    '500': '#FF0066',
    '600': '#CC0052',
    '700': '#99003D',
    '800': '#660029',
    '900': '#330014',
  },
  copper: {
    '50': '#F6F1EE',
    '100': '#E7D8D0',
    '200': '#D7C0B1',
    '300': '#C8A793',
    '400': '#B88E75',
    // This has been adjusted to match the actual color in the theme rather than in the generated swatch
    // TODO: Figure out best practice here
    '500': '#B98F76',
    '600': '#875E45',
    '700': '#654634',
    '800': '#442F22',
    '900': '#221711',
  },
  aqua: {
    '50': '#E9FCFA',
    '100': '#C1F6F2',
    '200': '#99F0E9',
    '300': '#71EAE1',
    '400': '#49E4D8',
    // This has been adjusted to match the actual color in the theme rather than in the generated swatch
    // TODO: Figure out best practice here
    '500': '#1ABAB0',
    '600': '#1AB2A6',
    '700': '#1FD3C5',
    '800': '#0D5953',
    '900': '#072C2A',
  },
};

const textStyles = {
  h1: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '108px',
    lineHeight: '110px;   ',
    letterSpacing: '-6.11px',
  },
  h2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '78px',
    lineHeight: '82px;   ',
    letterSpacing: '-4.26px',
  },
  h3: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '48px',
    lineHeight: '55px',
    letterSpacing: '-2.76px',
  },
  h4: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '41px',
    letterSpacing: '-1.62px',
  },
  h5: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '35px',
    letterSpacing: '-0.56px',
  },
  h6: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '21px',
    lineHeight: '29px',
    letterSpacing: '-0.42px',
  },
  h7: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22.4px',
    letterSpacing: '-0.42px',
  },
  h8: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '55px',
    letterSpacing: '-0.42px',
  },
  body1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '29px',
    letterSpacing: '-0.0028em',
  },
  body2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.0028em',
  },
  body3: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '-0.0028em',
  },
  body4: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.28px',
  },
  body5: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '21px',
    letterSpacing: '-0.24px',
  },
  body6: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '25px',
  },
  body7: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '21px',
  },
  bodySmall: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '16px',
  },
  socialSignIn: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '16px',
  },
  subh1: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '25px',
  },
  subh2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
  },
  button: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '-0.3',
  },
  button_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
  },
  downloadTextSm: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '-0.0028em',
  },
  downloadTextLg: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '-0.0028em',
  },
  script: {
    fontFamily: 'Allison',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '55px',
  },
  h1_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '120px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '156.6px',
  },
  h2_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '44px',
  },
  h3_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '38.4px',
  },
  h4_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '30.8px',
  },
  h5_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '30.8px',
  },
  h6_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '26.4px',
  },
  h7_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '20.8px',
  },
  h8_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
  },
  h1news_1c: {
    fontFamily: 'Old Standard TT',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '79.1px',
  },
  h2news_1c: {
    fontFamily: 'EB Garamond',
    fontSize: '32px',
    fontStyle: 'italic',
    fontWeight: '600',
    lineHeight: '35.2px',
  },
  num_1c: {
    fontFamily: 'Old Standard TT',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '29.66px',
  },
  body1_1c: {
    fontFamily: 'Spectral',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '28.8px',
  },
  body2_1c: {
    fontFamily: 'Spectral',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '33px',
  },
  body3_1c: {
    fontFamily: 'Spectral',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '25.2px',
  },
  body4_1c: {
    fontFamily: 'Spectral',
    fontSize: '18px',
    fontStyle: 'italic',
    fontWeight: '500',
    lineHeight: '27.4px',
  },
  body5_1c: {
    fontFamily: 'Spectral',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20.8px',
  },
  body7_product: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '21px',
  },
  body6_1c: {
    fontFamily: 'Spectral',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18.2px',
  },
  showpiece_h8: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '17px',
  },
  showpiece_h7: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '1.4em',
  },
  showpiece_h6: {
    fontFamily: 'Poppins',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '29.4px',
  },
};

const shadows = {
  ...baseTheme.shadows,
  balls: '0px 0px 150px rgba(0, 0, 0, 0.08)',
  card: '0px 12px 34px rgba(0, 0, 0, 0.1)',
  cardActive: '0px 2px 4px rgba(0, 0, 0, 0.1)',
};

const sizes = {
  ...baseTheme.sizes,
  container: {
    xl: '1296px',
  },
};

const layerStyles = {
  card: {
    borderRadius: '24px',
    bg: 'superlight',
  },
  whiteCard: {
    borderRadius: '12px',
    bg: 'white',
    shadows: shadows.card,
  },
};

const components = {
  ...baseTheme.components,
  FormError: {
    ...baseTheme.components.FormError,
    baseStyle: {
      ...baseTheme.components.FormError.baseStyle,
      text: {
        ...baseTheme.components.FormError.baseStyle?.text,
        textStyle: 'body4',
        color: 'red.500',
      },
    },
  },
  FormLabel: {
    ...baseTheme.components.FormLabel,
    baseStyle: {
      ...baseTheme.components.FormLabel.baseStyle,
      textStyle: 'body4',
      color: 'tone1',
    },
  },
  Input: {
    ...baseTheme.components.Input,
    variants: {
      ...baseTheme.components.Input.variants,
      outline: (props: StyleFunctionProps) => {
        const base = baseTheme.components.Input.variants?.outline(props);
        return {
          ...base,
          field: {
            ...(base?.field ?? {}),
            borderColor: 'tone2',
            borderRadius: '14px',
            textStyle: 'body2',
            _focusVisible: {
              borderColor: 'white',
              outline: 'none',
              boxShadow: 'none',
            },
          },
        };
      },
      basic: (props: StyleFunctionProps) => {
        const base = baseTheme.components.Input.variants?.outline(props);
        return {
          ...base,
          field: {
            ...(base?.field ?? {}),
            borderColor: 'tone2',
            borderRadius: '14px',
            py: 1,
            height: '48px',
            textStyle: 'body2',
            _placeholder: {color: 'tone2'},
            background: 'white',
            _hover: {
              borderColor: 'inherit',
            },
          },
        };
      },
      marketplace: (props: StyleFunctionProps) => {
        const base = baseTheme.components.Input.variants?.outline(props);
        return {
          ...base,
          field: {
            ...(base?.field ?? {}),
            borderColor: 'tone2',
            borderRadius: '14px',
            py: 1,
            height: '48px',
            textStyle: 'body2',
            _placeholder: {color: 'tone2'},
            _hover: {
              borderColor: 'inherit',
            },
          },
        };
      },
    },
  },
  Select: {
    ...baseTheme.components.Input,
    variants: {
      ...baseTheme.components.Input.variants,
      basic: (props: StyleFunctionProps) => {
        const base = baseTheme.components.Input.variants?.outline(props);
        return {
          ...base,
          field: {
            ...(base?.field ?? {}),
            borderColor: 'tone2',
            borderRadius: '14px',
            py: 1,
            height: '48px',
            textStyle: 'body2',
            _placeholder: {color: 'tone2'},
            background: 'white',
            _hover: {
              borderColor: 'inherit',
            },
          },
        };
      },
      outline: (props: StyleFunctionProps) => {
        const base = baseTheme.components.Input.variants?.outline(props);
        return {
          ...base,
          field: {
            ...(base?.field ?? {}),
            borderColor: 'tone2',
            borderRadius: '14px',
            textStyle: 'body2',
          },
        };
      },
      marketplace: (props: StyleFunctionProps) => {
        const base = baseTheme.components.Input.variants?.outline(props);
        return {
          ...base,
          field: {
            ...(base?.field ?? {}),
            borderColor: 'tone2',
            borderRadius: '14px',
            textStyle: 'body2',
            py: 1,
            height: '48px',
            _placeholder: {color: 'tone2'},
            _hover: {
              borderColor: 'inherit',
            },
          },
        };
      },
    },
  },
  Tag: {
    baseStyle: {
      ...textStyles.button,
    },
  },
  Button: {
    baseStyle: {
      ...textStyles.button,
      _focus: {boxShadow: 'none'},
      borderRadius: '14px',
    },

    sizes: {
      md: {
        h: '46px',
        px: '20px',
      },
      lg: {
        h: '56px',
      },
    },
  },
};

export const theme = extendTheme({
  colors,
  textStyles,
  shadows,
  layerStyles,
  components,
  sizes,
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
    magenta: 'EB Garamond',
    spectral: 'Spectral',
    oldStandard: 'Old Standard TT',
    baskerville: 'Baskerville',
  },
  styles: {
    global: {
      '*::placeholder': {
        color: 'gray.700',
      },
    },
  },
});

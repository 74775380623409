import mixpanel from 'mixpanel-browser';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

const TRACK_LINK = {
  SOCIAL_FB: {
    class: 'social_link_fb',
    event: 'Clicked a Social Media Link',
    properties: {social: 'Facebook'},
  },
  SOCIAL_TW: {
    class: 'social_link_tw',
    event: 'Clicked a Social Media Link',
    properties: {social: 'Twitter'},
  },
  SOCIAL_IG: {
    class: 'social_link_ig',
    event: 'Clicked a Social Media Link',
    properties: {social: 'Instagram'},
  },
  REGISTER: {
    class: 'register_link',
    event: 'Register',
  },
  HOME_CTA: {
    class: 'home_cta',
    event: 'Home CTA Clicked',
  },
};

type TrackLinkIdsType = Record<keyof typeof TRACK_LINK, string>;
export const TRACK_LINK_ID: TrackLinkIdsType = Object.entries(
  TRACK_LINK,
).reduce(
  (acc, [key, value]) => ({...acc, [key]: value.class}),
  {} as TrackLinkIdsType,
);
mixpanel.init(
  process.env.STAGE === 'production'
    ? 'f1028b83911b5a8826114afff1acd5d2'
    : '30159a744887d21567a25eb96f141f19',
  {
    debug: process.env.NODE_ENV === 'development',
    api_host: 'https://api-eu.mixpanel.com',
  },
);

// trackPageView(window.location.href);
// Router.events.on('routeChangeComplete', trackPageView);

export const usePageViewEffect = (): void => {
  const isBrowser = typeof window !== 'undefined';

  const trackPageView = (url: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    mixpanel.track('Page View', {
      url,
      referredFrom: params.referredFrom ?? '',
    });
  };

  // hacky way to ensure page-tracking is called on initial page load:
  const [initialRouteTracked, setInitialRouteTracked] = useState(false);
  if (isBrowser && !initialRouteTracked && window.location.search === '') {
    trackPageView(window.location.href);
    setInitialRouteTracked(true);
  }

  // use the setup from the examples directory as normal
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};

export const useLinkTrackingEffect = (): void =>
  useEffect(() => {
    Object.values(TRACK_LINK).forEach((link) => {
      mixpanel.track_links(
        `.${link.class}`,
        link.event,
        'properties' in link ? link.properties : {},
      );
    });
  });

export {default as mixpanel} from 'mixpanel-browser';

import {useEffect, useState} from 'react';

import {
  Text,
  UnorderedList,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import {Listing} from '../../../graphql/generated';
import {useGeolocation} from '../../../hooks/geolocation';

interface PiecePurchaseListProps {
  items: Listing[];
}

export const PiecePurchaseList: React.FC<PiecePurchaseListProps> = ({
  items,
}) => {
  const [basketQuantity, setBasketQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const {currency, currencyPostfix} = useGeolocation();

  useEffect(() => {
    setBasketQuantity(items.reduce((prev, curr) => prev + curr.quantity, 0));
    setTotalAmount(
      items.reduce((prev, curr) => {
        return prev + curr.quantity * curr.price.value;
      }, 0),
    );
  }, [items]);

  return (
    <Accordion allowToggle defaultIndex={[0]} pb="120px">
      <AccordionItem border="none">
        <AccordionButton
          padding="0"
          _focus={{
            outline: 'none',
          }}
        >
          <Text textStyle="body4" fontWeight="400">
            Your best price for {basketQuantity} Piece
            {basketQuantity > 1 ? 's' : ''} is{' '}
            <Text as="span" color="aqua.500" fontWeight="bold">
              {`${currency}${totalAmount.toFixed(2)}`}
              <Text as="span" fontSize="0.7em" fontWeight="bold">
                {currencyPostfix}
              </Text>
            </Text>
          </Text>
          <AccordionIcon color="aqua.500" marginLeft="12px" />
        </AccordionButton>
        <AccordionPanel padding="0">
          <UnorderedList
            color="tone3"
            textStyle="body4"
            mt="5px"
            paddingLeft="8px"
          >
            {items.length > 0 &&
              items.map(({quantity, price, id}) => (
                <ListItem key={id}>
                  {`${quantity} ${
                    quantity > 1 ? 'Pieces' : 'Piece'
                  } for ${currency}${price}`}
                  <Text as="span" fontSize="0.7em">
                    {currencyPostfix}
                  </Text>
                </ListItem>
              ))}
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

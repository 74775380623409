import {Box, Button} from '@chakra-ui/react';
import {TransactionDrawerHeader} from './transaction-drawer-header';
import {TransactionDrawerFooter} from './transaction-drawer-footer';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';

interface TransactionDrawerErrorOverlayProps {
  isActive?: boolean;
  onReturnClick: () => void;
}

export const TransactionDrawerErrorOverlay: React.FC<
  TransactionDrawerErrorOverlayProps
> = ({isActive = false, onReturnClick}) => {
  const {resetDrawer} = useTransactionDrawerContext();

  return (
    <>
      {isActive && (
        <Box
          pos="absolute"
          zIndex={10}
          top={0}
          left={0}
          width="100%"
          height="100%"
          background="white"
          paddingTop={{base: '32px', md: '80px'}}
          paddingX={{base: '32px', md: '48px'}}
        >
          <TransactionDrawerHeader
            title="Exit?"
            intro="If you exit now, you will lose any information you have input and the price of the Pieces may have changed."
          />
          <TransactionDrawerFooter variation="no-shadow">
            <Button
              colorScheme="aqua"
              height="56px"
              display="block"
              width="100%"
              mb={4}
              onClick={() => onReturnClick && onReturnClick()}
            >
              Go Back
            </Button>
            <Button
              colorScheme="aqua"
              height="56px"
              display="block"
              width="100%"
              variant="outline"
              onClick={() => resetDrawer && resetDrawer()}
            >
              Exit
            </Button>
          </TransactionDrawerFooter>
        </Box>
      )}
    </>
  );
};

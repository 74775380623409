import {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  // eslint-disable-next-line no-restricted-imports
  useToast,
} from '@chakra-ui/react';
import {TransactionDrawerHeader} from './transaction-drawer-header';
import {TransactionDrawerFooter} from './transaction-drawer-footer';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {assetInfo} from '../../../utils/own-a-piece-hero';
import {useCurrentAssetOverview} from '../../../hooks/current-asset-overview';
import {InputCover} from '../forms/inputCover';
import {
  useUserQuery,
  useSetPriceAlertMutation,
} from '../../../graphql/generated';
import {emailRegExp, sanitiseEmail} from '../../../shared/consts';

export const PriceAlertDrawer: React.FC = () => {
  const user = useUserQuery();
  const toast = useToast();
  // const {currency, currencyPostfix, currencyCode} = useGeolocation();
  const {setDrawerLoading, drawerProduct, resetDrawer} =
    useTransactionDrawerContext();

  const [price, setPrice] = useState<number>();
  const [email, setEmail] = useState<string>();

  const [setPriceAlert] = useSetPriceAlertMutation();

  const asset = useCurrentAssetOverview(drawerProduct as string);

  useEffect(() => {
    if (!user.loading) if (setDrawerLoading) setDrawerLoading(false);
  }, [setDrawerLoading, user.loading]);

  const priceChangeHandler = (updatedPrice: number) => {
    if (updatedPrice === 0) {
      setPrice(undefined);
    } else {
      setPrice(updatedPrice);
    }
  };

  const onSubmit = async () => {
    if (!user.data?.user.email) {
      const validateEmail = email && emailRegExp.test(email);

      if (email === '')
        return toast({
          status: 'error',
          position: 'bottom',
          title: 'Error submitting price alert',
          description: 'Email address cannot be empty',
          duration: 2000,
        });

      if (!validateEmail)
        return toast({
          status: 'error',
          title: 'Error submitting price alert',
          position: 'bottom',
          description: 'Wrong format for the email address',
          duration: 2000,
        });
    }
    if (asset && asset.assetId && asset.pricing?.symbol && price) {
      const priceAlertVariables = {
        price,
        assetId: asset.assetId,
        currency: asset.pricing?.symbol,
        email: email ? sanitiseEmail(email) : user.data?.user.email || '',
      };

      setPriceAlert({variables: priceAlertVariables}).then((result) => {
        if (result.data && result.data.setPriceAlert) {
          if (resetDrawer) resetDrawer();
          toast({
            status: 'success',
            position: 'bottom',
            title: `${price}${asset.pricing?.symbol} Price Alert set up for ${asset.assetName}`,
            description: 'We’ll email you if a listing is made!',
            duration: 3000,
          });
        }
      });
    }
    return null;
  };

  return (
    <>
      {asset && asset.assetId && asset.assetName && (
        <>
          <TransactionDrawerHeader
            title={asset.assetName}
            img={assetInfo[asset.assetId].imgSrc}
            url={assetInfo[asset.assetId].href}
            intro="What price would you like to buy at? We’ll send you an email if someone lists below the price you set!"
          />

          <Box mt={6}>
            <Box mt="42px">
              <InputCover
                label="   Send me a price alert for listings below..."
                id="setPriceAlert"
              >
                <InputGroup
                  sx={{
                    '.chakra-input__left-element, , .chakra-input__right-element':
                      {
                        height: '100%',
                      },
                  }}
                >
                  <InputLeftElement pointerEvents="none">
                    {asset.pricing?.symbol}
                  </InputLeftElement>
                  <Input
                    id="priceAlert"
                    type="number"
                    variant="marketplace"
                    onChange={(e) => priceChangeHandler(Number(e.target.value))}
                    value={price}
                  />
                  {asset.pricing?.postFix && (
                    <InputRightElement pointerEvents="none">
                      {asset.pricing?.postFix}
                    </InputRightElement>
                  )}
                </InputGroup>
              </InputCover>
            </Box>
            {!user.data?.user && (
              <Box mt={6}>
                <InputCover label="Email Address" id="emailAddress">
                  <Input
                    variant="marketplace"
                    id="emailAddress"
                    name="emailAddress"
                    type="text"
                    placeholder="e.g john@smith.com"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </InputCover>
              </Box>
            )}
          </Box>
          <TransactionDrawerFooter variation="no-shadow">
            <Box mb="22px">
              <Text color="aqua.500" textStyle="body2">
                Setting a new price alert will replace any existing ones
              </Text>
            </Box>

            <Button
              colorScheme="aqua"
              height="56px"
              display="block"
              width="100%"
              onClick={onSubmit}
            >
              Confirm
            </Button>
          </TransactionDrawerFooter>
        </>
      )}
    </>
  );
};

import * as yup from 'yup';

export interface PaymentRegistrationForm {
  accountType: string;
  accountNumber?: string;
  routingNumber?: string;
  swiftIban?: string;
  addressLine1: string;
  addressLine2: string;
  townOrCity: string;
  country: string;
  postCode: string;
}

export interface termsAndConditionsForm {
  fundsTimeline: boolean;
  sellingDirectToBuyer: boolean;
  noRefunds: boolean;
  currencyConversionAndStripe: boolean;
  crossBorderCharge: boolean;
  termsAndConditions: boolean;
}
export interface BuyerTermsAndConditionsForm {
  directFromSeller: boolean;
  noRefunds: boolean;
  currencyConversionAndStripe: boolean;
  termsAndConditions: boolean;
}

export const termsAndConditionsFormSchema = yup
  .object()
  .shape({
    fundsTimeline: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    sellingDirectToBuyer: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    noRefunds: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    currencyConversionAndStripe: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    crossBorderCharge: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    termsAndConditions: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
  })
  .required();

export const buyerTermsAndConditionsFormSchema = yup
  .object()
  .shape({
    directFromSeller: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    noRefunds: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    currencyConversionAndStripe: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
    termsAndConditions: yup
      .bool()
      .oneOf([true], 'In order to accept the terms, you must agree to them.'),
  })
  .required();

export const paymentRegistrationFormSchema = yup
  .object()
  .shape({
    accountType: yup.string().required(),

    // When sortcode is selected
    accountNumber: yup.string().when('accountType', {
      is: (accountType) => ['sortcode', 'routing'].includes(accountType),
      then: yup.string().required('Account Number is required'),
    }),
    routingNumber: yup
      .string()
      .when('accountType', {
        is: (accountType) => accountType === 'sortcode',
        then: yup.string().required('Sort code is required'),
      })
      .when('accountType', {
        is: (accountType) => accountType === 'routing',
        then: yup.string().required('Routing number is required'),
      }),

    swiftIban: yup
      .string()
      .when('accountType', {
        is: (accountType) => accountType === 'swift',
        then: yup.string().required('Swift / BIC Number is required'),
      })
      .when('accountType', {
        is: (accountType) => accountType === 'iban',
        then: yup.string().required('IBAN number is required'),
      }),

    addressLine1: yup.string().required(),
    addressLine2: yup.string(),
    townOrCity: yup.string().required(),
    country: yup.string().required(),
    postCode: yup.string().required(),
  })
  .required();

export interface AddressForm {
  addressLine1: string;
  addressLine2: string;
  townOrCity: string;
  country: string;
  postCode: string;
}

export const addressFormSchema = yup
  .object()
  .shape({
    addressLine1: yup.string().required(),
    addressLine2: yup.string(),
    townOrCity: yup.string().required('Town/City is required'),
    country: yup.string().required('Country is required'),
    postCode: yup.string().required('Postcode is required'),
  })
  .required();
export interface RegisterSellerForm {
  dateOfBirth: string;
  phoneNumber: string;
}

export const registerSellerFormSchema = yup
  .object()
  .shape({
    dateOfBirth: yup.string().required('Your date of birth is required'),
    phoneNumber: yup.string().required('Your phone number is required'),
  })
  .required();

export interface SellPiecesForm {
  amount: number;
  quantity: number;
  pieces: string[];
}

export const sellPiecesFormSchema = yup
  .object()
  .shape({
    amount: yup
      .number()
      .min(1)
      .required('Price must be greater than or equal to 1'),
    quantity: yup.number().min(1).required('Must add at least one item'),
    pieces: yup.array().min(1),
  })
  .required();

export interface ConfirmPriceForm {
  amount: number;
}

export const confirmPriceFormSchema = yup
  .object()
  .shape({
    amount: yup.number().required(),
  })
  .required();

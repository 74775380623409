/* eslint-disable radix */
import {ApolloError} from '@apollo/client';
import {useAssetQuery} from '../graphql/generated';

export interface AssetOverviewProps {
  priceString: string;
  pricePerPiece: number | null;
  totalAssetPiecesSold: number | null;
  totalAssetPieces: number | null;
  totalAssetPiecesAvailable: number | null;
  totalAssetCustomersTransacted: number | null;
  pricing:
    | {
        pricePerPiece: number | null;
        currency: string;
        symbol: string;
        postFix: string;
        formatPrice: boolean;
      }
    | undefined;
  assetName?: string;
  assetId?: string;
  assetOfferingId?: string;
  totalAssetTransactions: number | null;
  loading: boolean;
  error: ApolloError | undefined;
  maxAssetsPerUser: number | null;
  kycMaxPieces: number | null;
  lastPurchasedPrice: string | null;
  startDate: Date;
  endDate: Date;
  lowestPrice: string | number | null;
  totalActiveListings: number | null;
  marketplaceAveragePrice: string | null;
  availablePiecesFromCurrentAssetOffering?: number;
  totalPiecesFromCurrentAssetOffering?: number;
  soldPiecesFromCurrentAssetOffering?: number;
  offeringDisplayItems?: OfferingDisplayItem[];
  marketplaceLastSoldPrice: number | null;
  status: string | null;
  formatPrices: boolean;
}

export interface OfferingDisplayItem {
  id?: string;
  pieces?: number;
  available?: boolean;
  totalPiecesAvailable?: number;
}

export const useCurrentAssetOverview = (id: string): AssetOverviewProps => {
  const {data, loading, error} = useAssetQuery({
    variables: {id},
    fetchPolicy: 'no-cache',
  });

  const asset = data?.asset;

  const assetName = asset?.title;
  const assetOffering = asset?.currentAssetOffering;
  const offeringDisplayItems = asset?.allAssetOfferings
    ?.filter(
      (offering) =>
        !offering?.id.includes('reserve') &&
        new Date(offering?.start) < new Date(),
    )
    .map((filteredOffering) => ({
      id: filteredOffering?.id,
      pieces: filteredOffering?.transactionOverview.totalPieces,
      available: filteredOffering?.id === assetOffering?.id,
      totalPiecesAvailable:
        filteredOffering?.transactionOverview.totalPiecesAvailable,
    }));

  const assetOfferingId = assetOffering?.id;
  const availablePiecesFromCurrentAssetOffering =
    assetOffering?.transactionOverview.totalPiecesAvailable;
  const totalPiecesFromCurrentAssetOffering =
    assetOffering?.transactionOverview.totalPieces;
  const soldPiecesFromCurrentAssetOffering =
    assetOffering?.transactionOverview.totalPiecesSold;
  const assetOverview = asset?.transactionOverview;
  const assetId = asset?.id;
  const status = asset?.status || null;

  const pricing = assetOffering?.pricing;
  const formatPrices = pricing?.formatPrice || false;

  const priceString =
    pricing?.pricePerPiece && formatPrices
      ? pricing.pricePerPiece.toFixed(2)
      : `${pricing?.pricePerPiece}`;

  const lowestPrice =
    asset?.listingOverview.lowestPrice && formatPrices
      ? asset?.listingOverview.lowestPrice.toFixed(2)
      : `${asset?.listingOverview.lowestPrice}`;

  const lastPurchasedPrice =
    asset?.lastAssetTransactionOverview?.price.value && formatPrices
      ? asset?.lastAssetTransactionOverview?.price.value.toFixed(2)
      : `${asset?.lastAssetTransactionOverview?.price.value}`;

  const marketplaceLastSoldPrice =
    asset?.transactionOverview.price.lastSoldPrice && formatPrices
      ? asset?.transactionOverview.price.lastSoldPrice.toFixed(2)
      : `${asset?.transactionOverview.price.lastSoldPrice}`;

  const marketplaceAveragePrice =
    asset?.transactionOverview.price.averagePrice && formatPrices
      ? asset?.transactionOverview.price.averagePrice.toFixed(2)
      : `${asset?.transactionOverview.price.averagePrice}`;

  const pricePerPiece = pricing?.pricePerPiece || null;

  const totalAssetPiecesSold = assetOverview?.totalPiecesSold || null;

  const totalAssetTransactions =
    assetOverview?.totalNumberOfTransactions || null;

  const totalAssetCustomersTransacted =
    assetOverview?.totalCustomersTransacted || null;

  const totalAssetPiecesAvailable = assetOverview?.totalPiecesAvailable || null;

  const totalAssetPieces = assetOverview?.totalPieces || null;

  const maxAssetsPerUser = asset?.quantityAvailable
    ? (asset?.quantityAvailable / 100) * 10
    : 999;

  const kycMaxPieces = asset?.transactionOverview.kycMaxPieces || 100;

  const startDate = asset?.currentAssetOffering?.start || null;
  const endDate = asset?.currentAssetOffering?.end || null;

  const totalActiveListings = asset?.listingOverview.totalActiveNFPs || null;

  return {
    priceString,
    pricing,
    lastPurchasedPrice,
    pricePerPiece,
    totalAssetPiecesSold,
    totalAssetPieces,
    totalAssetPiecesAvailable,
    totalAssetCustomersTransacted,
    assetName,
    assetId,
    assetOfferingId,
    totalAssetTransactions,
    loading,
    error,
    maxAssetsPerUser,
    kycMaxPieces,
    startDate,
    endDate,
    lowestPrice,
    totalActiveListings,
    marketplaceAveragePrice,
    availablePiecesFromCurrentAssetOffering,
    totalPiecesFromCurrentAssetOffering,
    soldPiecesFromCurrentAssetOffering,
    offeringDisplayItems,
    marketplaceLastSoldPrice: parseInt(marketplaceLastSoldPrice),
    status,
    formatPrices,
  };
};

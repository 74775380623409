import {DefaultSeoProps} from 'next-seo';

export const seoConfig: DefaultSeoProps = {
  titleTemplate: `%s | Showpiece`,
  defaultTitle: `Own a Piece of History | Showpiece`,
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    images: [
      {
        url: 'https://showpiece.com/og-image.png',
        width: 1200,
        height: 630,
        alt: 'Showpiece',
      },
    ],
    url: 'https://www.showpiece.com/',
    site_name: 'Showpiece',
  },
  twitter: {
    handle: '@showpiece',
    site: '@showpiece',
    cardType: 'summary_large_image',
  },
};

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {useEffect, useState} from 'react';
import {Box, Button, Flex, Text, Tooltip, Icon} from '@chakra-ui/react';
import {IconInfoCircle} from '@tabler/icons';
import dayjs from 'dayjs';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {SellPiecesForm} from '../forms/formValidationSchema';
import {assetInfo} from '../../../utils/own-a-piece-hero';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {useGeolocation} from '../../../hooks/geolocation';
import {
  STRIPE_TRANSACTION_FEE,
  STRIPE_FIXED_TRANSACTION_FEE,
} from '../../../shared/consts';

interface ConfirmAllSaleDetailsProps {
  onSubmit: () => void;
  onBackClick?: () => void;
  isLoading?: boolean;
  saleDetails: SellPiecesForm;
  includeStripeFee?: boolean;
}

const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 30);

export const ConfirmAllSaleDetails: React.FC<ConfirmAllSaleDetailsProps> = ({
  onSubmit,
  onBackClick,
  isLoading = false,
  saleDetails,
}) => {
  const [isNotNegativeSale, setIsNotNegativeSale] = useState(true);
  const {drawerProduct} = useTransactionDrawerContext();
  const {currency, currencyPostfix, formatPrice, stripeMonthlyFee} =
    useGeolocation();

  const totalFees =
    saleDetails.quantity * saleDetails.amount * STRIPE_TRANSACTION_FEE +
    stripeMonthlyFee +
    STRIPE_FIXED_TRANSACTION_FEE;

  const totalFeesString = formatPrice
    ? totalFees.toFixed(2)
    : Math.ceil(totalFees);

  const totalPayout = saleDetails.quantity * saleDetails.amount - totalFees;

  useEffect(() => {
    const perPiece = saleDetails.amount * (totalFees / 100);

    setIsNotNegativeSale(perPiece < 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box background="white">
      <TransactionDrawerHeader
        title="Review"
        intro="Please review the details of your listing "
        backLink={!!onBackClick}
        onBackClick={() => onBackClick && onBackClick()}
      />

      <Box mt="50px" pb="200px">
        <Flex as="p" justify="space-between" mb="15px">
          <Text as="span" color="tone3" textStyle="body3">
            Showpiece Title
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {drawerProduct && assetInfo
              ? assetInfo[drawerProduct as string].title
              : ''}
          </Text>
        </Flex>
        <Flex as="p" justify="space-between" mb="15px">
          <Text as="span" color="tone3" textStyle="body3">
            Listing Expiry Date
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {dayjs(expirationDate).format('DD/MM/YYYY')}
          </Text>
        </Flex>
        <Flex as="p" justify="space-between" mb="15px">
          <Text as="span" color="tone3" textStyle="body3">
            Quantity listed
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {saleDetails.quantity}
          </Text>
        </Flex>
        <Flex as="p" justify="space-between" mb="15px">
          <Text as="span" color="tone3" textStyle="body3">
            Listing Price per Piece
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {currency}
            {formatPrice ? saleDetails.amount.toFixed(2) : saleDetails.amount}
            <Text as="span" fontSize="0.5em">
              {currencyPostfix}
            </Text>
          </Text>
        </Flex>

        <Flex
          as="p"
          justify="space-between"
          mb="15px"
          pb="10px"
          borderBottom="1px solid"
          borderColor="tone1"
        >
          <Text as="span" color="charcoal" textStyle="h7">
            Total listing value
          </Text>
          <Text as="span" color="aqua.500" textStyle="h6">
            {currency}
            {formatPrice
              ? (saleDetails.quantity * saleDetails.amount).toFixed(2)
              : saleDetails.quantity * saleDetails.amount}
            <Text as="span" fontSize="0.7em">
              {currencyPostfix}
            </Text>
          </Text>
        </Flex>
        <Flex
          as="p"
          justify="space-between"
          mb="15px"
          pb="10px"
          borderBottom="1px solid"
          borderColor="tone1"
        >
          <Text as="span" color="tone3" textStyle="body3">
            <span tabIndex={0}>Marketplace Seller fee</span>
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {currency}
            {totalFeesString}
            {currencyPostfix}
          </Text>
        </Flex>

        <Flex
          as="p"
          justify="space-between"
          mb="15px"
          pb="10px"
          borderColor="tone1"
        >
          <Text as="span" color="charcoal" textStyle="h7">
            Expected Payout
          </Text>
          <Text as="span" color="aqua.500" textStyle="h6">
            {currency}
            {formatPrice ? totalPayout.toFixed(2) : Math.ceil(totalPayout)}
            <Text as="span" fontSize="0.7em">
              {currencyPostfix}
            </Text>
          </Text>
        </Flex>

        <Flex as="p" justify="space-between">
          <Tooltip
            label={
              <Flex flexWrap="wrap" p="5px">
                <Text w="100%">
                  Occasionally you may receive more money than your expected
                  payout amount
                </Text>
                <Text w="100%" pt="15px">
                  This is because there is some variation in currency conversion
                  fees if a user purchases from overseas.
                </Text>
              </Flex>
            }
            placement="bottom"
            background="white"
            color="charcoal"
            border="1px solid #1ABAB0"
            hasArrow
            textAlign="center"
            arrowShadowColor="#1ABAB0"
            arrowSize={15}
          >
            <Text as="span" color="tone3" textStyle="body3">
              Received more money than you expected?
              <Icon
                as={IconInfoCircle}
                color="tone6"
                fontSize="16px"
                ml={1}
                mt="-3px"
              />
            </Text>
          </Tooltip>
        </Flex>

        {isNotNegativeSale && (
          <Text as="span" color="stampRed" textStyle="body4">
            oops! The price of your listing has to cover transaction fees.
            Please go back and increase the listing price.
          </Text>
        )}
      </Box>

      <TransactionDrawerFooter variation="no-shadow">
        <Button
          colorScheme="aqua"
          height="56px"
          width="100%"
          type="submit"
          isLoading={isLoading}
          isDisabled={isNotNegativeSale}
          onClick={() => onSubmit()}
        >
          Continue
        </Button>
      </TransactionDrawerFooter>
    </Box>
  );
};

import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents `true` or `false` values. */
  BooleanType: any;
  CustomData: any;
  /** A ISO 8601 compliant date value */
  Date: any;
  /** A ISO 8601 compliant datetime value */
  DateTime: any;
  /** Represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point). */
  FloatType: any;
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  IntType: any;
  ItemId: any;
  JsonField: any;
  MetaTagAttributes: any;
  UploadId: any;
};

export type AboutUsPageModelContentBlocksField =
  | CenteredImageAndTextBlockSectionRecord
  | ImagesWithTitleRecord
  | OurTeamRecord
  | SisterCompanyLogoRecord;

/** Record of type About Us Page (about_us_page) */
export type AboutUsPageRecord = RecordInterface & {
  __typename?: 'AboutUsPageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  contentBlocks: Array<AboutUsPageModelContentBlocksField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  seo?: Maybe<SeoField>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type About Us Page (about_us_page) */
export type AboutUsPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type AddVotePostAttributes = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type Addon = {
  __typename?: 'Addon';
  id: Scalars['String'];
  image: Scalars['String'];
  maxPieces: Scalars['Int'];
  price: AddonPricing;
  requiresAddress: Scalars['Boolean'];
  title: Scalars['String'];
};

export type AddonPricing = {
  __typename?: 'AddonPricing';
  currency: Scalars['String'];
  formatPrice: Scalars['Boolean'];
  postFix: Scalars['String'];
  priceIncludeVAT: Scalars['Float'];
  symbol: Scalars['String'];
  totalVat: Scalars['Int'];
  value: Scalars['Float'];
  vat: Scalars['Boolean'];
};

export type AddonResponse = {
  __typename?: 'AddonResponse';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includeVAT?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  countryCode: Scalars['String'];
  lineOne: Scalars['String'];
  lineTwo: Scalars['String'];
  postcode: Scalars['String'];
};

export type AddressInfo = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  lineOne?: InputMaybe<Scalars['String']>;
  lineTwo?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  lineOne: Scalars['String'];
  lineTwo?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
};

export type Admin = {
  __typename?: 'Admin';
  token: Scalars['String'];
  username: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  addonsOverview?: Maybe<Array<Maybe<Addon>>>;
  allAssetOfferings?: Maybe<Array<Maybe<AssetOffering>>>;
  currentAssetOffering?: Maybe<AssetOffering>;
  id: Scalars['String'];
  lastAssetTransactionOverview?: Maybe<LastAssetTransactionOverview>;
  listingOverview: ListingOverview;
  quantityAvailable: Scalars['Int'];
  status: Scalars['String'];
  title: Scalars['String'];
  transactionOverview: TransactionOverview;
};

/** Block of type Asset Category Banner (asset_category_banner) */
export type AssetCategoryBannerRecord = RecordInterface & {
  __typename?: 'AssetCategoryBannerRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  cards: Array<CategoryCardRecord>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Asset Category Banner (asset_category_banner) */
export type AssetCategoryBannerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type AssetForTransfer = {
  __typename?: 'AssetForTransfer';
  id: Scalars['String'];
  redeemCode: Scalars['String'];
};

export type AssetModelModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetModelModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetModelModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  assetId?: InputMaybe<StringFilter>;
  assetImage?: InputMaybe<FileFilter>;
  backgroundColor?: InputMaybe<ColorFilter>;
  cardDescription?: InputMaybe<TextFilter>;
  cardImage?: InputMaybe<FileFilter>;
  cardSubtitle?: InputMaybe<StringFilter>;
  cardTitle?: InputMaybe<StringFilter>;
  cardTitleSize?: InputMaybe<StringFilter>;
  cardType?: InputMaybe<StringFilter>;
  certificateImage?: InputMaybe<FileFilter>;
  certificateTitle?: InputMaybe<StringFilter>;
  checkoutPageTitle?: InputMaybe<StringFilter>;
  company?: InputMaybe<StringFilter>;
  companyLogo?: InputMaybe<FileFilter>;
  companyPrefix?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  deed?: InputMaybe<TextFilter>;
  description?: InputMaybe<StringFilter>;
  foregroundColor?: InputMaybe<ColorFilter>;
  funFact?: InputMaybe<TextFilter>;
  giftCertificateImage?: InputMaybe<FileFilter>;
  giftDeed?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  labelsColor?: InputMaybe<ColorFilter>;
  logoImage?: InputMaybe<FileFilter>;
  marketplaceBuyerDeed?: InputMaybe<TextFilter>;
  marketplaceSellerDeed?: InputMaybe<TextFilter>;
  momentOfDelight?: InputMaybe<StringFilter>;
  ownAPiecePageTitle?: InputMaybe<StringFilter>;
  postPurchaseAssetImage?: InputMaybe<FileFilter>;
  postPurchasePartnerImage?: InputMaybe<FileFilter>;
  product?: InputMaybe<LinkFilter>;
  purchaseTerms?: InputMaybe<FileFilter>;
  sampleDeed?: InputMaybe<FileFilter>;
  seo?: InputMaybe<SeoFilter>;
  setImageHeroBackground?: InputMaybe<BooleanFilter>;
  slug?: InputMaybe<SlugFilter>;
  stripImage?: InputMaybe<FileFilter>;
  subTitle?: InputMaybe<StringFilter>;
  theme?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum AssetModelModelOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
  _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
  _isValid_ASC = '_isValid_ASC',
  _isValid_DESC = '_isValid_DESC',
  _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
  _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
  _publishedAt_ASC = '_publishedAt_ASC',
  _publishedAt_DESC = '_publishedAt_DESC',
  _status_ASC = '_status_ASC',
  _status_DESC = '_status_DESC',
  _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
  _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  assetId_ASC = 'assetId_ASC',
  assetId_DESC = 'assetId_DESC',
  cardSubtitle_ASC = 'cardSubtitle_ASC',
  cardSubtitle_DESC = 'cardSubtitle_DESC',
  cardTitleSize_ASC = 'cardTitleSize_ASC',
  cardTitleSize_DESC = 'cardTitleSize_DESC',
  cardTitle_ASC = 'cardTitle_ASC',
  cardTitle_DESC = 'cardTitle_DESC',
  cardType_ASC = 'cardType_ASC',
  cardType_DESC = 'cardType_DESC',
  certificateTitle_ASC = 'certificateTitle_ASC',
  certificateTitle_DESC = 'certificateTitle_DESC',
  checkoutPageTitle_ASC = 'checkoutPageTitle_ASC',
  checkoutPageTitle_DESC = 'checkoutPageTitle_DESC',
  companyPrefix_ASC = 'companyPrefix_ASC',
  companyPrefix_DESC = 'companyPrefix_DESC',
  company_ASC = 'company_ASC',
  company_DESC = 'company_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  description_ASC = 'description_ASC',
  description_DESC = 'description_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  momentOfDelight_ASC = 'momentOfDelight_ASC',
  momentOfDelight_DESC = 'momentOfDelight_DESC',
  ownAPiecePageTitle_ASC = 'ownAPiecePageTitle_ASC',
  ownAPiecePageTitle_DESC = 'ownAPiecePageTitle_DESC',
  setImageHeroBackground_ASC = 'setImageHeroBackground_ASC',
  setImageHeroBackground_DESC = 'setImageHeroBackground_DESC',
  subTitle_ASC = 'subTitle_ASC',
  subTitle_DESC = 'subTitle_DESC',
  theme_ASC = 'theme_ASC',
  theme_DESC = 'theme_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
}

/** Record of type Asset (asset_model) */
export type AssetModelRecord = RecordInterface & {
  __typename?: 'AssetModelRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  assetId?: Maybe<Scalars['String']>;
  assetImage?: Maybe<FileField>;
  backgroundColor?: Maybe<ColorField>;
  benefits: Array<BenefitTierRecord>;
  cardDescription?: Maybe<Scalars['String']>;
  cardImage?: Maybe<FileField>;
  cardSubtitle?: Maybe<Scalars['String']>;
  cardTitle?: Maybe<Scalars['String']>;
  cardTitleSize?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  certificateImage?: Maybe<FileField>;
  certificateTitle?: Maybe<Scalars['String']>;
  checkoutPageTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<FileField>;
  companyPrefix?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deed?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  foregroundColor?: Maybe<ColorField>;
  funFact?: Maybe<Scalars['String']>;
  giftCertificateImage?: Maybe<FileField>;
  giftDeed?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  labelsColor?: Maybe<ColorField>;
  logoImage?: Maybe<FileField>;
  marketplaceBuyerDeed?: Maybe<Scalars['String']>;
  marketplaceSellerDeed?: Maybe<Scalars['String']>;
  momentOfDelight?: Maybe<Scalars['String']>;
  ownAPiecePageTitle?: Maybe<Scalars['String']>;
  postPurchaseAssetImage?: Maybe<FileField>;
  postPurchasePartnerImage?: Maybe<FileField>;
  product?: Maybe<ProductRecord>;
  purchaseTerms?: Maybe<FileField>;
  sampleDeed?: Maybe<FileField>;
  seo?: Maybe<SeoField>;
  setImageHeroBackground?: Maybe<Scalars['BooleanType']>;
  slug?: Maybe<Scalars['String']>;
  stripImage?: Maybe<FileField>;
  subTitle?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Asset (asset_model) */
export type AssetModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Asset (asset_model) */
export type AssetModelRecordCardDescriptionArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Record of type Asset (asset_model) */
export type AssetModelRecordDeedArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Record of type Asset (asset_model) */
export type AssetModelRecordFunFactArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Record of type Asset (asset_model) */
export type AssetModelRecordGiftDeedArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Record of type Asset (asset_model) */
export type AssetModelRecordMarketplaceBuyerDeedArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Record of type Asset (asset_model) */
export type AssetModelRecordMarketplaceSellerDeedArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

export type AssetOffering = {
  __typename?: 'AssetOffering';
  end: Scalars['DateTime'];
  id: Scalars['String'];
  pricing: AssetOfferingPricing;
  start: Scalars['DateTime'];
  transactionOverview: TransactionOverview;
};

export type AssetOfferingPricing = {
  __typename?: 'AssetOfferingPricing';
  currency: Scalars['String'];
  formatPrice: Scalars['Boolean'];
  postFix: Scalars['String'];
  pricePerPiece: Scalars['Float'];
  symbol: Scalars['String'];
};

export type AssetOfferingResponse = {
  __typename?: 'AssetOfferingResponse';
  description: Scalars['String'];
  end: Scalars['String'];
  id: Scalars['String'];
  priceInGbp: Scalars['Int'];
  quantityAvailable: Scalars['Int'];
  start: Scalars['String'];
  totalPiecesLeft: Scalars['Int'];
};

export type AssetOfferingsPostAttributes = {
  end: Scalars['DateTime'];
  id: Scalars['String'];
  priceInGbp: Scalars['Int'];
  quantityAvailable: Scalars['Int'];
  start: Scalars['DateTime'];
};

export type AssetOverview = {
  __typename?: 'AssetOverview';
  assetId: Scalars['String'];
  displayedSide: Scalars['Int'];
  localValue: LocalValue;
  recentPurchaseDate: Scalars['String'];
  recentPurchasePieces: Scalars['Int'];
  recentPurchaseValue: Scalars['Int'];
  status: Scalars['String'];
  sumOwnedAssets: Scalars['Int'];
  totalPiecesAvailable: Scalars['Int'];
  totalPiecesOwned: Scalars['Int'];
};

export type AssetPostAttributes = {
  assetOfferings: Array<InputMaybe<AssetOfferingsPostAttributes>>;
  quantityAvailable: Scalars['Int'];
  stagingStripeProductId: Scalars['String'];
  status: Scalars['String'];
  stripeProductId: Scalars['String'];
  title: Scalars['String'];
  valueInGbp: Scalars['Int'];
};

export type AssetResponse = {
  __typename?: 'AssetResponse';
  assetOfferings: Array<Maybe<AssetOfferingResponse>>;
  id: Scalars['String'];
  publishStatus: Scalars['String'];
  quantityAvailable: Scalars['Int'];
  stagingStripeProductId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  stripeProductId: Scalars['String'];
  title: Scalars['String'];
  totalPiecesLeft: Scalars['Int'];
  valueInGbp: Scalars['String'];
};

export type BalanceResponse = {
  __typename?: 'BalanceResponse';
  assetId: Scalars['String'];
  quantityAvailable: Scalars['Int'];
  quantityOwned: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

/** Block of type Basic Image Text Card (basic_image_text_card) */
export type BasicImageTextCardRecord = RecordInterface & {
  __typename?: 'BasicImageTextCardRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  imageTag?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Basic Image Text Card (basic_image_text_card) */
export type BasicImageTextCardRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Basic Image Text Card (basic_image_text_card) */
export type BasicImageTextCardRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

export type BasicMessage = {
  __typename?: 'BasicMessage';
  message: Scalars['String'];
};

/** Block of type Benefit Tier (benefit_tier) */
export type BenefitTierRecord = RecordInterface & {
  __typename?: 'BenefitTierRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  benefitImage?: Maybe<FileField>;
  benefitList: Array<TextValueRecord>;
  coinColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  quantity?: Maybe<Scalars['IntType']>;
  textColor?: Maybe<ColorField>;
  tierIcon?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Benefit Tier (benefit_tier) */
export type BenefitTierRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['BooleanType']>;
};

export type BuyerTermsAndConditionsInfo = {
  currencyConversionAndStripe?: InputMaybe<Scalars['Boolean']>;
  directFromSeller?: InputMaybe<Scalars['Boolean']>;
  noRefunds?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']>;
};

/** Block of type Category Card (category_card) */
export type CategoryCardRecord = RecordInterface & {
  __typename?: 'CategoryCardRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Category Card (category_card) */
export type CategoryCardRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Image and Text Section (mobile image between text) (centered_image_and_text_block_section) */
export type CenteredImageAndTextBlockSectionRecord = RecordInterface & {
  __typename?: 'CenteredImageAndTextBlockSectionRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  centerTextMobile?: Maybe<Scalars['BooleanType']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  imagePosition?: Maybe<Scalars['String']>;
  preTitle?: Maybe<Scalars['String']>;
  primaryCtaText?: Maybe<Scalars['String']>;
  primaryCtaUrl?: Maybe<Scalars['String']>;
  socialLinks: Array<SocialLinkModelRecord>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Image and Text Section (mobile image between text) (centered_image_and_text_block_section) */
export type CenteredImageAndTextBlockSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  count: Scalars['IntType'];
};

export type CollectionOverview = {
  __typename?: 'CollectionOverview';
  assetOverviews?: Maybe<Array<AssetOverview>>;
  banksyVDMTokenId?: Maybe<Scalars['String']>;
  hasKYCPending: Scalars['Boolean'];
  id: Scalars['String'];
  userFirstName: Scalars['String'];
};

export enum ColorBucketType {
  black = 'black',
  blue = 'blue',
  brown = 'brown',
  cyan = 'cyan',
  green = 'green',
  grey = 'grey',
  orange = 'orange',
  pink = 'pink',
  purple = 'purple',
  red = 'red',
  white = 'white',
  yellow = 'yellow',
}

export type ColorField = {
  __typename?: 'ColorField';
  alpha: Scalars['IntType'];
  blue: Scalars['IntType'];
  cssRgb: Scalars['String'];
  green: Scalars['IntType'];
  hex: Scalars['String'];
  red: Scalars['IntType'];
};

/** Specifies how to filter Color fields */
export type ColorFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  active: Scalars['Boolean'];
  amount: Scalars['Int'];
  code: Scalars['String'];
  currency: Scalars['String'];
  dateCreated?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  referrer: Scalars['String'];
  user: Scalars['String'];
};

export type CreateAssetPostAttributes = {
  assetOfferings: Array<InputMaybe<AssetOfferingsPostAttributes>>;
  category: Scalars['String'];
  id: Scalars['String'];
  quantityAvailable: Scalars['Int'];
  stagingStripeProductId: Scalars['String'];
  status: Scalars['String'];
  stripeProductId: Scalars['String'];
  valueInGbp: Scalars['Int'];
};

export type CreateTransactionPostAttributes = {
  amount: Scalars['Int'];
  assetId: Scalars['String'];
  assetOfferingId: Scalars['String'];
  currency: Scalars['String'];
  method: Scalars['String'];
  paymentIntentId: Scalars['String'];
  quantity: Scalars['Int'];
  status: Scalars['String'];
  targetUserId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']>;
};

/** Block of type Document Download (document_download) */
export type DocumentDownloadRecord = RecordInterface & {
  __typename?: 'DocumentDownloadRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  document?: Maybe<FileField>;
  documentName?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};

/** Block of type Document Download (document_download) */
export type DocumentDownloadRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type FAQ Placeholder (faq_placeholder) */
export type FaqPlaceholderRecord = RecordInterface & {
  __typename?: 'FaqPlaceholderRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type FAQ Placeholder (faq_placeholder) */
export type FaqPlaceholderRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type FAQ Story Placeholder (faq_story_placeholder) */
export type FaqStoryPlaceholderRecord = RecordInterface & {
  __typename?: 'FaqStoryPlaceholderRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};

/** Block of type FAQ Story Placeholder (faq_story_placeholder) */
export type FaqStoryPlaceholderRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum FaviconType {
  appleTouchIcon = 'appleTouchIcon',
  icon = 'icon',
  msApplication = 'msApplication',
}

/** Block of type Featured Image (featured_image) */
export type FeaturedImageRecord = RecordInterface & {
  __typename?: 'FeaturedImageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Featured Image (featured_image) */
export type FeaturedImageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type FileField = FileFieldInterface & {
  __typename?: 'FileField';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _updatedAt: Scalars['DateTime'];
  alt?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  basename: Scalars['String'];
  blurUpThumb?: Maybe<Scalars['String']>;
  blurhash?: Maybe<Scalars['String']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']>;
  customData: Scalars['CustomData'];
  exifInfo: Scalars['CustomData'];
  filename: Scalars['String'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String'];
  height?: Maybe<Scalars['IntType']>;
  id: Scalars['UploadId'];
  md5: Scalars['String'];
  mimeType: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType'];
  smartTags: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  thumbhash?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']>;
};

export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float'];
  quality?: Scalars['Int'];
  size?: Scalars['Int'];
};

export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']>;
};

export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _updatedAt: Scalars['DateTime'];
  alt?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  basename: Scalars['String'];
  blurUpThumb?: Maybe<Scalars['String']>;
  blurhash?: Maybe<Scalars['String']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']>;
  customData: Scalars['CustomData'];
  exifInfo: Scalars['CustomData'];
  filename: Scalars['String'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String'];
  height?: Maybe<Scalars['IntType']>;
  id: Scalars['UploadId'];
  md5: Scalars['String'];
  mimeType: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType'];
  smartTags: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  thumbhash?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']>;
};

export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float'];
  quality?: Scalars['Int'];
  size?: Scalars['Int'];
};

export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']>;
};

export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars['UploadId']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars['UploadId']>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']>>>;
};

/** Block of type General Mailing List Banner (general_mailing_list_banner) */
export type GeneralMailingListBannerRecord = RecordInterface & {
  __typename?: 'GeneralMailingListBannerRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  subHeading?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type General Mailing List Banner (general_mailing_list_banner) */
export type GeneralMailingListBannerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type Geolocation = {
  __typename?: 'Geolocation';
  canBuyOnTheMarketplace: Scalars['Boolean'];
  canSellOnTheMarketplace: Scalars['Boolean'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  currencyCode: Scalars['String'];
  currencyPostfix?: Maybe<Scalars['String']>;
  currencySymbol: Scalars['String'];
  formatPrice: Scalars['Boolean'];
  paymentMethod: Scalars['String'];
  stripeMonthlyFee: Scalars['Int'];
  triggerKYCAmount: Scalars['Int'];
};

export type Gift = {
  isGift: Scalars['Boolean'];
  recipientName?: InputMaybe<Scalars['String']>;
};

export type GiftCardsPageModelGiftCardsPageLayoutField =
  | ImageAndTextBlockSectionRecord
  | ImageAndTextPageHeaderRecord
  | ProductCarouselRecord;

/** Record of type Gift Cards Page (gift_cards_page) */
export type GiftCardsPageRecord = RecordInterface & {
  __typename?: 'GiftCardsPageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  giftCardsPageLayout: Array<GiftCardsPageModelGiftCardsPageLayoutField>;
  id: Scalars['ItemId'];
  seo?: Maybe<SeoField>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Gift Cards Page (gift_cards_page) */
export type GiftCardsPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type GlobalSeoField = {
  __typename?: 'GlobalSeoField';
  facebookPageUrl?: Maybe<Scalars['String']>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars['String']>;
  titleSuffix?: Maybe<Scalars['String']>;
  twitterAccount?: Maybe<Scalars['String']>;
};

/** Block of type Heading With Button (heading_with_button) */
export type HeadingWithButtonRecord = RecordInterface & {
  __typename?: 'HeadingWithButtonRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};

/** Block of type Heading With Button (heading_with_button) */
export type HeadingWithButtonRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type HomePageModelSectionsField =
  | AssetCategoryBannerRecord
  | CenteredImageAndTextBlockSectionRecord
  | FaqPlaceholderRecord
  | GeneralMailingListBannerRecord
  | HeadingWithButtonRecord
  | ProductCarouselRecord
  | SisterCompanyLogoRecord
  | StoryCarouselRecord
  | VerticalProcessRecord
  | VideoRecord;

/** Record of type Home Page (home_page) */
export type HomePageRecord = RecordInterface & {
  __typename?: 'HomePageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  assetCard?: Maybe<AssetModelRecord>;
  cardAccentColor?: Maybe<Scalars['String']>;
  cardImage?: Maybe<FileField>;
  cardSubtitle?: Maybe<Scalars['String']>;
  cardUrl?: Maybe<Scalars['String']>;
  cartTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  heroBackgroundImage?: Maybe<FileField>;
  heroBackgroundMobileImage?: Maybe<FileField>;
  hidePurchaseInfo?: Maybe<Scalars['BooleanType']>;
  howItWorksImage?: Maybe<FileField>;
  id: Scalars['ItemId'];
  primaryLink?: Maybe<Scalars['String']>;
  primaryLinkUrl?: Maybe<Scalars['String']>;
  process: Array<ProcessStepRecord>;
  publictionHeading?: Maybe<Scalars['String']>;
  publictionImages: Array<FileField>;
  secondaryLink?: Maybe<Scalars['String']>;
  secondaryLinkUrl?: Maybe<Scalars['String']>;
  sections: Array<HomePageModelSectionsField>;
  seo?: Maybe<SeoField>;
  signUpDescription?: Maybe<Scalars['String']>;
  signUpTitle?: Maybe<Scalars['String']>;
  subHeading?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Home Page (home_page) */
export type HomePageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Home Page (home_page) */
export type HomePageRecordSubHeadingArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Block of type Horizontal Timeline (horizontal_timeline) */
export type HorizontalTimelineRecord = RecordInterface & {
  __typename?: 'HorizontalTimelineRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  timeline: Array<TimelineItemRecord>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Horizontal Timeline (horizontal_timeline) */
export type HorizontalTimelineRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImageAndTextBlockModelStructuredTextField = {
  __typename?: 'ImageAndTextBlockModelStructuredTextField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

/** Block of type Image and Text Block (image_and_text_block) */
export type ImageAndTextBlockRecord = RecordInterface & {
  __typename?: 'ImageAndTextBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  imageSide?: Maybe<Scalars['String']>;
  imagesCarousel: Array<FileField>;
  structuredText?: Maybe<ImageAndTextBlockModelStructuredTextField>;
  updatedAt: Scalars['DateTime'];
  video?: Maybe<VideoField>;
};

/** Block of type Image and Text Block (image_and_text_block) */
export type ImageAndTextBlockRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImageAndTextBlockSectionModelStructuredTextField = {
  __typename?: 'ImageAndTextBlockSectionModelStructuredTextField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

/** Block of type Image and Text Section (image_and_text_block_section) */
export type ImageAndTextBlockSectionRecord = RecordInterface & {
  __typename?: 'ImageAndTextBlockSectionRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  centerTextMobile?: Maybe<Scalars['BooleanType']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  imagePosition?: Maybe<Scalars['String']>;
  imagePositionMobile?: Maybe<Scalars['String']>;
  structuredText?: Maybe<ImageAndTextBlockSectionModelStructuredTextField>;
  textStyle?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Image and Text Section (image_and_text_block_section) */
export type ImageAndTextBlockSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Image and Text Page Header (image_and_text_page_header) */
export type ImageAndTextPageHeaderRecord = RecordInterface & {
  __typename?: 'ImageAndTextPageHeaderRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  primaryCtaText?: Maybe<Scalars['String']>;
  primaryCtaUrl?: Maybe<Scalars['String']>;
  secondaryCtaText?: Maybe<Scalars['String']>;
  secondaryCtaUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Image and Text Page Header (image_and_text_page_header) */
export type ImageAndTextPageHeaderRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Image and Text Page Header (image_and_text_page_header) */
export type ImageAndTextPageHeaderRecordBodyArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Block of type Image Carousel (image_carousel) */
export type ImageCarouselRecord = RecordInterface & {
  __typename?: 'ImageCarouselRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  imageCarousel: Array<FileField>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Image Carousel (image_carousel) */
export type ImageCarouselRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Image with Link (image_with_link) */
export type ImageWithLinkRecord = RecordInterface & {
  __typename?: 'ImageWithLinkRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  linkUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Image with Link (image_with_link) */
export type ImageWithLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Images with Title (images_with_title) */
export type ImagesWithTitleRecord = RecordInterface & {
  __typename?: 'ImagesWithTitleRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  images: Array<ImageWithLinkRecord>;
  preTitle?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Images with Title (images_with_title) */
export type ImagesWithTitleRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: InputMaybe<Scalars['String']>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: InputMaybe<Scalars['String']>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background-removal/bg-remove)
   */
  bgRemove?: InputMaybe<Scalars['BooleanType']>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: InputMaybe<Scalars['String']>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars['IntType']>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars['String']>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: InputMaybe<Scalars['FloatType']>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: InputMaybe<Scalars['IntType']>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: InputMaybe<Scalars['FloatType']>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: InputMaybe<Scalars['IntType']>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: InputMaybe<Scalars['IntType']>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: InputMaybe<Scalars['IntType']>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: InputMaybe<Scalars['String']>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars['IntType']>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars['IntType']>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: InputMaybe<Scalars['String']>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: InputMaybe<Scalars['String']>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars['IntType']>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars['IntType']>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: InputMaybe<Scalars['IntType']>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: InputMaybe<Scalars['IntType']>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: InputMaybe<Scalars['IntType']>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: InputMaybe<Scalars['IntType']>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: InputMaybe<Scalars['IntType']>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars['String']>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: InputMaybe<Scalars['String']>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: InputMaybe<Scalars['IntType']>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: InputMaybe<Scalars['FloatType']>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars['String']>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars['IntType']>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: InputMaybe<Scalars['IntType']>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: InputMaybe<Scalars['IntType']>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: InputMaybe<Scalars['IntType']>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: InputMaybe<Scalars['FloatType']>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: InputMaybe<Scalars['IntType']>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars['String']>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: InputMaybe<Scalars['BooleanType']>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: InputMaybe<Scalars['FloatType']>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: InputMaybe<Scalars['FloatType']>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: InputMaybe<Scalars['FloatType']>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps?: InputMaybe<Scalars['IntType']>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame?: InputMaybe<Scalars['String']>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: InputMaybe<Scalars['IntType']>;
  /**
   * Animated Gif Quality
   *
   * Depends on: `fm=gif`
   */
  gifQ?: InputMaybe<Scalars['IntType']>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: InputMaybe<Scalars['String']>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: InputMaybe<Scalars['IntType']>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: InputMaybe<Scalars['FloatType']>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: InputMaybe<Scalars['IntType']>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: InputMaybe<Scalars['IntType']>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: InputMaybe<Scalars['IntType']>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval?: InputMaybe<Scalars['IntType']>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: InputMaybe<Scalars['BooleanType']>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: InputMaybe<ImgixParamsIptc>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop?: InputMaybe<Scalars['IntType']>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: InputMaybe<Scalars['BooleanType']>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: InputMaybe<Scalars['String']>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: InputMaybe<Scalars['IntType']>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: InputMaybe<Scalars['String']>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: InputMaybe<Scalars['FloatType']>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: InputMaybe<Scalars['IntType']>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: InputMaybe<Scalars['FloatType']>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: InputMaybe<Scalars['IntType']>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: InputMaybe<Scalars['FloatType']>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: InputMaybe<Scalars['IntType']>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: InputMaybe<Scalars['IntType']>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: InputMaybe<Scalars['String']>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: InputMaybe<Scalars['String']>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: InputMaybe<Scalars['IntType']>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: InputMaybe<Scalars['IntType']>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: InputMaybe<Scalars['IntType']>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: InputMaybe<Scalars['IntType']>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars['String']>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: InputMaybe<Scalars['IntType']>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: InputMaybe<Scalars['IntType']>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: InputMaybe<Scalars['IntType']>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: InputMaybe<Scalars['IntType']>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: InputMaybe<Scalars['IntType']>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: InputMaybe<Scalars['IntType']>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: InputMaybe<Scalars['IntType']>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: InputMaybe<Scalars['IntType']>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: InputMaybe<Scalars['IntType']>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars['BooleanType']>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: InputMaybe<Scalars['String']>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: InputMaybe<Scalars['IntType']>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: InputMaybe<Scalars['IntType']>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: InputMaybe<Scalars['String']>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse?: InputMaybe<Scalars['BooleanType']>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: InputMaybe<Scalars['FloatType']>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: InputMaybe<Scalars['IntType']>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: InputMaybe<Scalars['IntType']>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: InputMaybe<Scalars['FloatType']>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: InputMaybe<Scalars['FloatType']>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip?: InputMaybe<Scalars['IntType']>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars['String']>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: InputMaybe<Scalars['FloatType']>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: InputMaybe<Scalars['IntType']>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: InputMaybe<Scalars['FloatType']>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: InputMaybe<Scalars['FloatType']>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: InputMaybe<Scalars['String']>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: InputMaybe<Scalars['String']>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: InputMaybe<Scalars['String']>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: InputMaybe<Scalars['IntType']>;
  /**
   * Text Ligatures
   *
   * Controls the level of ligature substitution
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-lig)
   */
  txtLig?: InputMaybe<Scalars['IntType']>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: InputMaybe<Scalars['IntType']>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: InputMaybe<Scalars['String']>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: InputMaybe<Scalars['IntType']>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: InputMaybe<Scalars['FloatType']>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: InputMaybe<Scalars['IntType']>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: InputMaybe<Scalars['IntType']>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: InputMaybe<Scalars['IntType']>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: InputMaybe<Scalars['IntType']>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: InputMaybe<Scalars['IntType']>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: InputMaybe<Scalars['IntType']>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: InputMaybe<Scalars['FloatType']>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: InputMaybe<Scalars['IntType']>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: InputMaybe<Scalars['FloatType']>;
};

export enum ImgixParamsAuto {
  compress = 'compress',
  enhance = 'enhance',
  format = 'format',
  redeye = 'redeye',
}

export enum ImgixParamsBlendAlign {
  bottom = 'bottom',
  center = 'center',
  left = 'left',
  middle = 'middle',
  right = 'right',
  top = 'top',
}

export enum ImgixParamsBlendCrop {
  bottom = 'bottom',
  faces = 'faces',
  left = 'left',
  right = 'right',
  top = 'top',
}

export enum ImgixParamsBlendFit {
  clamp = 'clamp',
  clip = 'clip',
  crop = 'crop',
  max = 'max',
  scale = 'scale',
}

export enum ImgixParamsBlendMode {
  burn = 'burn',
  color = 'color',
  darken = 'darken',
  difference = 'difference',
  dodge = 'dodge',
  exclusion = 'exclusion',
  hardlight = 'hardlight',
  hue = 'hue',
  lighten = 'lighten',
  luminosity = 'luminosity',
  multiply = 'multiply',
  normal = 'normal',
  overlay = 'overlay',
  saturation = 'saturation',
  screen = 'screen',
  softlight = 'softlight',
}

export enum ImgixParamsBlendSize {
  inherit = 'inherit',
}

export enum ImgixParamsCh {
  dpr = 'dpr',
  saveData = 'saveData',
  width = 'width',
}

export enum ImgixParamsCrop {
  bottom = 'bottom',
  edges = 'edges',
  entropy = 'entropy',
  faces = 'faces',
  focalpoint = 'focalpoint',
  left = 'left',
  right = 'right',
  top = 'top',
}

export enum ImgixParamsCs {
  adobergb1998 = 'adobergb1998',
  srgb = 'srgb',
  strip = 'strip',
  tinysrgb = 'tinysrgb',
}

export enum ImgixParamsFill {
  blur = 'blur',
  solid = 'solid',
}

export enum ImgixParamsFit {
  clamp = 'clamp',
  clip = 'clip',
  crop = 'crop',
  facearea = 'facearea',
  fill = 'fill',
  fillmax = 'fillmax',
  max = 'max',
  min = 'min',
  scale = 'scale',
}

export enum ImgixParamsFlip {
  h = 'h',
  hv = 'hv',
  v = 'v',
}

export enum ImgixParamsFm {
  avif = 'avif',
  blurhash = 'blurhash',
  gif = 'gif',
  jp2 = 'jp2',
  jpg = 'jpg',
  json = 'json',
  jxr = 'jxr',
  mp4 = 'mp4',
  pjpg = 'pjpg',
  png = 'png',
  png8 = 'png8',
  png32 = 'png32',
  webm = 'webm',
  webp = 'webp',
}

export enum ImgixParamsIptc {
  allow = 'allow',
  block = 'block',
}

export enum ImgixParamsMarkAlign {
  bottom = 'bottom',
  center = 'center',
  left = 'left',
  middle = 'middle',
  right = 'right',
  top = 'top',
}

export enum ImgixParamsMarkFit {
  clip = 'clip',
  crop = 'crop',
  fill = 'fill',
  max = 'max',
  scale = 'scale',
}

export enum ImgixParamsMarkTile {
  grid = 'grid',
}

export enum ImgixParamsPalette {
  css = 'css',
  json = 'json',
}

export enum ImgixParamsTransparency {
  grid = 'grid',
}

export enum ImgixParamsTrim {
  auto = 'auto',
  color = 'color',
}

export enum ImgixParamsTxtAlign {
  bottom = 'bottom',
  center = 'center',
  left = 'left',
  middle = 'middle',
  right = 'right',
  top = 'top',
}

export enum ImgixParamsTxtClip {
  ellipsis = 'ellipsis',
  end = 'end',
  middle = 'middle',
  start = 'start',
}

export enum ImgixParamsTxtFit {
  max = 'max',
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars['ItemId']>;
  /** Search records with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']>>>;
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars['ItemId']>;
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']>>>;
};

export enum ItemStatus {
  draft = 'draft',
  published = 'published',
  updated = 'updated',
}

/** Block of type Left/Right Text Column (left_right_text_column) */
export type LeftRightTextColumnRecord = RecordInterface & {
  __typename?: 'LeftRightTextColumnRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  columnTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  leftColumn?: Maybe<Scalars['String']>;
  rightColumn?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Left/Right Text Column (left_right_text_column) */
export type LeftRightTextColumnRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Left/Right Text Column (left_right_text_column) */
export type LeftRightTextColumnRecordLeftColumnArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Block of type Left/Right Text Column (left_right_text_column) */
export type LeftRightTextColumnRecordRightColumnArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: InputMaybe<Scalars['ItemId']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records linked to one of the specified records */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']>>>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: InputMaybe<Scalars['ItemId']>;
  /** Filter records not linked to one of the specified records */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']>>>;
};

export enum ListType {
  DARWIN = 'DARWIN',
  EDWARD = 'EDWARD',
  EDWARD_MARKETPLACE = 'EDWARD_MARKETPLACE',
  MAGENTA = 'MAGENTA',
  MAGENTA_MARKETPLACE = 'MAGENTA_MARKETPLACE',
  MARKETPLACE_SELLER = 'MARKETPLACE_SELLER',
  MARKETPLACE_USER = 'MARKETPLACE_USER',
  NEWSLETTER = 'NEWSLETTER',
  VDM = 'VDM',
  WARHOL = 'WARHOL',
}

export type Listing = {
  __typename?: 'Listing';
  assetId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  currency: Scalars['String'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  price: ListingPrice;
  quantity: Scalars['Int'];
  quantityAvailable: Scalars['Int'];
  sellerLocation?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type ListingOverview = {
  __typename?: 'ListingOverview';
  averagePrice: Scalars['Float'];
  highestPrice: Scalars['Float'];
  lowestPrice: Scalars['Float'];
  totalActiveNFPs: Scalars['Int'];
};

export type ListingPrice = {
  __typename?: 'ListingPrice';
  currency: Scalars['String'];
  formatPrice: Scalars['Boolean'];
  postFix: Scalars['String'];
  symbol: Scalars['String'];
  value: Scalars['Float'];
};

export type ListingResponse = {
  __typename?: 'ListingResponse';
  assetId: Scalars['String'];
  createdAt: Scalars['Date'];
  currency: Scalars['String'];
  expirationDate: Scalars['Date'];
  id: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  quantityAvailable: Scalars['Int'];
  status: Scalars['String'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type LocalValue = {
  __typename?: 'LocalValue';
  currency: Scalars['String'];
  postFix?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
};

/** Record of type Marketplace Page (marketplace_page) */
export type MarketplacePageRecord = RecordInterface & {
  __typename?: 'MarketplacePageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  marketplaceCards: Array<AssetModelRecord>;
  seo?: Maybe<SeoField>;
  showpieceIpo: Array<AssetModelRecord>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Marketplace Page (marketplace_page) */
export type MarketplacePageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type MarketplaceTransactionResponse = {
  __typename?: 'MarketplaceTransactionResponse';
  amount: Scalars['Int'];
  assetId: Scalars['String'];
  capturingAt: Scalars['String'];
  completedAt: Scalars['String'];
  createdAt: Scalars['String'];
  currency: Scalars['String'];
  error: Scalars['String'];
  failedAt: Scalars['String'];
  id: Scalars['String'];
  listingId: Scalars['String'];
  method: Scalars['String'];
  paymentIntentId: Scalars['String'];
  quantity: Scalars['Int'];
  recipientName: Scalars['String'];
  redeemCode: Scalars['String'];
  status: Scalars['String'];
  targetUserId: Scalars['String'];
  userId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addListing: Scalars['String'];
  addOffer: Scalars['String'];
  addUserSignature: User;
  addUserToMailingList: Scalars['String'];
  addUserVote: Scalars['String'];
  adminCreateAsset: Scalars['String'];
  adminCreateTransaction: Scalars['String'];
  adminRefundTransaction: Scalars['String'];
  adminUpdateAsset: Scalars['String'];
  adminUpdateTransaction: Scalars['String'];
  adminUpdateUser: Scalars['String'];
  assignReferral?: Maybe<BasicMessage>;
  cancelTransfer: Scalars['String'];
  checkRedeemCode?: Maybe<RedeemTransaction>;
  checkUserSocialLoginAccount: Scalars['String'];
  createCheckoutSession: Scalars['String'];
  createTransfer: AssetForTransfer;
  deleteListing: Scalars['Boolean'];
  findUser?: Maybe<Array<Maybe<SearchUserResponse>>>;
  getAllAssets: Array<Maybe<AssetResponse>>;
  getAllListings: Array<Maybe<ListingResponse>>;
  getAllMarketplaceTransactions: Array<Maybe<MarketplaceTransactionResponse>>;
  getAllTransactions: Array<Maybe<TransactionResponse>>;
  getAllUsers: Array<Maybe<UserResponse>>;
  getAllUsersCollections: Array<Maybe<UserCollectionResponse>>;
  getPaymentStatus: PaymentStatusResponse;
  getUserCollectionOverview?: Maybe<Array<AssetOverview>>;
  loginAdmin: Scalars['String'];
  payOffer: PayOfferResponse;
  publishAsset: Scalars['String'];
  redeemTransfer: TransferredAsset;
  registerAdmin: Scalars['Boolean'];
  root: Scalars['String'];
  sendReferrerCode: BasicMessage;
  setPriceAlert: Scalars['String'];
  suggestUsername: Scalars['String'];
  updateUser: User;
  updateUserDisplayedSideAsset: Scalars['String'];
  userVotedForSurvey: Scalars['Boolean'];
  verifyPromoCode: Coupon;
};

export type MutationAddListingArgs = {
  addressInfo?: InputMaybe<AddressInfo>;
  assetId: Scalars['String'];
  bankInfo?: InputMaybe<BankInfo>;
  pieces?: InputMaybe<Array<Scalars['String']>>;
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  termsAndConditionsInfo?: InputMaybe<TermsAndConditionsInfo>;
};

export type MutationAddOfferArgs = {
  assetId: Scalars['String'];
  listingId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type MutationAddUserSignatureArgs = {
  img: Scalars['String'];
};

export type MutationAddUserToMailingListArgs = {
  countryCode: Scalars['String'];
  email: Scalars['String'];
  listType: ListType;
};

export type MutationAddUserVoteArgs = {
  data: Array<InputMaybe<AddVotePostAttributes>>;
  surveyId: Scalars['String'];
};

export type MutationAdminCreateAssetArgs = {
  data: CreateAssetPostAttributes;
};

export type MutationAdminCreateTransactionArgs = {
  data: CreateTransactionPostAttributes;
};

export type MutationAdminRefundTransactionArgs = {
  id: Scalars['String'];
};

export type MutationAdminUpdateAssetArgs = {
  data: AssetPostAttributes;
  id: Scalars['String'];
};

export type MutationAdminUpdateTransactionArgs = {
  data: TransactionPostAttributes;
  id: Scalars['String'];
};

export type MutationAdminUpdateUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type MutationAssignReferralArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type MutationCancelTransferArgs = {
  transactionId: Scalars['String'];
};

export type MutationCheckRedeemCodeArgs = {
  code: Scalars['String'];
};

export type MutationCheckUserSocialLoginAccountArgs = {
  username: Scalars['String'];
};

export type MutationCreateCheckoutSessionArgs = {
  addons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  assetId: Scalars['String'];
  couponCode?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  discountCode?: InputMaybe<Scalars['String']>;
  gift?: InputMaybe<Gift>;
  quantity: Scalars['Int'];
  shippingAddress?: InputMaybe<Scalars['String']>;
};

export type MutationCreateTransferArgs = {
  assetOfferingId: Scalars['String'];
  currency: Scalars['String'];
  quantity: Scalars['Int'];
  recipientName?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteListingArgs = {
  listingId: Scalars['String'];
};

export type MutationFindUserArgs = {
  search: Scalars['String'];
};

export type MutationGetAllAssetsArgs = {
  after: Scalars['String'];
};

export type MutationGetAllListingsArgs = {
  after: Scalars['String'];
};

export type MutationGetAllMarketplaceTransactionsArgs = {
  after: Scalars['String'];
};

export type MutationGetAllTransactionsArgs = {
  after: Scalars['String'];
};

export type MutationGetAllUsersArgs = {
  after: Scalars['String'];
};

export type MutationGetAllUsersCollectionsArgs = {
  assetId: Scalars['String'];
};

export type MutationGetPaymentStatusArgs = {
  paymentId: Scalars['String'];
};

export type MutationGetUserCollectionOverviewArgs = {
  userId: Scalars['String'];
};

export type MutationLoginAdminArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationPayOfferArgs = {
  addressInfo?: InputMaybe<AddressInfo>;
  assetId: Scalars['String'];
  cardToken?: InputMaybe<Scalars['String']>;
  termsAndConditionsInfo?: InputMaybe<BuyerTermsAndConditionsInfo>;
};

export type MutationPublishAssetArgs = {
  assetId: Scalars['String'];
};

export type MutationRedeemTransferArgs = {
  transactionId: Scalars['String'];
  transactionIntentId: Scalars['String'];
};

export type MutationRegisterAdminArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationSendReferrerCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type MutationSetPriceAlertArgs = {
  assetId: Scalars['String'];
  currency: Scalars['String'];
  email: Scalars['String'];
  price: Scalars['Int'];
};

export type MutationSuggestUsernameArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateUserArgs = {
  categoriesInterestedIn?: InputMaybe<Array<Scalars['String']>>;
  collectorType?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateUserDisplayedSideAssetArgs = {
  assetId: Scalars['String'];
  side: Scalars['Int'];
};

export type MutationUserVotedForSurveyArgs = {
  surveyId: Scalars['String'];
};

export type MutationVerifyPromoCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export enum MuxThumbnailFormatType {
  gif = 'gif',
  jpg = 'jpg',
  png = 'png',
}

export enum NfpListingStatus {
  LISTED = 'LISTED',
  UNLISTED = 'UNLISTED',
}

/** Record of type 1c Magenta Page (one_c_magenta_page) */
export type OneCMagentaPageRecord = RecordInterface & {
  __typename?: 'OneCMagentaPageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  relatedProducts: Array<ProductCarouselRecord>;
  seo?: Maybe<SeoField>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type 1c Magenta Page (one_c_magenta_page) */
export type OneCMagentaPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>;
};

/** Block of type Our Team (our_team) */
export type OurTeamRecord = RecordInterface & {
  __typename?: 'OurTeamRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  preTitle?: Maybe<Scalars['String']>;
  teamMembers: Array<TeamMemberModelRecord>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Our Team (our_team) */
export type OurTeamRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type PartnershipBannerModelTextField = {
  __typename?: 'PartnershipBannerModelTextField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

/** Block of type Partnership Banner (partnership_banner) */
export type PartnershipBannerRecord = RecordInterface & {
  __typename?: 'PartnershipBannerRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  backgroundColourSide?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  desktopLogo?: Maybe<FileField>;
  id: Scalars['ItemId'];
  mobileLogo?: Maybe<FileField>;
  text?: Maybe<PartnershipBannerModelTextField>;
  textColor?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Partnership Banner (partnership_banner) */
export type PartnershipBannerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type PayOfferResponse = {
  __typename?: 'PayOfferResponse';
  paymentId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type PaymentStatusResponse = {
  __typename?: 'PaymentStatusResponse';
  status: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type PostCodeDetails = {
  __typename?: 'PostCodeDetails';
  addresses?: Maybe<Array<Maybe<Scalars['String']>>>;
  postCode: Scalars['String'];
};

/** Block of type Process step (process_step) */
export type ProcessStepRecord = RecordInterface & {
  __typename?: 'ProcessStepRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  hideOnMobile?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Process step (process_step) */
export type ProcessStepRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Process step (process_step) */
export type ProcessStepRecordDescriptionArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Block of type Product carousel (product_carousel) */
export type ProductCarouselRecord = RecordInterface & {
  __typename?: 'ProductCarouselRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  productCarouselSubheading?: Maybe<Scalars['String']>;
  selectedAssets: Array<AssetModelRecord>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Product carousel (product_carousel) */
export type ProductCarouselRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ProductModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProductModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ProductModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  asset?: InputMaybe<LinkFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  heroBackgroundImage?: InputMaybe<BooleanFilter>;
  heroContent?: InputMaybe<StructuredTextFilter>;
  heroDesktopImage?: InputMaybe<FileFilter>;
  heroImage?: InputMaybe<FileFilter>;
  heroMobileImage?: InputMaybe<FileFilter>;
  heroPartnershipLogo?: InputMaybe<FileFilter>;
  heroTitle?: InputMaybe<StructuredTextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seo?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export type ProductModelHeroContentField = {
  __typename?: 'ProductModelHeroContentField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

export type ProductModelHeroTitleField = {
  __typename?: 'ProductModelHeroTitleField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

export enum ProductModelOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
  _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
  _isValid_ASC = '_isValid_ASC',
  _isValid_DESC = '_isValid_DESC',
  _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
  _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
  _publishedAt_ASC = '_publishedAt_ASC',
  _publishedAt_DESC = '_publishedAt_DESC',
  _status_ASC = '_status_ASC',
  _status_DESC = '_status_DESC',
  _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
  _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  heroBackgroundImage_ASC = 'heroBackgroundImage_ASC',
  heroBackgroundImage_DESC = 'heroBackgroundImage_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
}

export type ProductModelSectionsField =
  | FaqStoryPlaceholderRecord
  | FeaturedImageRecord
  | HorizontalTimelineRecord
  | ImageAndTextBlockSectionRecord
  | ImageCarouselRecord
  | LeftRightTextColumnRecord
  | PartnershipBannerRecord
  | ProductCarouselRecord
  | QuoteBlockBannerRecord
  | StepProcessBannerRecord
  | StoryCarouselRecord
  | TitleRecord
  | VideoRecord;

/** Record of type Product (product) */
export type ProductRecord = RecordInterface & {
  __typename?: 'ProductRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  asset?: Maybe<AssetModelRecord>;
  createdAt: Scalars['DateTime'];
  heroBackgroundImage?: Maybe<Scalars['BooleanType']>;
  heroContent?: Maybe<ProductModelHeroContentField>;
  heroDesktopImage?: Maybe<FileField>;
  heroImage?: Maybe<FileField>;
  heroMobileImage?: Maybe<FileField>;
  heroPartnershipLogo?: Maybe<FileField>;
  heroTitle?: Maybe<ProductModelHeroTitleField>;
  id: Scalars['ItemId'];
  sections: Array<ProductModelSectionsField>;
  seo?: Maybe<SeoField>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Product (product) */
export type ProductRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type PublicCollectionOverview = {
  __typename?: 'PublicCollectionOverview';
  assetOverviews?: Maybe<Array<AssetOverview>>;
  hasKYCPending: Scalars['Boolean'];
  id: Scalars['String'];
  signature?: Maybe<Scalars['String']>;
  userFirstName: Scalars['String'];
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']>;
};

export type QrPageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<QrPageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<QrPageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  ctaColor?: InputMaybe<ColorFilter>;
  ctaText?: InputMaybe<StringFilter>;
  ctaUrl?: InputMaybe<StringFilter>;
  headerBackgroundColor?: InputMaybe<ColorFilter>;
  headerImage?: InputMaybe<FileFilter>;
  headerMobileImage?: InputMaybe<FileFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seo?: InputMaybe<SeoFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum QrPageModelOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
  _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
  _isValid_ASC = '_isValid_ASC',
  _isValid_DESC = '_isValid_DESC',
  _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
  _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
  _publishedAt_ASC = '_publishedAt_ASC',
  _publishedAt_DESC = '_publishedAt_DESC',
  _status_ASC = '_status_ASC',
  _status_DESC = '_status_DESC',
  _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
  _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  ctaText_ASC = 'ctaText_ASC',
  ctaText_DESC = 'ctaText_DESC',
  ctaUrl_ASC = 'ctaUrl_ASC',
  ctaUrl_DESC = 'ctaUrl_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
}

/** Record of type QR Page (qr_page) */
export type QrPageRecord = RecordInterface & {
  __typename?: 'QrPageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  ctaColor?: Maybe<ColorField>;
  ctaText?: Maybe<Scalars['String']>;
  ctaUrl?: Maybe<Scalars['String']>;
  headerBackgroundColor?: Maybe<ColorField>;
  headerImage?: Maybe<FileField>;
  headerMobileImage?: Maybe<FileField>;
  id: Scalars['ItemId'];
  seo?: Maybe<SeoField>;
  storyImageAndText: Array<ImageAndTextBlockRecord>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type QR Page (qr_page) */
export type QrPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query = {
  __typename?: 'Query';
  /** Returns meta information regarding a record collection */
  _allAssetModelsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allQrPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSocialLinkModelsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTeamMemberModelsMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allVotingSurvey1sMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns the single instance record */
  aboutUsPage?: Maybe<AboutUsPageRecord>;
  addressLookup: PostCodeDetails;
  admin: Admin;
  /** Returns a collection of records */
  allAssetModels: Array<AssetModelRecord>;
  /** Returns a collection of records */
  allProducts: Array<ProductRecord>;
  /** Returns a collection of records */
  allQrPages: Array<QrPageRecord>;
  /** Returns a collection of records */
  allSocialLinkModels: Array<SocialLinkModelRecord>;
  /** Returns a collection of records */
  allTeamMemberModels: Array<TeamMemberModelRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns a collection of records */
  allVotingSurvey1s: Array<VotingSurvey1Record>;
  asset: Asset;
  /** Returns a specific record */
  assetModel?: Maybe<AssetModelRecord>;
  couponsByUserId?: Maybe<Array<Maybe<Coupon>>>;
  generateConnectAccountLink?: Maybe<Scalars['String']>;
  getActiveListings?: Maybe<Array<Maybe<Listing>>>;
  getAllListingsByUserId?: Maybe<Array<Maybe<Listing>>>;
  getGeolocation: Geolocation;
  getListingsByAssetId?: Maybe<Array<Maybe<Listing>>>;
  getUserAssetsById?: Maybe<UserBalance>;
  getUserCurrencyCodeByLocation: UserLocation;
  /** Returns the single instance record */
  giftCardsPage?: Maybe<GiftCardsPageRecord>;
  /** Returns the single instance record */
  homePage?: Maybe<HomePageRecord>;
  /** Returns the single instance record */
  marketplacePage?: Maybe<MarketplacePageRecord>;
  /** Returns the single instance record */
  oneCMagentaPage?: Maybe<OneCMagentaPageRecord>;
  /** Returns a specific record */
  product?: Maybe<ProductRecord>;
  /** Returns a specific record */
  qrPage?: Maybe<QrPageRecord>;
  root: Scalars['String'];
  rootWithReportedError: Scalars['String'];
  rootWithoutReportedError: Scalars['String'];
  /** Returns a specific record */
  socialLinkModel?: Maybe<SocialLinkModelRecord>;
  /** Returns a specific record */
  teamMemberModel?: Maybe<TeamMemberModelRecord>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  user: User;
  userByReferrerCode: User;
  userCollectionOverview: PublicCollectionOverview;
  /** Returns the single instance record */
  valentinesDayMascaraPage?: Maybe<ValentinesDayMascaraPageRecord>;
  validIBAN: Scalars['Boolean'];
  validPostcode: Scalars['Boolean'];
  /** Returns a specific record */
  votingSurvey1?: Maybe<VotingSurvey1Record>;
};

/** The query root for this schema */
export type Query_AllAssetModelsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AssetModelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllProductsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllQrPagesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<QrPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllSocialLinkModelsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SocialLinkModelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTeamMemberModelsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TeamMemberModelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllVotingSurvey1sMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<VotingSurvey1ModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAboutUsPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAddressLookupArgs = {
  code?: InputMaybe<Scalars['String']>;
};

/** The query root for this schema */
export type QueryAllAssetModelsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AssetModelModelFilter>;
  first?: InputMaybe<Scalars['IntType']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AssetModelModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']>;
};

/** The query root for this schema */
export type QueryAllProductsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductModelFilter>;
  first?: InputMaybe<Scalars['IntType']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']>;
};

/** The query root for this schema */
export type QueryAllQrPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<QrPageModelFilter>;
  first?: InputMaybe<Scalars['IntType']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<QrPageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']>;
};

/** The query root for this schema */
export type QueryAllSocialLinkModelsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SocialLinkModelModelFilter>;
  first?: InputMaybe<Scalars['IntType']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SocialLinkModelModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']>;
};

/** The query root for this schema */
export type QueryAllTeamMemberModelsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TeamMemberModelModelFilter>;
  first?: InputMaybe<Scalars['IntType']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberModelModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']>;
};

/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars['IntType']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']>;
};

/** The query root for this schema */
export type QueryAllVotingSurvey1sArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<VotingSurvey1ModelFilter>;
  first?: InputMaybe<Scalars['IntType']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<VotingSurvey1ModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']>;
};

/** The query root for this schema */
export type QueryAssetArgs = {
  id: Scalars['String'];
};

/** The query root for this schema */
export type QueryAssetModelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AssetModelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AssetModelModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryCouponsByUserIdArgs = {
  id: Scalars['String'];
};

/** The query root for this schema */
export type QueryGetActiveListingsArgs = {
  assetId: Scalars['String'];
};

/** The query root for this schema */
export type QueryGetAllListingsByUserIdArgs = {
  userId?: InputMaybe<Scalars['String']>;
};

/** The query root for this schema */
export type QueryGetListingsByAssetIdArgs = {
  assetId: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
};

/** The query root for this schema */
export type QueryGetUserAssetsByIdArgs = {
  assetId: Scalars['String'];
};

/** The query root for this schema */
export type QueryGetUserCurrencyCodeByLocationArgs = {
  id?: InputMaybe<Scalars['String']>;
};

/** The query root for this schema */
export type QueryGiftCardsPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryHomePageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryMarketplacePageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryOneCMagentaPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryProductArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryQrPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<QrPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<QrPageModelOrderBy>>>;
};

/** The query root for this schema */
export type QuerySocialLinkModelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<SocialLinkModelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<SocialLinkModelModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTeamMemberModelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TeamMemberModelModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberModelModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};

/** The query root for this schema */
export type QueryUserByReferrerCodeArgs = {
  id: Scalars['String'];
};

/** The query root for this schema */
export type QueryUserCollectionOverviewArgs = {
  userId: Scalars['String'];
};

/** The query root for this schema */
export type QueryValentinesDayMascaraPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryValidIbanArgs = {
  iban?: InputMaybe<Scalars['String']>;
};

/** The query root for this schema */
export type QueryValidPostcodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};

/** The query root for this schema */
export type QueryVotingSurvey1Args = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<VotingSurvey1ModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<VotingSurvey1ModelOrderBy>>>;
};

/** Block of type Quote Block Banner (quote_block_banner) */
export type QuoteBlockBannerRecord = RecordInterface & {
  __typename?: 'QuoteBlockBannerRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  cite?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  quote?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Quote Block Banner (quote_block_banner) */
export type QuoteBlockBannerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Quote Block Banner (quote_block_banner) */
export type QuoteBlockBannerRecordQuoteArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

export type RecordInterface = {
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
};

export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type RedeemTransaction = {
  __typename?: 'RedeemTransaction';
  assetId: Scalars['String'];
  id: Scalars['ID'];
  intentId: Scalars['String'];
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  icon = 'icon',
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export type ResponsiveImage = {
  __typename?: 'ResponsiveImage';
  alt?: Maybe<Scalars['String']>;
  aspectRatio: Scalars['FloatType'];
  base64?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  height: Scalars['IntType'];
  sizes: Scalars['String'];
  src: Scalars['String'];
  srcSet: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  webpSrcSet: Scalars['String'];
  width: Scalars['IntType'];
};

export type SearchUserResponse = {
  __typename?: 'SearchUserResponse';
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SeoField = {
  __typename?: 'SeoField';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<FileField>;
  title?: Maybe<Scalars['String']>;
  twitterCard?: Maybe<Scalars['String']>;
};

/** Specifies how to filter SEO meta tags fields */
export type SeoFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
};

/** Block of type Sister Company Logos (sister_company_logo) */
export type SisterCompanyLogoRecord = RecordInterface & {
  __typename?: 'SisterCompanyLogoRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  sisterCompanyHeading?: Maybe<Scalars['String']>;
  sisterCompanyImage: Array<FileField>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Sister Company Logos (sister_company_logo) */
export type SisterCompanyLogoRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type Site = {
  __typename?: 'Site';
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
};

export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};

export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum SiteLocale {
  en = 'en',
}

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']>;
  /** Filter records that have one of the specified slugs */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']>;
  /** Filter records that do have one of the specified slugs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SocialLinkModelModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<SocialLinkModelModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SocialLinkModelModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  linkTitle?: InputMaybe<StringFilter>;
  linkUrl?: InputMaybe<StringFilter>;
  socialIcon?: InputMaybe<FileFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum SocialLinkModelModelOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
  _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
  _isValid_ASC = '_isValid_ASC',
  _isValid_DESC = '_isValid_DESC',
  _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
  _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
  _publishedAt_ASC = '_publishedAt_ASC',
  _publishedAt_DESC = '_publishedAt_DESC',
  _status_ASC = '_status_ASC',
  _status_DESC = '_status_DESC',
  _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
  _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  linkTitle_ASC = 'linkTitle_ASC',
  linkTitle_DESC = 'linkTitle_DESC',
  linkUrl_ASC = 'linkUrl_ASC',
  linkUrl_DESC = 'linkUrl_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
}

/** Record of type Social Link (social_link_model) */
export type SocialLinkModelRecord = RecordInterface & {
  __typename?: 'SocialLinkModelRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  linkTitle?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  socialIcon?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Social Link (social_link_model) */
export type SocialLinkModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

/** Block of type Step Process Banner (step_process_banner) */
export type StepProcessBannerRecord = RecordInterface & {
  __typename?: 'StepProcessBannerRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  steps: Array<ProcessStepRecord>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Step Process Banner (step_process_banner) */
export type StepProcessBannerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Story Carousel (story_carousel) */
export type StoryCarouselRecord = RecordInterface & {
  __typename?: 'StoryCarouselRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  storyContent: Array<StoryItemRecord>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Story Carousel (story_carousel) */
export type StoryCarouselRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Story Item (story_item) */
export type StoryItemRecord = RecordInterface & {
  __typename?: 'StoryItemRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  imageDescription?: Maybe<Scalars['String']>;
  imageTitle?: Maybe<Scalars['String']>;
  storyCarouselImage?: Maybe<FileField>;
  subtitle?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Story Item (story_item) */
export type StoryItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Story Item (story_item) */
export type StoryItemRecordImageDescriptionArgs = {
  markdown?: InputMaybe<Scalars['Boolean']>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']>;
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars['BooleanType']>;
  pattern: Scalars['String'];
  regexp?: InputMaybe<Scalars['BooleanType']>;
};

export enum StripeAccountStatus {
  MISSING_INFORMATION = 'MISSING_INFORMATION',
  PENDING = 'PENDING',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

/** Specifies how to filter Structured Text fields */
export type StructuredTextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type Tag = {
  __typename?: 'Tag';
  attributes?: Maybe<Scalars['MetaTagAttributes']>;
  content?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type TeamMemberModelModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TeamMemberModelModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TeamMemberModelModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  name?: InputMaybe<StringFilter>;
  role?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum TeamMemberModelModelOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
  _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
  _isValid_ASC = '_isValid_ASC',
  _isValid_DESC = '_isValid_DESC',
  _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
  _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
  _publishedAt_ASC = '_publishedAt_ASC',
  _publishedAt_DESC = '_publishedAt_DESC',
  _status_ASC = '_status_ASC',
  _status_DESC = '_status_DESC',
  _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
  _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  role_ASC = 'role_ASC',
  role_DESC = 'role_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
}

/** Record of type Team Member (team_member_model) */
export type TeamMemberModelRecord = RecordInterface & {
  __typename?: 'TeamMemberModelRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Team Member (team_member_model) */
export type TeamMemberModelRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type TermsAndConditionsInfo = {
  crossBorderCharge?: InputMaybe<Scalars['Boolean']>;
  currencyConversionAndStripe?: InputMaybe<Scalars['Boolean']>;
  fundsTimeline?: InputMaybe<Scalars['Boolean']>;
  noRefunds?: InputMaybe<Scalars['Boolean']>;
  sellingDirectToBuyer?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']>;
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Block of type Benefit Text (text_value) */
export type TextValueRecord = RecordInterface & {
  __typename?: 'TextValueRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  benefitText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};

/** Block of type Benefit Text (text_value) */
export type TextValueRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Timeline Item (timeline_item) */
export type TimelineItemRecord = RecordInterface & {
  __typename?: 'TimelineItemRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Timeline Item (timeline_item) */
export type TimelineItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Title (title) */
export type TitleRecord = RecordInterface & {
  __typename?: 'TitleRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Title (title) */
export type TitleRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type Transaction = {
  __typename?: 'Transaction';
  asset: Asset;
  id: Scalars['ID'];
  price: TransactionPrice;
  quantity: Scalars['Int'];
  redeemCode: Scalars['String'];
  status: TransactionStatus;
  transactionDate: Scalars['DateTime'];
  transactionDirection: TransactionDirection;
  type: TransactionType;
};

export enum TransactionDirection {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export type TransactionOverview = {
  __typename?: 'TransactionOverview';
  id: Scalars['String'];
  kycMaxPieces: Scalars['Int'];
  price: TransactionOverviewPricing;
  totalCustomersTransacted: Scalars['Int'];
  totalNumberOfTransactions: Scalars['Int'];
  totalPieces: Scalars['Int'];
  totalPiecesAvailable: Scalars['Int'];
  totalPiecesSold: Scalars['Int'];
};

export type TransactionOverviewPricing = {
  __typename?: 'TransactionOverviewPricing';
  averagePrice: Scalars['Int'];
  currency: Scalars['String'];
  formatPrice: Scalars['Boolean'];
  lastSoldPrice: Scalars['Float'];
  postFix: Scalars['String'];
  symbol: Scalars['String'];
};

export type TransactionPostAttributes = {
  amount: Scalars['Int'];
  assetId: Scalars['String'];
  assetOfferingId: Scalars['String'];
  currency: Scalars['String'];
  method: Scalars['String'];
  paymentIntentId: Scalars['String'];
  quantity: Scalars['Int'];
  recipientName: Scalars['String'];
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type TransactionPrice = {
  __typename?: 'TransactionPrice';
  code: Scalars['String'];
  formatPrice: Scalars['Boolean'];
  postFix: Scalars['String'];
  symbol: Scalars['String'];
  value: Scalars['Float'];
};

export type TransactionResponse = {
  __typename?: 'TransactionResponse';
  addons?: Maybe<Array<Maybe<AddonResponse>>>;
  amount: Scalars['Int'];
  assetId: Scalars['String'];
  assetOfferingId: Scalars['String'];
  capturingAt: Scalars['String'];
  completedAt: Scalars['String'];
  createdAt: Scalars['String'];
  currency: Scalars['String'];
  error: Scalars['String'];
  failedAt: Scalars['String'];
  id: Scalars['String'];
  method: Scalars['String'];
  paymentIntentId: Scalars['String'];
  quantity: Scalars['Int'];
  recipientName: Scalars['String'];
  redeemCode: Scalars['String'];
  shippingAddress?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  targetUserId: Scalars['String'];
  userId: Scalars['String'];
};

export enum TransactionStatus {
  CAPTURING = 'CAPTURING',
  CAPTURING_KYC = 'CAPTURING_KYC',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  TRANSFER_PENDING = 'TRANSFER_PENDING',
  TRANSFER_REDEEMED = 'TRANSFER_REDEEMED',
}

export enum TransactionType {
  Gift = 'Gift',
  Purchase = 'Purchase',
  Sale = 'Sale',
  Transfer = 'Transfer',
}

export type TransferredAsset = {
  __typename?: 'TransferredAsset';
  assetName: Scalars['String'];
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in?: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn?: InputMaybe<Array<InputMaybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars['String']>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars['String']>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type UploadFilter = {
  AND?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  _createdAt?: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>;
  alt?: InputMaybe<UploadAltFilter>;
  author?: InputMaybe<UploadAuthorFilter>;
  basename?: InputMaybe<UploadBasenameFilter>;
  colors?: InputMaybe<UploadColorsFilter>;
  copyright?: InputMaybe<UploadCopyrightFilter>;
  filename?: InputMaybe<UploadFilenameFilter>;
  format?: InputMaybe<UploadFormatFilter>;
  height?: InputMaybe<UploadHeightFilter>;
  id?: InputMaybe<UploadIdFilter>;
  inUse?: InputMaybe<InUseFilter>;
  md5?: InputMaybe<UploadMd5Filter>;
  mimeType?: InputMaybe<UploadMimeTypeFilter>;
  notes?: InputMaybe<UploadNotesFilter>;
  orientation?: InputMaybe<OrientationFilter>;
  resolution?: InputMaybe<ResolutionFilter>;
  size?: InputMaybe<UploadSizeFilter>;
  smartTags?: InputMaybe<UploadTagsFilter>;
  tags?: InputMaybe<UploadTagsFilter>;
  title?: InputMaybe<UploadTitleFilter>;
  type?: InputMaybe<TypeFilter>;
  width?: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars['String']>;
  /** Search assets with the specified formats */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars['String']>;
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars['IntType']>;
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars['IntType']>;
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars['IntType']>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars['UploadId']>;
  /** Search assets with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']>>>;
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars['UploadId']>;
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars['String']>;
  /** Search assets with the specified MD5s */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars['String']>;
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars['String']>;
  /** Search assets with the specified mime types */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars['String']>;
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  basename_ASC = 'basename_ASC',
  basename_DESC = 'basename_DESC',
  filename_ASC = 'filename_ASC',
  filename_DESC = 'filename_DESC',
  format_ASC = 'format_ASC',
  format_DESC = 'format_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  mimeType_ASC = 'mimeType_ASC',
  mimeType_DESC = 'mimeType_DESC',
  resolution_ASC = 'resolution_ASC',
  resolution_DESC = 'resolution_DESC',
  size_ASC = 'size_ASC',
  size_DESC = 'size_DESC',
}

export enum UploadOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  square = 'square',
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars['IntType']>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars['IntType']>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars['IntType']>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Array<Scalars['String']>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Array<Scalars['String']>>;
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars['String']>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<Scalars['String']>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars['String']>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']>;
  /** Search assets with the specified as default title */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars['String']>;
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadType {
  archive = 'archive',
  audio = 'audio',
  image = 'image',
  pdfdocument = 'pdfdocument',
  presentation = 'presentation',
  richtext = 'richtext',
  spreadsheet = 'spreadsheet',
  video = 'video',
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']>;
};

export type UploadVideoField = {
  __typename?: 'UploadVideoField';
  duration?: Maybe<Scalars['Int']>;
  framerate?: Maybe<Scalars['Int']>;
  mp4Url?: Maybe<Scalars['String']>;
  muxAssetId: Scalars['String'];
  muxPlaybackId: Scalars['String'];
  streamingUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>;
  res?: InputMaybe<VideoMp4Res>;
};

export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars['IntType']>;
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars['IntType']>;
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars['IntType']>;
};

export type User = {
  __typename?: 'User';
  billingAddress?: Maybe<Address>;
  categoriesInterestedIn?: Maybe<Array<Scalars['String']>>;
  collectionOverview?: Maybe<CollectionOverview>;
  collectorType?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  referrerCode: Scalars['String'];
  shippingAddress?: Maybe<Address>;
  signature?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeAccountVerificationStatus?: Maybe<StripeAccountStatus>;
  stripePaymentMethods: Array<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transactions: Array<Transaction>;
};

export type UserBalance = {
  __typename?: 'UserBalance';
  pieces: Array<Maybe<UserPieces>>;
  quantityAvailable: Scalars['Int'];
  quantityOwned: Scalars['Int'];
};

export type UserCollectionResponse = {
  __typename?: 'UserCollectionResponse';
  assetId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  percentFromAllItems: Scalars['Float'];
  piecesOwned: Scalars['Int'];
  totalOwnershipValue: Scalars['Float'];
};

export type UserPieces = {
  __typename?: 'UserPieces';
  id: Scalars['String'];
  price: ListingPrice;
  status: NfpListingStatus;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  email: Scalars['String'];
  id: Scalars['String'];
  loginProvider: Scalars['String'];
  name: Scalars['String'];
  stripeCustomerId: Scalars['String'];
};

/** Record of type Valentines Day Mascara Page (valentines_day_mascara_page) */
export type ValentinesDayMascaraPageRecord = RecordInterface & {
  __typename?: 'ValentinesDayMascaraPageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  documents: Array<DocumentDownloadRecord>;
  gallery: Array<BasicImageTextCardRecord>;
  id: Scalars['ItemId'];
  relatedProducts: Array<ProductCarouselRecord>;
  seo?: Maybe<SeoField>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Valentines Day Mascara Page (valentines_day_mascara_page) */
export type ValentinesDayMascaraPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Vertical Process Steps (vertical_process) */
export type VerticalProcessRecord = RecordInterface & {
  __typename?: 'VerticalProcessRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  process: Array<ProcessStepRecord>;
  updatedAt: Scalars['DateTime'];
};

/** Block of type Vertical Process Steps (vertical_process) */
export type VerticalProcessRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Embed Video (video_embed) */
export type VideoEmbedRecord = RecordInterface & {
  __typename?: 'VideoEmbedRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  embedVideo?: Maybe<VideoField>;
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};

/** Block of type Embed Video (video_embed) */
export type VideoEmbedRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type VideoField = {
  __typename?: 'VideoField';
  height: Scalars['IntType'];
  provider: Scalars['String'];
  providerUid: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['IntType'];
};

/** Block of type Video File (video_file) */
export type VideoFileRecord = RecordInterface & {
  __typename?: 'VideoFileRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  thumbnail?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

/** Block of type Video File (video_file) */
export type VideoFileRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type VideoModelVideoContentField = VideoEmbedRecord | VideoFileRecord;

export enum VideoMp4Res {
  high = 'high',
  low = 'low',
  medium = 'medium',
}

/** Block of type Video (video) */
export type VideoRecord = RecordInterface & {
  __typename?: 'VideoRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
  videoContent: Array<VideoModelVideoContentField>;
  videoId?: Maybe<Scalars['String']>;
};

/** Block of type Video (video) */
export type VideoRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type VotingSurvey1ModelDescriptionField = {
  __typename?: 'VotingSurvey1ModelDescriptionField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

export type VotingSurvey1ModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<VotingSurvey1ModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VotingSurvey1ModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  createdAt?: InputMaybe<CreatedAtFilter>;
  description?: InputMaybe<StructuredTextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<UpdatedAtFilter>;
};

export enum VotingSurvey1ModelOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
  _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
  _isValid_ASC = '_isValid_ASC',
  _isValid_DESC = '_isValid_DESC',
  _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
  _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
  _publishedAt_ASC = '_publishedAt_ASC',
  _publishedAt_DESC = '_publishedAt_DESC',
  _status_ASC = '_status_ASC',
  _status_DESC = '_status_DESC',
  _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
  _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
}

/** Record of type Voting Survey 1 (voting_survey1) */
export type VotingSurvey1Record = RecordInterface & {
  __typename?: 'VotingSurvey1Record';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** SEO meta tags */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<VotingSurvey1ModelDescriptionField>;
  id: Scalars['ItemId'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Record of type Voting Survey 1 (voting_survey1) */
export type VotingSurvey1Record_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type BankInfo = {
  IBAN?: InputMaybe<Scalars['String']>;
  Swift?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
};

export type FocalPoint = {
  __typename?: 'focalPoint';
  x: Scalars['FloatType'];
  y: Scalars['FloatType'];
};

export type LastAssetTransactionOverview = {
  __typename?: 'lastAssetTransactionOverview';
  id: Scalars['String'];
  price: LastAssetTransactionPricing;
};

export type LastAssetTransactionPricing = {
  __typename?: 'lastAssetTransactionPricing';
  currency: Scalars['String'];
  formatPrice: Scalars['Boolean'];
  postFix: Scalars['String'];
  symbol: Scalars['String'];
  value: Scalars['Float'];
};

export type UserLocation = {
  __typename?: 'userLocation';
  currencyCode: Scalars['String'];
};

export type CreateCheckoutSessionMutationVariables = Exact<{
  assetId: Scalars['String'];
  currency: Scalars['String'];
  quantity: Scalars['Int'];
  gift?: InputMaybe<Gift>;
  discountCode?: InputMaybe<Scalars['String']>;
  couponCode?: InputMaybe<Scalars['String']>;
  addons?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  shippingAddress?: InputMaybe<Scalars['String']>;
}>;

export type CreateCheckoutSessionMutation = {
  __typename?: 'Mutation';
  createCheckoutSession: string;
};

export type AddUserSignatureMutationVariables = Exact<{
  img: Scalars['String'];
}>;

export type AddUserSignatureMutation = {
  __typename?: 'Mutation';
  addUserSignature: {
    __typename?: 'User';
    id: string;
    signature?: string | null;
  };
};

export type AddToMailingListMutationVariables = Exact<{
  email: Scalars['String'];
  countryCode: Scalars['String'];
  listType: ListType;
}>;

export type AddToMailingListMutation = {
  __typename?: 'Mutation';
  addUserToMailingList: string;
};

export type AddVoteMutationVariables = Exact<{
  surveyId: Scalars['String'];
  data:
    | Array<InputMaybe<AddVotePostAttributes>>
    | InputMaybe<AddVotePostAttributes>;
}>;

export type AddVoteMutation = {__typename?: 'Mutation'; addUserVote: string};

export type CheckVoteMutationVariables = Exact<{
  surveyId: Scalars['String'];
}>;

export type CheckVoteMutation = {
  __typename?: 'Mutation';
  userVotedForSurvey: boolean;
};

export type UpdateUserMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  collectorType?: InputMaybe<Scalars['String']>;
  categoriesInterestedIn?: InputMaybe<
    Array<Scalars['String']> | Scalars['String']
  >;
  shippingAddress?: InputMaybe<AddressInput>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth?: any | null;
    title?: string | null;
    collectorType?: string | null;
    categoriesInterestedIn?: Array<string> | null;
    shippingAddress?: {
      __typename?: 'Address';
      lineOne: string;
      lineTwo: string;
      city: string;
      postcode: string;
      countryCode: string;
    } | null;
  };
};

export type CheckRedeemCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type CheckRedeemCodeMutation = {
  __typename?: 'Mutation';
  checkRedeemCode?: {
    __typename?: 'RedeemTransaction';
    id: string;
    assetId: string;
    intentId: string;
  } | null;
};

export type RedeemTransferMutationVariables = Exact<{
  transactionId: Scalars['String'];
  transactionIntentId: Scalars['String'];
}>;

export type RedeemTransferMutation = {
  __typename?: 'Mutation';
  redeemTransfer: {
    __typename?: 'TransferredAsset';
    id: string;
    quantity: number;
    assetName: string;
  };
};

export type CreateTransferMutationVariables = Exact<{
  assetOfferingId: Scalars['String'];
  quantity: Scalars['Int'];
  currency: Scalars['String'];
  recipientName?: InputMaybe<Scalars['String']>;
}>;

export type CreateTransferMutation = {
  __typename?: 'Mutation';
  createTransfer: {
    __typename?: 'AssetForTransfer';
    id: string;
    redeemCode: string;
  };
};

export type CancelTransferMutationVariables = Exact<{
  transactionId: Scalars['String'];
}>;

export type CancelTransferMutation = {
  __typename?: 'Mutation';
  cancelTransfer: string;
};

export type UpdateUserDisplayedSideAssetMutationVariables = Exact<{
  assetId: Scalars['String'];
  side: Scalars['Int'];
}>;

export type UpdateUserDisplayedSideAssetMutation = {
  __typename?: 'Mutation';
  updateUserDisplayedSideAsset: string;
};

export type CheckUserSocialLoginAccountMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type CheckUserSocialLoginAccountMutation = {
  __typename?: 'Mutation';
  checkUserSocialLoginAccount: string;
};

export type SendReferrerCodeMutationVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}>;

export type SendReferrerCodeMutation = {
  __typename?: 'Mutation';
  sendReferrerCode: {__typename?: 'BasicMessage'; message: string};
};

export type AssignReferralMutationVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
}>;

export type AssignReferralMutation = {
  __typename?: 'Mutation';
  assignReferral?: {__typename?: 'BasicMessage'; message: string} | null;
};

export type VerifyPromoCodeMutationVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
}>;

export type VerifyPromoCodeMutation = {
  __typename?: 'Mutation';
  verifyPromoCode: {
    __typename?: 'Coupon';
    id: string;
    active: boolean;
    code: string;
    currency: string;
    amount: number;
  };
};

export type AddListingMutationVariables = Exact<{
  assetId: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  pieces?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  lineOne?: InputMaybe<Scalars['String']>;
  lineTwo?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  IBAN?: InputMaybe<Scalars['String']>;
  Swift?: InputMaybe<Scalars['String']>;
  fundsTimeline?: InputMaybe<Scalars['Boolean']>;
  sellingDirectToBuyer?: InputMaybe<Scalars['Boolean']>;
  noRefunds?: InputMaybe<Scalars['Boolean']>;
  currencyConversionAndStripe?: InputMaybe<Scalars['Boolean']>;
  crossBorderCharge?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']>;
}>;

export type AddListingMutation = {__typename?: 'Mutation'; addListing: string};

export type DeleteListingMutationVariables = Exact<{
  listingId: Scalars['String'];
}>;

export type DeleteListingMutation = {
  __typename?: 'Mutation';
  deleteListing: boolean;
};

export type AddOfferMutationVariables = Exact<{
  assetId: Scalars['String'];
  quantity: Scalars['Int'];
  listingId: Scalars['String'];
}>;

export type AddOfferMutation = {__typename?: 'Mutation'; addOffer: string};

export type PayOfferMutationVariables = Exact<{
  assetId: Scalars['String'];
  cardToken: Scalars['String'];
  countryCode: Scalars['String'];
  city: Scalars['String'];
  postcode?: InputMaybe<Scalars['String']>;
  lineOne?: InputMaybe<Scalars['String']>;
  lineTwo?: InputMaybe<Scalars['String']>;
  directFromSeller?: InputMaybe<Scalars['Boolean']>;
  noRefunds?: InputMaybe<Scalars['Boolean']>;
  currencyConversionAndStripe?: InputMaybe<Scalars['Boolean']>;
  termsAndConditions?: InputMaybe<Scalars['Boolean']>;
}>;

export type PayOfferMutation = {
  __typename?: 'Mutation';
  payOffer: {
    __typename?: 'PayOfferResponse';
    status: string;
    url?: string | null;
    paymentId?: string | null;
  };
};

export type GetPaymentStatusMutationVariables = Exact<{
  paymentId: Scalars['String'];
}>;

export type GetPaymentStatusMutation = {
  __typename?: 'Mutation';
  getPaymentStatus: {
    __typename?: 'PaymentStatusResponse';
    status: string;
    url?: string | null;
  };
};

export type SetPriceAlertMutationVariables = Exact<{
  price: Scalars['Int'];
  assetId: Scalars['String'];
  currency: Scalars['String'];
  email: Scalars['String'];
}>;

export type SetPriceAlertMutation = {
  __typename?: 'Mutation';
  setPriceAlert: string;
};

export type UserQueryVariables = Exact<{[key: string]: never}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    title?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    referrerCode: string;
    phoneNumber?: string | null;
    dateOfBirth?: any | null;
    stripeAccountId?: string | null;
    stripeAccountVerificationStatus?: StripeAccountStatus | null;
    collectorType?: string | null;
    categoriesInterestedIn?: Array<string> | null;
    shippingAddress?: {
      __typename?: 'Address';
      countryCode: string;
      city: string;
      postcode: string;
      lineOne: string;
      lineTwo: string;
    } | null;
  };
};

export type UserWithSignatureQueryVariables = Exact<{[key: string]: never}>;

export type UserWithSignatureQuery = {
  __typename?: 'Query';
  user: {__typename?: 'User'; id: string; signature?: string | null};
};

export type UserTransactionsQueryVariables = Exact<{[key: string]: never}>;

export type UserTransactionsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    transactions: Array<{
      __typename?: 'Transaction';
      id: string;
      type: TransactionType;
      quantity: number;
      status: TransactionStatus;
      transactionDate: any;
      transactionDirection: TransactionDirection;
      redeemCode: string;
      price: {
        __typename?: 'TransactionPrice';
        value: number;
        symbol: string;
        code: string;
        postFix: string;
        formatPrice: boolean;
      };
      asset: {__typename?: 'Asset'; id: string; title: string};
    }>;
  };
};

export type TransactionOverviewFragment = {
  __typename?: 'TransactionOverview';
  id: string;
  totalPieces: number;
  totalPiecesAvailable: number;
  totalPiecesSold: number;
  totalCustomersTransacted: number;
  totalNumberOfTransactions: number;
  kycMaxPieces: number;
  price: {
    __typename?: 'TransactionOverviewPricing';
    averagePrice: number;
    lastSoldPrice: number;
    currency: string;
    formatPrice: boolean;
    symbol: string;
    postFix: string;
  };
};

export type AssetQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AssetQuery = {
  __typename?: 'Query';
  asset: {
    __typename?: 'Asset';
    id: string;
    title: string;
    quantityAvailable: number;
    status: string;
    transactionOverview: {
      __typename?: 'TransactionOverview';
      id: string;
      totalPieces: number;
      totalPiecesAvailable: number;
      totalPiecesSold: number;
      totalCustomersTransacted: number;
      totalNumberOfTransactions: number;
      kycMaxPieces: number;
      price: {
        __typename?: 'TransactionOverviewPricing';
        averagePrice: number;
        lastSoldPrice: number;
        currency: string;
        formatPrice: boolean;
        symbol: string;
        postFix: string;
      };
    };
    lastAssetTransactionOverview?: {
      __typename?: 'lastAssetTransactionOverview';
      id: string;
      price: {__typename?: 'lastAssetTransactionPricing'; value: number};
    } | null;
    allAssetOfferings?: Array<{
      __typename?: 'AssetOffering';
      id: string;
      start: any;
      end: any;
      transactionOverview: {
        __typename?: 'TransactionOverview';
        id: string;
        totalPieces: number;
        totalPiecesAvailable: number;
        totalPiecesSold: number;
        totalCustomersTransacted: number;
        totalNumberOfTransactions: number;
        kycMaxPieces: number;
        price: {
          __typename?: 'TransactionOverviewPricing';
          averagePrice: number;
          lastSoldPrice: number;
          currency: string;
          formatPrice: boolean;
          symbol: string;
          postFix: string;
        };
      };
      pricing: {
        __typename?: 'AssetOfferingPricing';
        pricePerPiece: number;
        currency: string;
        formatPrice: boolean;
        symbol: string;
        postFix: string;
      };
    } | null> | null;
    currentAssetOffering?: {
      __typename?: 'AssetOffering';
      id: string;
      start: any;
      end: any;
      transactionOverview: {
        __typename?: 'TransactionOverview';
        id: string;
        totalPieces: number;
        totalPiecesAvailable: number;
        totalPiecesSold: number;
        totalCustomersTransacted: number;
        totalNumberOfTransactions: number;
        kycMaxPieces: number;
        price: {
          __typename?: 'TransactionOverviewPricing';
          averagePrice: number;
          lastSoldPrice: number;
          currency: string;
          formatPrice: boolean;
          symbol: string;
          postFix: string;
        };
      };
      pricing: {
        __typename?: 'AssetOfferingPricing';
        pricePerPiece: number;
        currency: string;
        formatPrice: boolean;
        symbol: string;
        postFix: string;
      };
    } | null;
    listingOverview: {
      __typename?: 'ListingOverview';
      lowestPrice: number;
      totalActiveNFPs: number;
    };
  };
};

export type AssetAddonsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AssetAddonsQuery = {
  __typename?: 'Query';
  asset: {
    __typename?: 'Asset';
    addonsOverview?: Array<{
      __typename?: 'Addon';
      id: string;
      title: string;
      image: string;
      maxPieces: number;
      requiresAddress: boolean;
      price: {
        __typename?: 'AddonPricing';
        value: number;
        vat: boolean;
        totalVat: number;
        priceIncludeVAT: number;
        currency: string;
        formatPrice: boolean;
        symbol: string;
        postFix: string;
      };
    } | null> | null;
  };
};

export type MyCollectionQueryVariables = Exact<{[key: string]: never}>;

export type MyCollectionQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    collectionOverview?: {
      __typename?: 'CollectionOverview';
      id: string;
      userFirstName: string;
      hasKYCPending: boolean;
      banksyVDMTokenId?: string | null;
      assetOverviews?: Array<{
        __typename?: 'AssetOverview';
        displayedSide: number;
        totalPiecesOwned: number;
        totalPiecesAvailable: number;
        assetId: string;
        status: string;
        recentPurchasePieces: number;
        recentPurchaseDate: string;
        recentPurchaseValue: number;
        sumOwnedAssets: number;
        localValue: {
          __typename?: 'LocalValue';
          total: number;
          currency: string;
          symbol?: string | null;
          postFix?: string | null;
        };
      }> | null;
    } | null;
  };
};

export type UserCollectionOverviewQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type UserCollectionOverviewQuery = {
  __typename?: 'Query';
  userCollectionOverview: {
    __typename?: 'PublicCollectionOverview';
    id: string;
    userFirstName: string;
    signature?: string | null;
    assetOverviews?: Array<{
      __typename?: 'AssetOverview';
      displayedSide: number;
      recentPurchaseDate: string;
      totalPiecesAvailable: number;
      totalPiecesOwned: number;
      assetId: string;
    }> | null;
  };
};

export type UserByReferrerCodeQueryVariables = Exact<{
  referrerId: Scalars['String'];
}>;

export type UserByReferrerCodeQuery = {
  __typename?: 'Query';
  userByReferrerCode: {
    __typename?: 'User';
    firstName: string;
    lastName: string;
    email: string;
  };
};

export type CouponsByUserIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type CouponsByUserIdQuery = {
  __typename?: 'Query';
  couponsByUserId?: Array<{
    __typename?: 'Coupon';
    active: boolean;
    code: string;
    referrer: string;
  } | null> | null;
};

export type GenerateConnectAccountLinkQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GenerateConnectAccountLinkQuery = {
  __typename?: 'Query';
  generateConnectAccountLink?: string | null;
};

export type GetActiveListingsQueryVariables = Exact<{
  assetId: Scalars['String'];
}>;

export type GetActiveListingsQuery = {
  __typename?: 'Query';
  getActiveListings?: Array<{
    __typename?: 'Listing';
    id: string;
    assetId?: string | null;
    userId: string;
    quantity: number;
    createdAt: any;
    expirationDate?: any | null;
    quantityAvailable: number;
    sellerLocation?: string | null;
    price: {
      __typename?: 'ListingPrice';
      value: number;
      currency: string;
      symbol: string;
      postFix: string;
      formatPrice: boolean;
    };
  } | null> | null;
};

export type AddressLookupQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
}>;

export type AddressLookupQuery = {
  __typename?: 'Query';
  addressLookup: {
    __typename?: 'PostCodeDetails';
    postCode: string;
    addresses?: Array<string | null> | null;
  };
};

export type ValidPostcodeQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
}>;

export type ValidPostcodeQuery = {__typename?: 'Query'; validPostcode: boolean};

export type ValidIbanQueryVariables = Exact<{
  iban?: InputMaybe<Scalars['String']>;
}>;

export type ValidIbanQuery = {__typename?: 'Query'; validIBAN: boolean};

export type GetUserAssetsByIdQueryVariables = Exact<{
  assetId: Scalars['String'];
}>;

export type GetUserAssetsByIdQuery = {
  __typename?: 'Query';
  getUserAssetsById?: {
    __typename?: 'UserBalance';
    quantityOwned: number;
    quantityAvailable: number;
    pieces: Array<{
      __typename?: 'UserPieces';
      id: string;
      status: NfpListingStatus;
      price: {
        __typename?: 'ListingPrice';
        value: number;
        currency: string;
        formatPrice: boolean;
        symbol: string;
        postFix: string;
      };
    } | null>;
  } | null;
};

export type GetAllListingsByUserIdQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;

export type GetAllListingsByUserIdQuery = {
  __typename?: 'Query';
  getAllListingsByUserId?: Array<{
    __typename?: 'Listing';
    id: string;
    userId: string;
    quantity: number;
    quantityAvailable: number;
    createdAt: any;
    expirationDate?: any | null;
    assetId?: string | null;
    price: {
      __typename?: 'ListingPrice';
      value: number;
      currency: string;
      formatPrice: boolean;
      symbol: string;
      postFix: string;
    };
  } | null> | null;
};

export type GetListingsByAssetIdQueryVariables = Exact<{
  assetId: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
}>;

export type GetListingsByAssetIdQuery = {
  __typename?: 'Query';
  getListingsByAssetId?: Array<{
    __typename?: 'Listing';
    id: string;
    userId: string;
    quantity: number;
    quantityAvailable: number;
    createdAt: any;
    expirationDate?: any | null;
    assetId?: string | null;
    status: string;
    currency: string;
    price: {
      __typename?: 'ListingPrice';
      value: number;
      currency: string;
      formatPrice: boolean;
      symbol: string;
      postFix: string;
    };
  } | null> | null;
};

export type GeoLookupQueryVariables = Exact<{[key: string]: never}>;

export type GeoLookupQuery = {
  __typename?: 'Query';
  getGeolocation: {
    __typename?: 'Geolocation';
    triggerKYCAmount: number;
    paymentMethod: string;
    countryName: string;
    countryCode: string;
    currencyCode: string;
    currencySymbol: string;
    currencyPostfix?: string | null;
    canSellOnTheMarketplace: boolean;
    canBuyOnTheMarketplace: boolean;
    formatPrice: boolean;
    stripeMonthlyFee: number;
  };
};

export const TransactionOverviewFragmentDoc = gql`
  fragment TransactionOverview on TransactionOverview {
    id
    totalPieces
    totalPiecesAvailable
    totalPiecesSold
    totalCustomersTransacted
    totalNumberOfTransactions
    price {
      averagePrice
      lastSoldPrice
      currency
      formatPrice
      symbol
      postFix
    }
    kycMaxPieces
  }
`;
export const CreateCheckoutSessionDocument = gql`
  mutation createCheckoutSession(
    $assetId: String!
    $currency: String!
    $quantity: Int!
    $gift: Gift
    $discountCode: String
    $couponCode: String
    $addons: [String]
    $shippingAddress: String
  ) {
    createCheckoutSession(
      assetId: $assetId
      currency: $currency
      quantity: $quantity
      gift: $gift
      discountCode: $discountCode
      couponCode: $couponCode
      addons: $addons
      shippingAddress: $shippingAddress
    )
  }
`;
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<
  CreateCheckoutSessionMutation,
  CreateCheckoutSessionMutationVariables
>;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      currency: // value for 'currency'
 *      quantity: // value for 'quantity'
 *      gift: // value for 'gift'
 *      discountCode: // value for 'discountCode'
 *      couponCode: // value for 'couponCode'
 *      addons: // value for 'addons'
 *      shippingAddress: // value for 'shippingAddress'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCheckoutSessionMutation,
    CreateCheckoutSessionMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateCheckoutSessionMutation,
    CreateCheckoutSessionMutationVariables
  >(CreateCheckoutSessionDocument, baseOptions);
}
export type CreateCheckoutSessionMutationHookResult = ReturnType<
  typeof useCreateCheckoutSessionMutation
>;
export type CreateCheckoutSessionMutationResult =
  Apollo.MutationResult<CreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateCheckoutSessionMutation,
  CreateCheckoutSessionMutationVariables
>;
export const AddUserSignatureDocument = gql`
  mutation addUserSignature($img: String!) {
    addUserSignature(img: $img) {
      id
      signature
    }
  }
`;
export type AddUserSignatureMutationFn = Apollo.MutationFunction<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;

/**
 * __useAddUserSignatureMutation__
 *
 * To run a mutation, you first call `useAddUserSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSignatureMutation, { data, loading, error }] = useAddUserSignatureMutation({
 *   variables: {
 *      img: // value for 'img'
 *   },
 * });
 */
export function useAddUserSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserSignatureMutation,
    AddUserSignatureMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddUserSignatureMutation,
    AddUserSignatureMutationVariables
  >(AddUserSignatureDocument, baseOptions);
}
export type AddUserSignatureMutationHookResult = ReturnType<
  typeof useAddUserSignatureMutation
>;
export type AddUserSignatureMutationResult =
  Apollo.MutationResult<AddUserSignatureMutation>;
export type AddUserSignatureMutationOptions = Apollo.BaseMutationOptions<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;
export const AddToMailingListDocument = gql`
  mutation addToMailingList(
    $email: String!
    $countryCode: String!
    $listType: ListType!
  ) {
    addUserToMailingList(
      email: $email
      countryCode: $countryCode
      listType: $listType
    )
  }
`;
export type AddToMailingListMutationFn = Apollo.MutationFunction<
  AddToMailingListMutation,
  AddToMailingListMutationVariables
>;

/**
 * __useAddToMailingListMutation__
 *
 * To run a mutation, you first call `useAddToMailingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToMailingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToMailingListMutation, { data, loading, error }] = useAddToMailingListMutation({
 *   variables: {
 *      email: // value for 'email'
 *      countryCode: // value for 'countryCode'
 *      listType: // value for 'listType'
 *   },
 * });
 */
export function useAddToMailingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddToMailingListMutation,
    AddToMailingListMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddToMailingListMutation,
    AddToMailingListMutationVariables
  >(AddToMailingListDocument, baseOptions);
}
export type AddToMailingListMutationHookResult = ReturnType<
  typeof useAddToMailingListMutation
>;
export type AddToMailingListMutationResult =
  Apollo.MutationResult<AddToMailingListMutation>;
export type AddToMailingListMutationOptions = Apollo.BaseMutationOptions<
  AddToMailingListMutation,
  AddToMailingListMutationVariables
>;
export const AddVoteDocument = gql`
  mutation addVote($surveyId: String!, $data: [AddVotePostAttributes]!) {
    addUserVote(surveyId: $surveyId, data: $data)
  }
`;
export type AddVoteMutationFn = Apollo.MutationFunction<
  AddVoteMutation,
  AddVoteMutationVariables
>;

/**
 * __useAddVoteMutation__
 *
 * To run a mutation, you first call `useAddVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVoteMutation, { data, loading, error }] = useAddVoteMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVoteMutation,
    AddVoteMutationVariables
  >,
) {
  return Apollo.useMutation<AddVoteMutation, AddVoteMutationVariables>(
    AddVoteDocument,
    baseOptions,
  );
}
export type AddVoteMutationHookResult = ReturnType<typeof useAddVoteMutation>;
export type AddVoteMutationResult = Apollo.MutationResult<AddVoteMutation>;
export type AddVoteMutationOptions = Apollo.BaseMutationOptions<
  AddVoteMutation,
  AddVoteMutationVariables
>;
export const CheckVoteDocument = gql`
  mutation checkVote($surveyId: String!) {
    userVotedForSurvey(surveyId: $surveyId)
  }
`;
export type CheckVoteMutationFn = Apollo.MutationFunction<
  CheckVoteMutation,
  CheckVoteMutationVariables
>;

/**
 * __useCheckVoteMutation__
 *
 * To run a mutation, you first call `useCheckVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkVoteMutation, { data, loading, error }] = useCheckVoteMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useCheckVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckVoteMutation,
    CheckVoteMutationVariables
  >,
) {
  return Apollo.useMutation<CheckVoteMutation, CheckVoteMutationVariables>(
    CheckVoteDocument,
    baseOptions,
  );
}
export type CheckVoteMutationHookResult = ReturnType<
  typeof useCheckVoteMutation
>;
export type CheckVoteMutationResult = Apollo.MutationResult<CheckVoteMutation>;
export type CheckVoteMutationOptions = Apollo.BaseMutationOptions<
  CheckVoteMutation,
  CheckVoteMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser(
    $title: String
    $firstName: String
    $lastName: String
    $dateOfBirth: Date
    $collectorType: String
    $categoriesInterestedIn: [String!]
    $shippingAddress: AddressInput
  ) {
    updateUser(
      title: $title
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      collectorType: $collectorType
      categoriesInterestedIn: $categoriesInterestedIn
      shippingAddress: $shippingAddress
    ) {
      id
      firstName
      lastName
      dateOfBirth
      title
      collectorType
      categoriesInterestedIn
      shippingAddress {
        lineOne
        lineTwo
        city
        postcode
        countryCode
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      title: // value for 'title'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      collectorType: // value for 'collectorType'
 *      categoriesInterestedIn: // value for 'categoriesInterestedIn'
 *      shippingAddress: // value for 'shippingAddress'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CheckRedeemCodeDocument = gql`
  mutation checkRedeemCode($code: String!) {
    checkRedeemCode(code: $code) {
      id
      assetId
      intentId
    }
  }
`;
export type CheckRedeemCodeMutationFn = Apollo.MutationFunction<
  CheckRedeemCodeMutation,
  CheckRedeemCodeMutationVariables
>;

/**
 * __useCheckRedeemCodeMutation__
 *
 * To run a mutation, you first call `useCheckRedeemCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckRedeemCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkRedeemCodeMutation, { data, loading, error }] = useCheckRedeemCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCheckRedeemCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckRedeemCodeMutation,
    CheckRedeemCodeMutationVariables
  >,
) {
  return Apollo.useMutation<
    CheckRedeemCodeMutation,
    CheckRedeemCodeMutationVariables
  >(CheckRedeemCodeDocument, baseOptions);
}
export type CheckRedeemCodeMutationHookResult = ReturnType<
  typeof useCheckRedeemCodeMutation
>;
export type CheckRedeemCodeMutationResult =
  Apollo.MutationResult<CheckRedeemCodeMutation>;
export type CheckRedeemCodeMutationOptions = Apollo.BaseMutationOptions<
  CheckRedeemCodeMutation,
  CheckRedeemCodeMutationVariables
>;
export const RedeemTransferDocument = gql`
  mutation redeemTransfer(
    $transactionId: String!
    $transactionIntentId: String!
  ) {
    redeemTransfer(
      transactionId: $transactionId
      transactionIntentId: $transactionIntentId
    ) {
      id
      quantity
      assetName
    }
  }
`;
export type RedeemTransferMutationFn = Apollo.MutationFunction<
  RedeemTransferMutation,
  RedeemTransferMutationVariables
>;

/**
 * __useRedeemTransferMutation__
 *
 * To run a mutation, you first call `useRedeemTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemTransferMutation, { data, loading, error }] = useRedeemTransferMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      transactionIntentId: // value for 'transactionIntentId'
 *   },
 * });
 */
export function useRedeemTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedeemTransferMutation,
    RedeemTransferMutationVariables
  >,
) {
  return Apollo.useMutation<
    RedeemTransferMutation,
    RedeemTransferMutationVariables
  >(RedeemTransferDocument, baseOptions);
}
export type RedeemTransferMutationHookResult = ReturnType<
  typeof useRedeemTransferMutation
>;
export type RedeemTransferMutationResult =
  Apollo.MutationResult<RedeemTransferMutation>;
export type RedeemTransferMutationOptions = Apollo.BaseMutationOptions<
  RedeemTransferMutation,
  RedeemTransferMutationVariables
>;
export const CreateTransferDocument = gql`
  mutation createTransfer(
    $assetOfferingId: String!
    $quantity: Int!
    $currency: String!
    $recipientName: String
  ) {
    createTransfer(
      assetOfferingId: $assetOfferingId
      quantity: $quantity
      currency: $currency
      recipientName: $recipientName
    ) {
      id
      redeemCode
    }
  }
`;
export type CreateTransferMutationFn = Apollo.MutationFunction<
  CreateTransferMutation,
  CreateTransferMutationVariables
>;

/**
 * __useCreateTransferMutation__
 *
 * To run a mutation, you first call `useCreateTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransferMutation, { data, loading, error }] = useCreateTransferMutation({
 *   variables: {
 *      assetOfferingId: // value for 'assetOfferingId'
 *      quantity: // value for 'quantity'
 *      currency: // value for 'currency'
 *      recipientName: // value for 'recipientName'
 *   },
 * });
 */
export function useCreateTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTransferMutation,
    CreateTransferMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateTransferMutation,
    CreateTransferMutationVariables
  >(CreateTransferDocument, baseOptions);
}
export type CreateTransferMutationHookResult = ReturnType<
  typeof useCreateTransferMutation
>;
export type CreateTransferMutationResult =
  Apollo.MutationResult<CreateTransferMutation>;
export type CreateTransferMutationOptions = Apollo.BaseMutationOptions<
  CreateTransferMutation,
  CreateTransferMutationVariables
>;
export const CancelTransferDocument = gql`
  mutation cancelTransfer($transactionId: String!) {
    cancelTransfer(transactionId: $transactionId)
  }
`;
export type CancelTransferMutationFn = Apollo.MutationFunction<
  CancelTransferMutation,
  CancelTransferMutationVariables
>;

/**
 * __useCancelTransferMutation__
 *
 * To run a mutation, you first call `useCancelTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTransferMutation, { data, loading, error }] = useCancelTransferMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useCancelTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTransferMutation,
    CancelTransferMutationVariables
  >,
) {
  return Apollo.useMutation<
    CancelTransferMutation,
    CancelTransferMutationVariables
  >(CancelTransferDocument, baseOptions);
}
export type CancelTransferMutationHookResult = ReturnType<
  typeof useCancelTransferMutation
>;
export type CancelTransferMutationResult =
  Apollo.MutationResult<CancelTransferMutation>;
export type CancelTransferMutationOptions = Apollo.BaseMutationOptions<
  CancelTransferMutation,
  CancelTransferMutationVariables
>;
export const UpdateUserDisplayedSideAssetDocument = gql`
  mutation updateUserDisplayedSideAsset($assetId: String!, $side: Int!) {
    updateUserDisplayedSideAsset(assetId: $assetId, side: $side)
  }
`;
export type UpdateUserDisplayedSideAssetMutationFn = Apollo.MutationFunction<
  UpdateUserDisplayedSideAssetMutation,
  UpdateUserDisplayedSideAssetMutationVariables
>;

/**
 * __useUpdateUserDisplayedSideAssetMutation__
 *
 * To run a mutation, you first call `useUpdateUserDisplayedSideAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDisplayedSideAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDisplayedSideAssetMutation, { data, loading, error }] = useUpdateUserDisplayedSideAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      side: // value for 'side'
 *   },
 * });
 */
export function useUpdateUserDisplayedSideAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDisplayedSideAssetMutation,
    UpdateUserDisplayedSideAssetMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateUserDisplayedSideAssetMutation,
    UpdateUserDisplayedSideAssetMutationVariables
  >(UpdateUserDisplayedSideAssetDocument, baseOptions);
}
export type UpdateUserDisplayedSideAssetMutationHookResult = ReturnType<
  typeof useUpdateUserDisplayedSideAssetMutation
>;
export type UpdateUserDisplayedSideAssetMutationResult =
  Apollo.MutationResult<UpdateUserDisplayedSideAssetMutation>;
export type UpdateUserDisplayedSideAssetMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserDisplayedSideAssetMutation,
    UpdateUserDisplayedSideAssetMutationVariables
  >;
export const CheckUserSocialLoginAccountDocument = gql`
  mutation checkUserSocialLoginAccount($username: String!) {
    checkUserSocialLoginAccount(username: $username)
  }
`;
export type CheckUserSocialLoginAccountMutationFn = Apollo.MutationFunction<
  CheckUserSocialLoginAccountMutation,
  CheckUserSocialLoginAccountMutationVariables
>;

/**
 * __useCheckUserSocialLoginAccountMutation__
 *
 * To run a mutation, you first call `useCheckUserSocialLoginAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUserSocialLoginAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUserSocialLoginAccountMutation, { data, loading, error }] = useCheckUserSocialLoginAccountMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCheckUserSocialLoginAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckUserSocialLoginAccountMutation,
    CheckUserSocialLoginAccountMutationVariables
  >,
) {
  return Apollo.useMutation<
    CheckUserSocialLoginAccountMutation,
    CheckUserSocialLoginAccountMutationVariables
  >(CheckUserSocialLoginAccountDocument, baseOptions);
}
export type CheckUserSocialLoginAccountMutationHookResult = ReturnType<
  typeof useCheckUserSocialLoginAccountMutation
>;
export type CheckUserSocialLoginAccountMutationResult =
  Apollo.MutationResult<CheckUserSocialLoginAccountMutation>;
export type CheckUserSocialLoginAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CheckUserSocialLoginAccountMutation,
    CheckUserSocialLoginAccountMutationVariables
  >;
export const SendReferrerCodeDocument = gql`
  mutation sendReferrerCode($code: String, $email: String) {
    sendReferrerCode(code: $code, email: $email) {
      message
    }
  }
`;
export type SendReferrerCodeMutationFn = Apollo.MutationFunction<
  SendReferrerCodeMutation,
  SendReferrerCodeMutationVariables
>;

/**
 * __useSendReferrerCodeMutation__
 *
 * To run a mutation, you first call `useSendReferrerCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReferrerCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReferrerCodeMutation, { data, loading, error }] = useSendReferrerCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendReferrerCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendReferrerCodeMutation,
    SendReferrerCodeMutationVariables
  >,
) {
  return Apollo.useMutation<
    SendReferrerCodeMutation,
    SendReferrerCodeMutationVariables
  >(SendReferrerCodeDocument, baseOptions);
}
export type SendReferrerCodeMutationHookResult = ReturnType<
  typeof useSendReferrerCodeMutation
>;
export type SendReferrerCodeMutationResult =
  Apollo.MutationResult<SendReferrerCodeMutation>;
export type SendReferrerCodeMutationOptions = Apollo.BaseMutationOptions<
  SendReferrerCodeMutation,
  SendReferrerCodeMutationVariables
>;
export const AssignReferralDocument = gql`
  mutation assignReferral($code: String) {
    assignReferral(code: $code) {
      message
    }
  }
`;
export type AssignReferralMutationFn = Apollo.MutationFunction<
  AssignReferralMutation,
  AssignReferralMutationVariables
>;

/**
 * __useAssignReferralMutation__
 *
 * To run a mutation, you first call `useAssignReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignReferralMutation, { data, loading, error }] = useAssignReferralMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAssignReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignReferralMutation,
    AssignReferralMutationVariables
  >,
) {
  return Apollo.useMutation<
    AssignReferralMutation,
    AssignReferralMutationVariables
  >(AssignReferralDocument, baseOptions);
}
export type AssignReferralMutationHookResult = ReturnType<
  typeof useAssignReferralMutation
>;
export type AssignReferralMutationResult =
  Apollo.MutationResult<AssignReferralMutation>;
export type AssignReferralMutationOptions = Apollo.BaseMutationOptions<
  AssignReferralMutation,
  AssignReferralMutationVariables
>;
export const VerifyPromoCodeDocument = gql`
  mutation verifyPromoCode($code: String) {
    verifyPromoCode(code: $code) {
      id
      active
      code
      currency
      amount
    }
  }
`;
export type VerifyPromoCodeMutationFn = Apollo.MutationFunction<
  VerifyPromoCodeMutation,
  VerifyPromoCodeMutationVariables
>;

/**
 * __useVerifyPromoCodeMutation__
 *
 * To run a mutation, you first call `useVerifyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPromoCodeMutation, { data, loading, error }] = useVerifyPromoCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPromoCodeMutation,
    VerifyPromoCodeMutationVariables
  >,
) {
  return Apollo.useMutation<
    VerifyPromoCodeMutation,
    VerifyPromoCodeMutationVariables
  >(VerifyPromoCodeDocument, baseOptions);
}
export type VerifyPromoCodeMutationHookResult = ReturnType<
  typeof useVerifyPromoCodeMutation
>;
export type VerifyPromoCodeMutationResult =
  Apollo.MutationResult<VerifyPromoCodeMutation>;
export type VerifyPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  VerifyPromoCodeMutation,
  VerifyPromoCodeMutationVariables
>;
export const AddListingDocument = gql`
  mutation addListing(
    $assetId: String!
    $price: Int!
    $quantity: Int!
    $pieces: [String!]
    $countryCode: String
    $city: String
    $postcode: String
    $lineOne: String
    $lineTwo: String
    $accountNumber: String
    $routingNumber: String
    $IBAN: String
    $Swift: String
    $fundsTimeline: Boolean
    $sellingDirectToBuyer: Boolean
    $noRefunds: Boolean
    $currencyConversionAndStripe: Boolean
    $crossBorderCharge: Boolean
    $termsAndConditions: Boolean
  ) {
    addListing(
      assetId: $assetId
      price: $price
      quantity: $quantity
      pieces: $pieces
      bankInfo: {
        accountNumber: $accountNumber
        routingNumber: $routingNumber
        IBAN: $IBAN
        Swift: $Swift
      }
      addressInfo: {
        countryCode: $countryCode
        city: $city
        postcode: $postcode
        lineOne: $lineOne
        lineTwo: $lineTwo
      }
      termsAndConditionsInfo: {
        fundsTimeline: $fundsTimeline
        sellingDirectToBuyer: $sellingDirectToBuyer
        noRefunds: $noRefunds
        currencyConversionAndStripe: $currencyConversionAndStripe
        crossBorderCharge: $crossBorderCharge
        termsAndConditions: $termsAndConditions
      }
    )
  }
`;
export type AddListingMutationFn = Apollo.MutationFunction<
  AddListingMutation,
  AddListingMutationVariables
>;

/**
 * __useAddListingMutation__
 *
 * To run a mutation, you first call `useAddListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addListingMutation, { data, loading, error }] = useAddListingMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      price: // value for 'price'
 *      quantity: // value for 'quantity'
 *      pieces: // value for 'pieces'
 *      countryCode: // value for 'countryCode'
 *      city: // value for 'city'
 *      postcode: // value for 'postcode'
 *      lineOne: // value for 'lineOne'
 *      lineTwo: // value for 'lineTwo'
 *      accountNumber: // value for 'accountNumber'
 *      routingNumber: // value for 'routingNumber'
 *      IBAN: // value for 'IBAN'
 *      Swift: // value for 'Swift'
 *      fundsTimeline: // value for 'fundsTimeline'
 *      sellingDirectToBuyer: // value for 'sellingDirectToBuyer'
 *      noRefunds: // value for 'noRefunds'
 *      currencyConversionAndStripe: // value for 'currencyConversionAndStripe'
 *      crossBorderCharge: // value for 'crossBorderCharge'
 *      termsAndConditions: // value for 'termsAndConditions'
 *   },
 * });
 */
export function useAddListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddListingMutation,
    AddListingMutationVariables
  >,
) {
  return Apollo.useMutation<AddListingMutation, AddListingMutationVariables>(
    AddListingDocument,
    baseOptions,
  );
}
export type AddListingMutationHookResult = ReturnType<
  typeof useAddListingMutation
>;
export type AddListingMutationResult =
  Apollo.MutationResult<AddListingMutation>;
export type AddListingMutationOptions = Apollo.BaseMutationOptions<
  AddListingMutation,
  AddListingMutationVariables
>;
export const DeleteListingDocument = gql`
  mutation deleteListing($listingId: String!) {
    deleteListing(listingId: $listingId)
  }
`;
export type DeleteListingMutationFn = Apollo.MutationFunction<
  DeleteListingMutation,
  DeleteListingMutationVariables
>;

/**
 * __useDeleteListingMutation__
 *
 * To run a mutation, you first call `useDeleteListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteListingMutation, { data, loading, error }] = useDeleteListingMutation({
 *   variables: {
 *      listingId: // value for 'listingId'
 *   },
 * });
 */
export function useDeleteListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteListingMutation,
    DeleteListingMutationVariables
  >,
) {
  return Apollo.useMutation<
    DeleteListingMutation,
    DeleteListingMutationVariables
  >(DeleteListingDocument, baseOptions);
}
export type DeleteListingMutationHookResult = ReturnType<
  typeof useDeleteListingMutation
>;
export type DeleteListingMutationResult =
  Apollo.MutationResult<DeleteListingMutation>;
export type DeleteListingMutationOptions = Apollo.BaseMutationOptions<
  DeleteListingMutation,
  DeleteListingMutationVariables
>;
export const AddOfferDocument = gql`
  mutation addOffer($assetId: String!, $quantity: Int!, $listingId: String!) {
    addOffer(assetId: $assetId, quantity: $quantity, listingId: $listingId)
  }
`;
export type AddOfferMutationFn = Apollo.MutationFunction<
  AddOfferMutation,
  AddOfferMutationVariables
>;

/**
 * __useAddOfferMutation__
 *
 * To run a mutation, you first call `useAddOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfferMutation, { data, loading, error }] = useAddOfferMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      quantity: // value for 'quantity'
 *      listingId: // value for 'listingId'
 *   },
 * });
 */
export function useAddOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOfferMutation,
    AddOfferMutationVariables
  >,
) {
  return Apollo.useMutation<AddOfferMutation, AddOfferMutationVariables>(
    AddOfferDocument,
    baseOptions,
  );
}
export type AddOfferMutationHookResult = ReturnType<typeof useAddOfferMutation>;
export type AddOfferMutationResult = Apollo.MutationResult<AddOfferMutation>;
export type AddOfferMutationOptions = Apollo.BaseMutationOptions<
  AddOfferMutation,
  AddOfferMutationVariables
>;
export const PayOfferDocument = gql`
  mutation payOffer(
    $assetId: String!
    $cardToken: String!
    $countryCode: String!
    $city: String!
    $postcode: String
    $lineOne: String
    $lineTwo: String
    $directFromSeller: Boolean
    $noRefunds: Boolean
    $currencyConversionAndStripe: Boolean
    $termsAndConditions: Boolean
  ) {
    payOffer(
      assetId: $assetId
      cardToken: $cardToken
      addressInfo: {
        countryCode: $countryCode
        city: $city
        postcode: $postcode
        lineOne: $lineOne
        lineTwo: $lineTwo
      }
      termsAndConditionsInfo: {
        directFromSeller: $directFromSeller
        noRefunds: $noRefunds
        currencyConversionAndStripe: $currencyConversionAndStripe
        termsAndConditions: $termsAndConditions
      }
    ) {
      status
      url
      paymentId
    }
  }
`;
export type PayOfferMutationFn = Apollo.MutationFunction<
  PayOfferMutation,
  PayOfferMutationVariables
>;

/**
 * __usePayOfferMutation__
 *
 * To run a mutation, you first call `usePayOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payOfferMutation, { data, loading, error }] = usePayOfferMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      cardToken: // value for 'cardToken'
 *      countryCode: // value for 'countryCode'
 *      city: // value for 'city'
 *      postcode: // value for 'postcode'
 *      lineOne: // value for 'lineOne'
 *      lineTwo: // value for 'lineTwo'
 *      directFromSeller: // value for 'directFromSeller'
 *      noRefunds: // value for 'noRefunds'
 *      currencyConversionAndStripe: // value for 'currencyConversionAndStripe'
 *      termsAndConditions: // value for 'termsAndConditions'
 *   },
 * });
 */
export function usePayOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayOfferMutation,
    PayOfferMutationVariables
  >,
) {
  return Apollo.useMutation<PayOfferMutation, PayOfferMutationVariables>(
    PayOfferDocument,
    baseOptions,
  );
}
export type PayOfferMutationHookResult = ReturnType<typeof usePayOfferMutation>;
export type PayOfferMutationResult = Apollo.MutationResult<PayOfferMutation>;
export type PayOfferMutationOptions = Apollo.BaseMutationOptions<
  PayOfferMutation,
  PayOfferMutationVariables
>;
export const GetPaymentStatusDocument = gql`
  mutation getPaymentStatus($paymentId: String!) {
    getPaymentStatus(paymentId: $paymentId) {
      status
      url
    }
  }
`;
export type GetPaymentStatusMutationFn = Apollo.MutationFunction<
  GetPaymentStatusMutation,
  GetPaymentStatusMutationVariables
>;

/**
 * __useGetPaymentStatusMutation__
 *
 * To run a mutation, you first call `useGetPaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPaymentStatusMutation, { data, loading, error }] = useGetPaymentStatusMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetPaymentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPaymentStatusMutation,
    GetPaymentStatusMutationVariables
  >,
) {
  return Apollo.useMutation<
    GetPaymentStatusMutation,
    GetPaymentStatusMutationVariables
  >(GetPaymentStatusDocument, baseOptions);
}
export type GetPaymentStatusMutationHookResult = ReturnType<
  typeof useGetPaymentStatusMutation
>;
export type GetPaymentStatusMutationResult =
  Apollo.MutationResult<GetPaymentStatusMutation>;
export type GetPaymentStatusMutationOptions = Apollo.BaseMutationOptions<
  GetPaymentStatusMutation,
  GetPaymentStatusMutationVariables
>;
export const SetPriceAlertDocument = gql`
  mutation setPriceAlert(
    $price: Int!
    $assetId: String!
    $currency: String!
    $email: String!
  ) {
    setPriceAlert(
      price: $price
      assetId: $assetId
      currency: $currency
      email: $email
    )
  }
`;
export type SetPriceAlertMutationFn = Apollo.MutationFunction<
  SetPriceAlertMutation,
  SetPriceAlertMutationVariables
>;

/**
 * __useSetPriceAlertMutation__
 *
 * To run a mutation, you first call `useSetPriceAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPriceAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPriceAlertMutation, { data, loading, error }] = useSetPriceAlertMutation({
 *   variables: {
 *      price: // value for 'price'
 *      assetId: // value for 'assetId'
 *      currency: // value for 'currency'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSetPriceAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPriceAlertMutation,
    SetPriceAlertMutationVariables
  >,
) {
  return Apollo.useMutation<
    SetPriceAlertMutation,
    SetPriceAlertMutationVariables
  >(SetPriceAlertDocument, baseOptions);
}
export type SetPriceAlertMutationHookResult = ReturnType<
  typeof useSetPriceAlertMutation
>;
export type SetPriceAlertMutationResult =
  Apollo.MutationResult<SetPriceAlertMutation>;
export type SetPriceAlertMutationOptions = Apollo.BaseMutationOptions<
  SetPriceAlertMutation,
  SetPriceAlertMutationVariables
>;
export const UserDocument = gql`
  query user {
    user {
      id
      title
      firstName
      lastName
      email
      referrerCode
      phoneNumber
      dateOfBirth
      stripeAccountId
      stripeAccountVerificationStatus
      collectorType
      categoriesInterestedIn
      shippingAddress {
        countryCode
        city
        postcode
        lineOne
        lineTwo
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(
  baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  return Apollo.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions,
  );
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserWithSignatureDocument = gql`
  query userWithSignature {
    user {
      id
      signature
    }
  }
`;

/**
 * __useUserWithSignatureQuery__
 *
 * To run a query within a React component, call `useUserWithSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithSignatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithSignatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserWithSignatureQuery,
    UserWithSignatureQueryVariables
  >,
) {
  return Apollo.useQuery<
    UserWithSignatureQuery,
    UserWithSignatureQueryVariables
  >(UserWithSignatureDocument, baseOptions);
}
export function useUserWithSignatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserWithSignatureQuery,
    UserWithSignatureQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    UserWithSignatureQuery,
    UserWithSignatureQueryVariables
  >(UserWithSignatureDocument, baseOptions);
}
export type UserWithSignatureQueryHookResult = ReturnType<
  typeof useUserWithSignatureQuery
>;
export type UserWithSignatureLazyQueryHookResult = ReturnType<
  typeof useUserWithSignatureLazyQuery
>;
export type UserWithSignatureQueryResult = Apollo.QueryResult<
  UserWithSignatureQuery,
  UserWithSignatureQueryVariables
>;
export const UserTransactionsDocument = gql`
  query userTransactions {
    user {
      id
      transactions {
        id
        type
        quantity
        status
        price {
          value
          symbol
          code
          postFix
          formatPrice
        }
        transactionDate
        asset {
          id
          title
        }
        transactionDirection
        redeemCode
      }
    }
  }
`;

/**
 * __useUserTransactionsQuery__
 *
 * To run a query within a React component, call `useUserTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserTransactionsQuery,
    UserTransactionsQueryVariables
  >,
) {
  return Apollo.useQuery<UserTransactionsQuery, UserTransactionsQueryVariables>(
    UserTransactionsDocument,
    baseOptions,
  );
}
export function useUserTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserTransactionsQuery,
    UserTransactionsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    UserTransactionsQuery,
    UserTransactionsQueryVariables
  >(UserTransactionsDocument, baseOptions);
}
export type UserTransactionsQueryHookResult = ReturnType<
  typeof useUserTransactionsQuery
>;
export type UserTransactionsLazyQueryHookResult = ReturnType<
  typeof useUserTransactionsLazyQuery
>;
export type UserTransactionsQueryResult = Apollo.QueryResult<
  UserTransactionsQuery,
  UserTransactionsQueryVariables
>;
export const AssetDocument = gql`
  query asset($id: String!) {
    asset(id: $id) {
      id
      title
      quantityAvailable
      status
      transactionOverview {
        ...TransactionOverview
      }
      lastAssetTransactionOverview {
        id
        price {
          value
        }
      }
      allAssetOfferings {
        id
        start
        end
        transactionOverview {
          ...TransactionOverview
        }
        pricing {
          pricePerPiece
          currency
          formatPrice
          symbol
          postFix
        }
      }
      currentAssetOffering {
        id
        start
        end
        transactionOverview {
          ...TransactionOverview
        }
        pricing {
          pricePerPiece
          currency
          formatPrice
          symbol
          postFix
        }
      }
      listingOverview {
        lowestPrice
        totalActiveNFPs
      }
    }
  }
  ${TransactionOverviewFragmentDoc}
`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetQuery(
  baseOptions: Apollo.QueryHookOptions<AssetQuery, AssetQueryVariables>,
) {
  return Apollo.useQuery<AssetQuery, AssetQueryVariables>(
    AssetDocument,
    baseOptions,
  );
}
export function useAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssetQuery, AssetQueryVariables>,
) {
  return Apollo.useLazyQuery<AssetQuery, AssetQueryVariables>(
    AssetDocument,
    baseOptions,
  );
}
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export type AssetQueryResult = Apollo.QueryResult<
  AssetQuery,
  AssetQueryVariables
>;
export const AssetAddonsDocument = gql`
  query assetAddons($id: String!) {
    asset(id: $id) {
      addonsOverview {
        id
        title
        image
        maxPieces
        requiresAddress
        price {
          value
          vat
          totalVat
          priceIncludeVAT
          currency
          formatPrice
          symbol
          postFix
        }
      }
    }
  }
`;

/**
 * __useAssetAddonsQuery__
 *
 * To run a query within a React component, call `useAssetAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetAddonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetAddonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssetAddonsQuery,
    AssetAddonsQueryVariables
  >,
) {
  return Apollo.useQuery<AssetAddonsQuery, AssetAddonsQueryVariables>(
    AssetAddonsDocument,
    baseOptions,
  );
}
export function useAssetAddonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetAddonsQuery,
    AssetAddonsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AssetAddonsQuery, AssetAddonsQueryVariables>(
    AssetAddonsDocument,
    baseOptions,
  );
}
export type AssetAddonsQueryHookResult = ReturnType<typeof useAssetAddonsQuery>;
export type AssetAddonsLazyQueryHookResult = ReturnType<
  typeof useAssetAddonsLazyQuery
>;
export type AssetAddonsQueryResult = Apollo.QueryResult<
  AssetAddonsQuery,
  AssetAddonsQueryVariables
>;
export const MyCollectionDocument = gql`
  query myCollection {
    user {
      id
      collectionOverview {
        id
        userFirstName
        assetOverviews {
          displayedSide
          totalPiecesOwned
          totalPiecesAvailable
          assetId
          status
          recentPurchasePieces
          recentPurchaseDate
          recentPurchaseValue
          localValue {
            total
            currency
            symbol
            postFix
          }
          sumOwnedAssets
        }
        hasKYCPending
        banksyVDMTokenId
      }
    }
  }
`;

/**
 * __useMyCollectionQuery__
 *
 * To run a query within a React component, call `useMyCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCollectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCollectionQuery,
    MyCollectionQueryVariables
  >,
) {
  return Apollo.useQuery<MyCollectionQuery, MyCollectionQueryVariables>(
    MyCollectionDocument,
    baseOptions,
  );
}
export function useMyCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCollectionQuery,
    MyCollectionQueryVariables
  >,
) {
  return Apollo.useLazyQuery<MyCollectionQuery, MyCollectionQueryVariables>(
    MyCollectionDocument,
    baseOptions,
  );
}
export type MyCollectionQueryHookResult = ReturnType<
  typeof useMyCollectionQuery
>;
export type MyCollectionLazyQueryHookResult = ReturnType<
  typeof useMyCollectionLazyQuery
>;
export type MyCollectionQueryResult = Apollo.QueryResult<
  MyCollectionQuery,
  MyCollectionQueryVariables
>;
export const UserCollectionOverviewDocument = gql`
  query userCollectionOverview($userId: String!) {
    userCollectionOverview(userId: $userId) {
      id
      userFirstName
      signature
      assetOverviews {
        displayedSide
        recentPurchaseDate
        totalPiecesAvailable
        totalPiecesOwned
        assetId
      }
    }
  }
`;

/**
 * __useUserCollectionOverviewQuery__
 *
 * To run a query within a React component, call `useUserCollectionOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCollectionOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCollectionOverviewQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserCollectionOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserCollectionOverviewQuery,
    UserCollectionOverviewQueryVariables
  >,
) {
  return Apollo.useQuery<
    UserCollectionOverviewQuery,
    UserCollectionOverviewQueryVariables
  >(UserCollectionOverviewDocument, baseOptions);
}
export function useUserCollectionOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCollectionOverviewQuery,
    UserCollectionOverviewQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    UserCollectionOverviewQuery,
    UserCollectionOverviewQueryVariables
  >(UserCollectionOverviewDocument, baseOptions);
}
export type UserCollectionOverviewQueryHookResult = ReturnType<
  typeof useUserCollectionOverviewQuery
>;
export type UserCollectionOverviewLazyQueryHookResult = ReturnType<
  typeof useUserCollectionOverviewLazyQuery
>;
export type UserCollectionOverviewQueryResult = Apollo.QueryResult<
  UserCollectionOverviewQuery,
  UserCollectionOverviewQueryVariables
>;
export const UserByReferrerCodeDocument = gql`
  query userByReferrerCode($referrerId: String!) {
    userByReferrerCode(id: $referrerId) {
      firstName
      lastName
      email
    }
  }
`;

/**
 * __useUserByReferrerCodeQuery__
 *
 * To run a query within a React component, call `useUserByReferrerCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByReferrerCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByReferrerCodeQuery({
 *   variables: {
 *      referrerId: // value for 'referrerId'
 *   },
 * });
 */
export function useUserByReferrerCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserByReferrerCodeQuery,
    UserByReferrerCodeQueryVariables
  >,
) {
  return Apollo.useQuery<
    UserByReferrerCodeQuery,
    UserByReferrerCodeQueryVariables
  >(UserByReferrerCodeDocument, baseOptions);
}
export function useUserByReferrerCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserByReferrerCodeQuery,
    UserByReferrerCodeQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    UserByReferrerCodeQuery,
    UserByReferrerCodeQueryVariables
  >(UserByReferrerCodeDocument, baseOptions);
}
export type UserByReferrerCodeQueryHookResult = ReturnType<
  typeof useUserByReferrerCodeQuery
>;
export type UserByReferrerCodeLazyQueryHookResult = ReturnType<
  typeof useUserByReferrerCodeLazyQuery
>;
export type UserByReferrerCodeQueryResult = Apollo.QueryResult<
  UserByReferrerCodeQuery,
  UserByReferrerCodeQueryVariables
>;
export const CouponsByUserIdDocument = gql`
  query couponsByUserId($userId: String!) {
    couponsByUserId(id: $userId) {
      active
      code
      referrer
    }
  }
`;

/**
 * __useCouponsByUserIdQuery__
 *
 * To run a query within a React component, call `useCouponsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCouponsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CouponsByUserIdQuery,
    CouponsByUserIdQueryVariables
  >,
) {
  return Apollo.useQuery<CouponsByUserIdQuery, CouponsByUserIdQueryVariables>(
    CouponsByUserIdDocument,
    baseOptions,
  );
}
export function useCouponsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CouponsByUserIdQuery,
    CouponsByUserIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    CouponsByUserIdQuery,
    CouponsByUserIdQueryVariables
  >(CouponsByUserIdDocument, baseOptions);
}
export type CouponsByUserIdQueryHookResult = ReturnType<
  typeof useCouponsByUserIdQuery
>;
export type CouponsByUserIdLazyQueryHookResult = ReturnType<
  typeof useCouponsByUserIdLazyQuery
>;
export type CouponsByUserIdQueryResult = Apollo.QueryResult<
  CouponsByUserIdQuery,
  CouponsByUserIdQueryVariables
>;
export const GenerateConnectAccountLinkDocument = gql`
  query generateConnectAccountLink {
    generateConnectAccountLink
  }
`;

/**
 * __useGenerateConnectAccountLinkQuery__
 *
 * To run a query within a React component, call `useGenerateConnectAccountLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateConnectAccountLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateConnectAccountLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateConnectAccountLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenerateConnectAccountLinkQuery,
    GenerateConnectAccountLinkQueryVariables
  >,
) {
  return Apollo.useQuery<
    GenerateConnectAccountLinkQuery,
    GenerateConnectAccountLinkQueryVariables
  >(GenerateConnectAccountLinkDocument, baseOptions);
}
export function useGenerateConnectAccountLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateConnectAccountLinkQuery,
    GenerateConnectAccountLinkQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GenerateConnectAccountLinkQuery,
    GenerateConnectAccountLinkQueryVariables
  >(GenerateConnectAccountLinkDocument, baseOptions);
}
export type GenerateConnectAccountLinkQueryHookResult = ReturnType<
  typeof useGenerateConnectAccountLinkQuery
>;
export type GenerateConnectAccountLinkLazyQueryHookResult = ReturnType<
  typeof useGenerateConnectAccountLinkLazyQuery
>;
export type GenerateConnectAccountLinkQueryResult = Apollo.QueryResult<
  GenerateConnectAccountLinkQuery,
  GenerateConnectAccountLinkQueryVariables
>;
export const GetActiveListingsDocument = gql`
  query getActiveListings($assetId: String!) {
    getActiveListings(assetId: $assetId) {
      id
      assetId
      userId
      price {
        value
        currency
        symbol
        postFix
        formatPrice
      }
      quantity
      createdAt
      expirationDate
      quantityAvailable
      sellerLocation
    }
  }
`;

/**
 * __useGetActiveListingsQuery__
 *
 * To run a query within a React component, call `useGetActiveListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveListingsQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetActiveListingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveListingsQuery,
    GetActiveListingsQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetActiveListingsQuery,
    GetActiveListingsQueryVariables
  >(GetActiveListingsDocument, baseOptions);
}
export function useGetActiveListingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveListingsQuery,
    GetActiveListingsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetActiveListingsQuery,
    GetActiveListingsQueryVariables
  >(GetActiveListingsDocument, baseOptions);
}
export type GetActiveListingsQueryHookResult = ReturnType<
  typeof useGetActiveListingsQuery
>;
export type GetActiveListingsLazyQueryHookResult = ReturnType<
  typeof useGetActiveListingsLazyQuery
>;
export type GetActiveListingsQueryResult = Apollo.QueryResult<
  GetActiveListingsQuery,
  GetActiveListingsQueryVariables
>;
export const AddressLookupDocument = gql`
  query addressLookup($code: String) {
    addressLookup(code: $code) {
      postCode
      addresses
    }
  }
`;

/**
 * __useAddressLookupQuery__
 *
 * To run a query within a React component, call `useAddressLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressLookupQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAddressLookupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddressLookupQuery,
    AddressLookupQueryVariables
  >,
) {
  return Apollo.useQuery<AddressLookupQuery, AddressLookupQueryVariables>(
    AddressLookupDocument,
    baseOptions,
  );
}
export function useAddressLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddressLookupQuery,
    AddressLookupQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AddressLookupQuery, AddressLookupQueryVariables>(
    AddressLookupDocument,
    baseOptions,
  );
}
export type AddressLookupQueryHookResult = ReturnType<
  typeof useAddressLookupQuery
>;
export type AddressLookupLazyQueryHookResult = ReturnType<
  typeof useAddressLookupLazyQuery
>;
export type AddressLookupQueryResult = Apollo.QueryResult<
  AddressLookupQuery,
  AddressLookupQueryVariables
>;
export const ValidPostcodeDocument = gql`
  query validPostcode($code: String) {
    validPostcode(code: $code)
  }
`;

/**
 * __useValidPostcodeQuery__
 *
 * To run a query within a React component, call `useValidPostcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidPostcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidPostcodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidPostcodeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ValidPostcodeQuery,
    ValidPostcodeQueryVariables
  >,
) {
  return Apollo.useQuery<ValidPostcodeQuery, ValidPostcodeQueryVariables>(
    ValidPostcodeDocument,
    baseOptions,
  );
}
export function useValidPostcodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidPostcodeQuery,
    ValidPostcodeQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ValidPostcodeQuery, ValidPostcodeQueryVariables>(
    ValidPostcodeDocument,
    baseOptions,
  );
}
export type ValidPostcodeQueryHookResult = ReturnType<
  typeof useValidPostcodeQuery
>;
export type ValidPostcodeLazyQueryHookResult = ReturnType<
  typeof useValidPostcodeLazyQuery
>;
export type ValidPostcodeQueryResult = Apollo.QueryResult<
  ValidPostcodeQuery,
  ValidPostcodeQueryVariables
>;
export const ValidIbanDocument = gql`
  query validIBAN($iban: String) {
    validIBAN(iban: $iban)
  }
`;

/**
 * __useValidIbanQuery__
 *
 * To run a query within a React component, call `useValidIbanQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidIbanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidIbanQuery({
 *   variables: {
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useValidIbanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ValidIbanQuery,
    ValidIbanQueryVariables
  >,
) {
  return Apollo.useQuery<ValidIbanQuery, ValidIbanQueryVariables>(
    ValidIbanDocument,
    baseOptions,
  );
}
export function useValidIbanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidIbanQuery,
    ValidIbanQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ValidIbanQuery, ValidIbanQueryVariables>(
    ValidIbanDocument,
    baseOptions,
  );
}
export type ValidIbanQueryHookResult = ReturnType<typeof useValidIbanQuery>;
export type ValidIbanLazyQueryHookResult = ReturnType<
  typeof useValidIbanLazyQuery
>;
export type ValidIbanQueryResult = Apollo.QueryResult<
  ValidIbanQuery,
  ValidIbanQueryVariables
>;
export const GetUserAssetsByIdDocument = gql`
  query getUserAssetsById($assetId: String!) {
    getUserAssetsById(assetId: $assetId) {
      quantityOwned
      quantityAvailable
      pieces {
        id
        status
        price {
          value
          currency
          formatPrice
          symbol
          postFix
        }
      }
    }
  }
`;

/**
 * __useGetUserAssetsByIdQuery__
 *
 * To run a query within a React component, call `useGetUserAssetsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssetsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssetsByIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetUserAssetsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAssetsByIdQuery,
    GetUserAssetsByIdQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetUserAssetsByIdQuery,
    GetUserAssetsByIdQueryVariables
  >(GetUserAssetsByIdDocument, baseOptions);
}
export function useGetUserAssetsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAssetsByIdQuery,
    GetUserAssetsByIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetUserAssetsByIdQuery,
    GetUserAssetsByIdQueryVariables
  >(GetUserAssetsByIdDocument, baseOptions);
}
export type GetUserAssetsByIdQueryHookResult = ReturnType<
  typeof useGetUserAssetsByIdQuery
>;
export type GetUserAssetsByIdLazyQueryHookResult = ReturnType<
  typeof useGetUserAssetsByIdLazyQuery
>;
export type GetUserAssetsByIdQueryResult = Apollo.QueryResult<
  GetUserAssetsByIdQuery,
  GetUserAssetsByIdQueryVariables
>;
export const GetAllListingsByUserIdDocument = gql`
  query getAllListingsByUserId($userId: String) {
    getAllListingsByUserId(userId: $userId) {
      id
      userId
      price {
        value
        currency
        formatPrice
        symbol
        postFix
      }
      quantity
      quantityAvailable
      createdAt
      expirationDate
      assetId
    }
  }
`;

/**
 * __useGetAllListingsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetAllListingsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllListingsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllListingsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllListingsByUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllListingsByUserIdQuery,
    GetAllListingsByUserIdQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetAllListingsByUserIdQuery,
    GetAllListingsByUserIdQueryVariables
  >(GetAllListingsByUserIdDocument, baseOptions);
}
export function useGetAllListingsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllListingsByUserIdQuery,
    GetAllListingsByUserIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetAllListingsByUserIdQuery,
    GetAllListingsByUserIdQueryVariables
  >(GetAllListingsByUserIdDocument, baseOptions);
}
export type GetAllListingsByUserIdQueryHookResult = ReturnType<
  typeof useGetAllListingsByUserIdQuery
>;
export type GetAllListingsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetAllListingsByUserIdLazyQuery
>;
export type GetAllListingsByUserIdQueryResult = Apollo.QueryResult<
  GetAllListingsByUserIdQuery,
  GetAllListingsByUserIdQueryVariables
>;
export const GetListingsByAssetIdDocument = gql`
  query getListingsByAssetId($assetId: String!, $status: String) {
    getListingsByAssetId(assetId: $assetId, status: $status) {
      id
      userId
      price {
        value
        currency
        formatPrice
        symbol
        postFix
      }
      quantity
      quantityAvailable
      createdAt
      expirationDate
      assetId
      status
      currency
    }
  }
`;

/**
 * __useGetListingsByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetListingsByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListingsByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListingsByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetListingsByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetListingsByAssetIdQuery,
    GetListingsByAssetIdQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetListingsByAssetIdQuery,
    GetListingsByAssetIdQueryVariables
  >(GetListingsByAssetIdDocument, baseOptions);
}
export function useGetListingsByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetListingsByAssetIdQuery,
    GetListingsByAssetIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetListingsByAssetIdQuery,
    GetListingsByAssetIdQueryVariables
  >(GetListingsByAssetIdDocument, baseOptions);
}
export type GetListingsByAssetIdQueryHookResult = ReturnType<
  typeof useGetListingsByAssetIdQuery
>;
export type GetListingsByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetListingsByAssetIdLazyQuery
>;
export type GetListingsByAssetIdQueryResult = Apollo.QueryResult<
  GetListingsByAssetIdQuery,
  GetListingsByAssetIdQueryVariables
>;
export const GeoLookupDocument = gql`
  query geoLookup {
    getGeolocation {
      triggerKYCAmount
      paymentMethod
      countryName
      countryCode
      currencyCode
      currencySymbol
      currencyPostfix
      canSellOnTheMarketplace
      canBuyOnTheMarketplace
      formatPrice
      stripeMonthlyFee
    }
  }
`;

/**
 * __useGeoLookupQuery__
 *
 * To run a query within a React component, call `useGeoLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoLookupQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeoLookupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeoLookupQuery,
    GeoLookupQueryVariables
  >,
) {
  return Apollo.useQuery<GeoLookupQuery, GeoLookupQueryVariables>(
    GeoLookupDocument,
    baseOptions,
  );
}
export function useGeoLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeoLookupQuery,
    GeoLookupQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GeoLookupQuery, GeoLookupQueryVariables>(
    GeoLookupDocument,
    baseOptions,
  );
}
export type GeoLookupQueryHookResult = ReturnType<typeof useGeoLookupQuery>;
export type GeoLookupLazyQueryHookResult = ReturnType<
  typeof useGeoLookupLazyQuery
>;
export type GeoLookupQueryResult = Apollo.QueryResult<
  GeoLookupQuery,
  GeoLookupQueryVariables
>;

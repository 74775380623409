import React, {useContext, useEffect, useState} from 'react';
import {
  ONE_C_MAGENTA_ID,
  EDWARD_VIII_PENNY_ID,
  REIGNING_QUEENS_ID,
  ORIGIN_OF_SPECIES,
} from '../shared/consts';
import {useLocalStorage} from './local-storage';

export interface BasketContextProps {
  quantity: number;
  addons: string[];
  shippingAddress: string;
  setQuantity: (value: number) => void;
  storageKey?: string;
  setStorageKey: (value: string) => void;
  setAddons: (value: string[]) => void;
  setShippingAddress: (value: string) => void;
}

const BasketContext = React.createContext<BasketContextProps>({
  setQuantity: () => null,
  setStorageKey: () => null,
  setAddons: () => null,
  setShippingAddress: () => null,
  quantity: 9 || 20,
  addons: [],
  shippingAddress: '',
});

const BasketProvider: React.FC<React.PropsWithChildren> = (props) => {
  const {getStorageValue, setStorageValue} = useLocalStorage();
  const [storageKey, setStorageKey] = useState<string | undefined>();

  const [quantity, setQuantity] = useState<number>(1);
  const [addons, setAddons] = useState<string[]>([]);
  const [shippingAddress, setShippingAddress] = useState<string>('');

  useEffect(() => {
    if (storageKey) {
      if (storageKey === ONE_C_MAGENTA_ID) {
        setQuantity(getStorageValue(storageKey, 1));
      }
      if (
        storageKey === EDWARD_VIII_PENNY_ID ||
        storageKey === ORIGIN_OF_SPECIES
      ) {
        setQuantity(getStorageValue(storageKey, 1));
      }
      if (storageKey === REIGNING_QUEENS_ID) {
        setQuantity(getStorageValue(storageKey, 1));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey]);

  useEffect(() => {
    if (storageKey && quantity) {
      setStorageValue(storageKey, quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey, quantity]);

  useEffect(() => {
    if (addons) {
      setStorageValue('addons', addons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons]);

  useEffect(() => {
    if (shippingAddress) {
      setStorageValue('shippingAddress', shippingAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingAddress]);

  return (
    <BasketContext.Provider
      value={{
        quantity,
        setQuantity,
        storageKey,
        setStorageKey,
        setAddons,
        addons,
        shippingAddress,
        setShippingAddress,
      }}
    >
      {props.children}
    </BasketContext.Provider>
  );
};

const useBasketContext: () => BasketContextProps = () => {
  return useContext(BasketContext);
};

export {BasketProvider, useBasketContext};

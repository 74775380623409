import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {useUserQuery} from '../../graphql/generated';
import {mixpanel} from '../../utils/mixpanel';

export const GlobalAnalyticsSetup: React.FC = () => {
  const {data, loading} = useUserQuery();
  const {pathname} = useRouter();

  useEffect(() => {
    if (!loading && data) {
      if (mixpanel) {
        mixpanel.identify(data?.user?.email);
        mixpanel.people.set_once({
          name: `${data?.user?.firstName} ${data?.user?.lastName}`,
          email: data?.user?.email,
          domain: data?.user?.email.split('@').pop(),
        });
      }
    }
  }, [pathname, loading, data]);

  return <></>;
};

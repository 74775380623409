export const useLocalStorage: () => {
  getStorageValue: (key: any, defaultValue: any) => any;
  setStorageValue: (key: any, value: any) => void;
  removeStorageValue: (key: any) => void;
} = () => {
  const getStorageValue = (key: string, defaultValue?: Record<string, any>) => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem(key);
      const initial = saved && JSON.parse(saved);
      return initial || defaultValue;
    }
    return null;
  };

  const setStorageValue = (key: string, value: Record<string, any>) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeStorageValue = (key: string) => {
    localStorage.removeItem(key);
  };

  return {
    getStorageValue,
    setStorageValue,
    removeStorageValue,
  };
};

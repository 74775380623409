/* eslint-disable no-shadow */
export const REDIRECT_KEY = 'redirect';
export const RETURN_TO_CHECKOUT_PAGE_KEY = 'go_to_checkout';

export const passwordRegExp =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])?.{8,}$/;

export const emailRegExp =
  // eslint-disable-next-line no-useless-escape
  /^([a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*)@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const sanitiseEmail = (email: string): string => {
  return email.toLocaleLowerCase().trim();
};

export const EARLY_ACCESS_GOAL = 8000;

export const S3_ASSET_BUCKET =
  'https://showpiece-assets.s3.eu-west-2.amazonaws.com';

export const ONE_C_MAGENTA_ID = '1.c.magenta';
export const EDWARD_VIII_PENNY_ID = 'edward.8.penny';
export const REIGNING_QUEENS_ID = 'reigning.queens';
export const ORIGIN_OF_SPECIES = 'origin.of.species';
export const VALENTINES_DAY_MASCARA = 'valentines.day.mascara';

// eslint-disable-next-line no-shadow
export enum Routes {
  ONE_C_MAGENTA = '/1c-magenta',
  EDWARD_VIII_PENNY = '/edward8-penny',
  REIGNING_QUEEN = '/reigning-queen',
  ORIGIN_OF_SPECIES = '/origin-of-species',
  LOGIN = '/login',
  MY_COLLECTION = '/my-collection',
  VERIFICATION = '/verification',
  VERIFICATION_REFERRAL = '/verification/referral',
  MY_ACCOUNT = '/my-account',
  HOMEPAGE = '/',
  OWN_A_PIECE = '/own-a-piece',
  EDWARD_CHECKOUT = '/checkout/edward8-penny',
  MAGENTA_CHECKOUT = '/checkout/1c-magenta',
  ADD_SIGNATURE = '/add-your-signature',
  FLIP_THE_COIN = '/flip-the-coin',
  REFER_A_FRIEND = '/refer-a-friend',
  CREATE_ACCOUNT = '/create-account',
  REDEEM = '/redeem',
  VOTE = '/vote',
  THANKS_VOTE = '/thanks-for-voting',
  ORDER_SUCCESSFUL = '/order-successful',
  QUEUE = '/queue',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  CHOOSE_PORTRAIT = '/choose-my-portrait',
  CHOOSE_COVER = '/choose-my-cover',
  MARKETPLACE = '/marketplace',
  GIFT_CARDS = '/gift-cards',
  ACCOUNT_PERSONALISATION = '/account-personalisation',
}

export enum StorageKeys {
  MAGENTA_KEY = '1c-magenta',
  EDWARD_KEY = 'edward8-penny',
  REIGNING_QUEENS_KEY = 'reigning-queen',
}

export const ONE_C_MAGENTA_SALE = {
  timeTillSaleCloses: '5/1/2022',
  timeEndNotification: 'Initial Offering Closed',
};

export enum SurveyVotingIds {
  SURVEY_ONE = 'voting-survey-1',
}

export enum SELL_DRAWER_STEPS {
  CONTACT_AND_DOB = 'dobAndContact',
  SELL = 'sell',
  CONFIRM = 'confirm',
  CONFIRM_PRICE = 'confirmPrice',
  PAYMENT_DETAILS = 'paymentDetails',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
}

export enum BUYER_DRAWER_STEPS {
  BUY = 'buy',
  PAYMENT = 'payment',
  NO_ITEMS = 'noItems',
  OWN_LISTING = 'ownListing',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
}

export enum HeapAnalyticsId {
  DEVELOPMENT = 2866618413,
  PRODUCTION = 1573879558,
}

export const STRIPE_MONTHLY_FEE = 2;

export const STRIPE_MONTHLY_FEE_IN = 150;
export const STRIPE_MONTHLY_FEE_JP = 200;
export const STRIPE_MONTHLY_FEE_TH = 60;

export const STRIPE_TRANSACTION_FEE = 0.029;
export const STRIPE_FIXED_TRANSACTION_FEE = 0.2;

export const MARKETPLACE_SESSION_TIMEOUT = 10; // minutes

export const CURRENCIES_WITHOUT_DECIMALS = ['JPY'];

export const TRUSTPILOT_COUNTRIES = ['GB'];

export const SUPPORTED_COUNTRIES_BY_STRIPE = [
  'JP',
  'US',
  'AU',
  'AT',
  'BE',
  'BR',
  'BG',
  'CA',
  'HR',
  'CY',
  'CZ',
  'DE',
  'EE',
  'FI',
  'FR',
  'DE',
  'GI',
  'GR',
  'HK',
  'HU',
  'IN',
  'IE',
  'IT',
  'LV',
  'ID',
  'PH',
  'LI',
  'LT',
  'GB',
  'AE',
  'TH',
  'SH',
  'SE',
  'ES',
  'SI',
  'SK',
  'SG',
  'RO',
  'PT',
  'PL',
  'NO',
  'NZ',
  'NL',
  'MX',
  'MT',
  'MY',
  'LU',
];

export const BLOG_URL =
  'https://showpiece-25252510.hubspotpagebuilder.eu/showpiece-blog';

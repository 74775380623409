import {Text, Box, Button} from '@chakra-ui/react';
import {useState, useEffect} from 'react';
import {InputCounterField} from '../../forms/input-counter-field';
import {PiecePurchaseList} from '../transaction-drawer/piece-purchase-list';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {assetInfo} from '../../../utils/own-a-piece-hero';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {Listing} from '../../../graphql/generated';
import {AssetOverviewProps} from '../../../hooks/current-asset-overview';
import {useAssetPurchaseLimitHook} from '../../../hooks/asset-purchase-limit-hook';
import {AssetLimitError} from '../../global/asset-limit-error';
import {InputCover} from '../forms/inputCover';
import {useGeolocation} from '../../../hooks/geolocation';

interface QuickBuyPiecesProps {
  onSubmit: () => void;
  asset: AssetOverviewProps;
  isLoading?: boolean;
  maxPieces: number;
  basketItems?: Listing[] | [];
  userListings: number | undefined;
}

// interface QuickBuyDrawerProps {}
export const QuickBuyPieces: React.FC<QuickBuyPiecesProps> = ({
  asset,
  onSubmit,
  maxPieces,
  basketItems,
  isLoading,
  userListings,
}) => {
  const {drawerQuantity, updateQuantity} = useTransactionDrawerContext();
  const {
    isPurchasedBlocked,
    isPurchasedBlockedLoading,
    allowedPiecesToPurchase,
  } = useAssetPurchaseLimitHook(
    drawerQuantity,
    asset.maxAssetsPerUser || 0,
    asset.assetId,
  );
  const {triggerKYCAmount} = useGeolocation();

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (basketItems && basketItems.length) {
      const items = basketItems as Listing[];
      setTotal(
        items.reduce((prev, curr) => {
          if (!curr) return prev;
          return prev + curr.quantity * curr.price.value;
        }, 0),
      );
    }
  }, [basketItems]);

  return (
    <Box>
      {asset && asset.assetId && asset.assetName && (
        <>
          <TransactionDrawerHeader
            title={asset.assetName}
            img={assetInfo[asset.assetId].imgSrc}
            url={assetInfo[asset.assetId].href}
            intro="Instantly sources the best priced Pieces for sale on the marketplace"
          />
        </>
      )}

      {userListings ? (
        <Box mt="16px" width="348px" backgroundColor="#F5F7F7">
          <Text p="10px" textStyle="body2">
            Your listings are not shown here as you are unable to buy your own
            pieces
          </Text>
        </Box>
      ) : null}

      {maxPieces > 0 && (
        <Box mt="42px">
          <InputCover
            id="counter"
            label="Number of Pieces"
            errorMessageColor="tone3"
            errorMessage={`Max. ${maxPieces} Pieces available`}
            isInvalid={
              !!(drawerQuantity && maxPieces && drawerQuantity >= maxPieces)
            }
          >
            <InputCounterField
              displayMaxValue
              value={drawerQuantity || 0}
              maxValue={maxPieces || 0}
              onInputChange={(val: number) => {
                if (updateQuantity) {
                  updateQuantity(val);
                }
              }}
            />
          </InputCover>
        </Box>
      )}

      <Box>
        <Text textStyle="body4" fontWeight="400" mt="40px">
          The average price paid per Piece was
          <Text as="span" fontWeight="bold" mx="4px">
            {`${asset.pricing?.symbol}${asset.marketplaceAveragePrice}`}
            <Text as="span" fontSize="0.7em">
              {asset.pricing?.postFix}
            </Text>
          </Text>
          for the 10 most recent {asset.assetName} transactions
        </Text>
      </Box>

      <Box mt="20px">
        {basketItems && basketItems.length > 0 && !!drawerQuantity && (
          <PiecePurchaseList items={basketItems || []} />
        )}
      </Box>

      <TransactionDrawerFooter>
        <Button
          colorScheme="aqua"
          height="56px"
          width="100%"
          isLoading={isLoading || isPurchasedBlockedLoading}
          onClick={() => onSubmit()}
          isDisabled={
            !(drawerQuantity && drawerQuantity > 0) ||
            total >= triggerKYCAmount ||
            isPurchasedBlocked
          }
        >
          Continue to payment
        </Button>
        {isPurchasedBlocked && (
          <AssetLimitError allowedPiecesToPurchase={allowedPiecesToPurchase} />
        )}
        {total >= triggerKYCAmount && (
          <Text textStyle="body3" mt="12px" textAlign="center">
            As your transaction is over £10,000, we are legally required to
            conduct Know Your Customer (KYC) checks. Please contact
            info@showpiece.com so we can get your account verified for this
            purchase.
          </Text>
        )}
      </TransactionDrawerFooter>
    </Box>
  );
};

import constate from 'constate';
import {useEffect, useState} from 'react';
import {CognitoHostedUIIdentityProvider, CognitoUser} from '@aws-amplify/auth';
import * as Sentry from '@sentry/nextjs';
import {Amplify, Auth, Hub} from 'aws-amplify';

import {useRouter} from 'next/router';
import {useApolloClient} from '@apollo/client';
import {mixpanel} from '../utils/mixpanel';
import {
  useCheckUserSocialLoginAccountMutation,
  useUserQuery,
} from '../graphql/generated';
import {baseUrl} from '../utils/utils';
import {Routes} from '../shared/consts';
import {getJwt} from '../graphql/client';
import {useAppToast} from './app-toast';

Amplify.configure({
  userPoolId: process.env.COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
  oauth: {
    domain: `showpiece-${process.env.STAGE}.auth.eu-west-2.amazoncognito.com`,
    scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
    region: 'eu-west-2',
    redirectSignIn: baseUrl,
    redirectSignOut: baseUrl,
    responseType: 'code',
  },
});

const useCognitoHook = () => {
  const userQuery = useUserQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<CognitoUser>();
  const [error, setError] = useState();
  const [redirectAfterAction, setRedirectAfterAction] = useState('');

  const [checkUserSocialLoginAccountMutation] =
    useCheckUserSocialLoginAccountMutation();
  const apollo = useApolloClient();
  const router = useRouter();

  const currentLocation = router.pathname;

  const authUser = async (
    cognitoUser: CognitoUser,
    referrerId: string,
    socialSignInRedirect: string,
  ) => {
    setUser(cognitoUser);
    if (referrerId) {
      const AssignReferral = `
          mutation AssignReferral{
            assignReferral(code:"${referrerId}"){
              message
            }
          }
          `;

      const jwt = await getJwt();
      const token = jwt || 'logged-out';
      await fetch(`${process.env.BASE_URL}/graphql`, {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          query: AssignReferral,
          variables: {
            now: new Date().toISOString(),
          },
        }),
      });
    }
    if (socialSignInRedirect) {
      router.replace(socialSignInRedirect);
    }
  };

  // Extract redirect data from url query.
  useEffect(() => {
    if (router.query.returnTo) {
      setRedirectAfterAction(`/${router.query.returnTo}`);
    }
  }, [router]);

  useEffect(() => {
    let socialSignInRedirect = '';
    let referrerId = '';
    const excludeUriFromRedirect = [
      Routes.LOGIN as string,
      Routes.VERIFICATION as string,
    ];

    Hub.listen('auth', ({payload: {event, data}}) => {
      switch (event) {
        case 'customOAuthState': {
          // handle redirect from social login.
          const customState = JSON.parse(data);
          if (customState.route !== router.pathname) {
            socialSignInRedirect = !excludeUriFromRedirect.includes(
              customState.route,
            )
              ? customState.route
              : Routes.MY_COLLECTION;
          }

          if (customState.referrerId) {
            referrerId = customState.referrerId;
          }
          break;
        }

        case 'signOut': {
          setRedirectAfterAction(Routes.HOMEPAGE);
          break;
        }

        case 'signIn': {
          if (!redirectAfterAction && !data.username.includes('Google')) {
            setRedirectAfterAction(
              !excludeUriFromRedirect.includes(currentLocation)
                ? currentLocation
                : Routes.MY_COLLECTION,
            );
          }
          break;
        }

        case 'autoSignIn': {
          authUser(data, referrerId, socialSignInRedirect);
          break;
        }

        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((cognitoUser) =>
        authUser(cognitoUser, referrerId, socialSignInRedirect),
      )
      .catch(setError)
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loggedUser = userQuery.data?.user;
    if (loggedUser) {
      mixpanel.identify(loggedUser.id);
      Sentry.setUser({
        id: loggedUser.id,
        email: loggedUser.email,
        username: `${loggedUser.firstName} ${loggedUser.lastName}`,
      });
    }
  }, [userQuery.data?.user]);

  return {
    isLoading,
    user,
    error,
    logout: async () =>
      Auth.signOut().then(() => {
        apollo.resetStore();
        router.reload();
      }),
    ssoSignIn: async () => {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
        customState: JSON.stringify({
          route: router.pathname,
          referrerId: router.query.referCode,
        }),
      });
    },
    signIn: async (
      username: string,
      password: string,
      newPassword?: string,
      firstName?: string,
      lastName?: string,
    ) =>
      Auth.signIn({
        username,
        password,
        // eslint-disable-next-line no-shadow
      }).then(async (userData) => {
        if (userData.challengeName === 'NEW_PASSWORD_REQUIRED' && newPassword) {
          await Auth.completeNewPassword(userData, newPassword, {
            given_name: firstName,
            family_name: lastName,
          });
        }

        apollo.resetStore();
        return userData;
      }),
    forgotPassword: async (username: string) => Auth.forgotPassword(username),
    forgotPasswordSubmit: async (
      username: string,
      code: string,
      password: string,
    ) => Auth.forgotPasswordSubmit(username, code, password),
    resetPasswordSubmit: async (oldPassword: string, newPassword: string) =>
      Auth.currentAuthenticatedUser().then((userData) =>
        Auth.changePassword(userData, oldPassword, newPassword),
      ),
    signUp: async (
      data: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        phoneNumber?: string;
        redirect?: string;
        referrerId?: string;
      },
      autoSignInOnConfirm?: boolean,
    ) =>
      Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          given_name: data.firstName,
          family_name: data.lastName,
          email: data.email,
          phone_number: data.phoneNumber,
          'custom:redirect': data.redirect,
          'custom:referrer': data.referrerId,
        },
        autoSignIn: {
          enabled: !!autoSignInOnConfirm,
        },
      }),
    confirmSmsSignUp: async (username: string, code: string) =>
      Auth.confirmSignUp(username, code),
    validateUserSocialLoginAccount: async (email: string) => {
      const result = await checkUserSocialLoginAccountMutation({
        variables: {
          username: email,
        },
      });

      return result.data?.checkUserSocialLoginAccount;
    },

    changeName: async (data: {firstName: string; lastName: string}) =>
      Auth.currentAuthenticatedUser()
        // eslint-disable-next-line no-shadow
        .then((userData) => {
          Auth.updateUserAttributes(userData, {
            given_name: data.firstName,
            family_name: data.lastName,
          });
        }),
    handleRedirect: (customRedirect?: string) => {
      let path = redirectAfterAction || customRedirect;

      // if no path go to homepage.
      if (!path) {
        path = Routes.HOMEPAGE;
      }

      // if the path is homepage but we have custom redirect we will redirect to custom.
      if (path === Routes.HOMEPAGE && customRedirect) {
        path = customRedirect;
      }

      return router.replace(path);
    },
  };
};

const useCognitoVerification = (): {
  isLoading: boolean;
  error: (Error & {code?: string}) | undefined;
  isVerified: boolean;
  isResendingEmailOrSms: boolean;
  onResendVerificationEmailOrSms: (user?: string) => void;
} => {
  const router = useRouter();
  const {
    verify_code: code,
    email,
    username,
  } = (router.query || {}) as Record<string, string>;
  const toast = useAppToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isResendingEmailOrSms, setIsResendingEmailOrSms] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState<Error & {code?: string}>();
  useEffect(() => {
    if (code && username) {
      Auth.confirmSignUp(username, code)
        .then(() => setIsVerified(true))
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [code, username]);

  return {
    isLoading,
    isVerified,
    error,
    isResendingEmailOrSms,
    onResendVerificationEmailOrSms: (user?: string) => {
      setIsResendingEmailOrSms(true);
      Auth.resendSignUp(user ?? email)
        .catch((e) => {
          toast.showErrorToast('Something went wrong. Please try again.');
          Sentry.captureException(e);
          throw e;
        })
        .finally(() => setIsResendingEmailOrSms(false));
    },
  };
};

const [CognitoProvider, useCognito] = constate(useCognitoHook);
export {
  CognitoProvider,
  useCognito,
  useCognitoVerification,
  Auth as CognitoAuth,
};

import {Button, Box, Text, Input, Flex} from '@chakra-ui/react';
import {useEffect, useRef, useState} from 'react';

interface InputCounterFieldProps {
  label?: string;
  errorForMaxValue?: string;
  value: number;
  maxValue: number;
  displayMaxValue?: boolean;
  onInputChange: (val: number) => void;
  size?: 'regular' | 'slim';
}

const getSizeOptions = (size: string) => {
  switch (size) {
    case 'slim':
      return {
        base: '45px',
        padding: '12px',
      };
    default:
      return {
        base: '56px',
        padding: '17px',
      };
  }
};

export const InputCounterField: React.FC<InputCounterFieldProps> = (props) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [maxValue, setMaxValue] = useState<number>(props.maxValue);
  const sizeOptions = getSizeOptions(props.size || 'regular');

  useEffect(() => setMaxValue(props.maxValue), [props.maxValue]);

  const calculateDisplayValue = (displayValue: string) => {
    setInputValue(
      props.displayMaxValue ? `${displayValue} of ${maxValue}` : displayValue,
    );
  };

  function increaseInputValue() {
    if (inputEl.current?.value) {
      const val = parseInt(inputEl.current.value, 10) + 1;
      if (val <= maxValue) {
        props.onInputChange(val);
        inputEl.current.value = val.toString();
        calculateDisplayValue(val.toString());
      }
    }
  }

  function decreaseInputValue() {
    if (inputEl.current?.value) {
      const val = parseInt(inputEl.current.value, 10) - 1;
      const calcValue = (val <= 0 ? 0 : val).toString();
      inputEl.current.value = calcValue;
      calculateDisplayValue(calcValue);
      props.onInputChange(Number(calcValue));
    }
  }

  useEffect(() => {
    calculateDisplayValue(props.value.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <Box>
      {props.label && (
        <Text
          as="label"
          display="block"
          htmlFor="quantity"
          textStyle="body3"
          color="tone3"
          mb="3px"
        >
          {props.label}
        </Text>
      )}
      <Flex color="tone3" height={sizeOptions.base}>
        <Button
          width={sizeOptions.base}
          px={4}
          height="100%"
          border="1px solid"
          bgColor="calcBtnGrey"
          borderColor="tone2"
          color="tone3"
          fontSize="18px"
          zIndex="docked"
          textStyle="h6"
          onClick={() => decreaseInputValue()}
          aria-label="Decrease total number of pieces"
          isDisabled={!(Number(inputEl.current?.value) > 0)}
        >
          -
        </Button>
        <Box
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="tone2"
          mx="-12px"
          pos="relative"
          minWidth="100px"
        >
          <Input
            id="quantity"
            name="quantity"
            ref={inputEl}
            height="100%"
            width="100%"
            variant="copper"
            textStyle="body3"
            fontSize="14px"
            color="tone3"
            px={2}
            textAlign="center"
            type="number"
            bgColor="white"
            defaultValue={props.value}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate3d(-50%, -50%, 0)"
            opacity={0}
            _focus={{
              opacity: 1,
            }}
            onChange={() => {
              if (!inputEl.current) return;
              const setValue = inputEl.current?.value;
              const calcValue = parseInt(setValue, 10);
              if (calcValue < 1) {
                inputEl.current.value = '0';
                calculateDisplayValue('0');
                return;
              }
              if (calcValue > maxValue) {
                inputEl.current.value = props.maxValue.toString();
                calculateDisplayValue(props.maxValue.toString());
                props.onInputChange(props.maxValue);
                return;
              }
              calculateDisplayValue(calcValue.toString());
              props.onInputChange(calcValue);
            }}
          />
          <Text
            width="100%"
            background="white"
            textAlign="center"
            textStyle="body3"
            color="tone3"
            pointerEvents="none"
            paddingX="25px"
            paddingTop={sizeOptions.padding}
          >
            {inputValue}
          </Text>
        </Box>
        <Button
          width={sizeOptions.base}
          px={4}
          height="100%"
          border="1px solid"
          bgColor="calcBtnGrey"
          borderColor="tone2"
          color="tone3"
          fontSize="18px"
          zIndex="docked"
          textStyle="h6"
          onClick={() => increaseInputValue()}
          aria-label="Increase total number of pieces"
          isDisabled={Number(inputEl.current?.value) === props.maxValue}
        >
          +
        </Button>
      </Flex>
    </Box>
  );
};

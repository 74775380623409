import {Router} from 'next/router';
import {useEffect} from 'react';

export const useInitPixelEffect = (): void => {
  useEffect(() => {
    import('react-facebook-pixel')
      .then(({default: ReactPixel}) => {
        ReactPixel.init('1072919680201622');
        Router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      })
      // eslint-disable-next-line no-console
      .catch(console.warn);
  }, []);
};

export const usePixelPurchase = ({
  currency,
  quantity,
  value,
}: {
  currency?: string;
  quantity?: number;
  value?: number;
}): void => {
  useEffect(() => {
    if (!currency || !quantity || !value) return;
    import('react-facebook-pixel')
      .then(({default: ReactPixel}) => {
        ReactPixel.init('1072919680201622');
        ReactPixel.track('Purchase', {
          currency,
          value,
          quantity,
          asset: '1c magenta',
        });
      })
      // eslint-disable-next-line no-console
      .catch(console.warn);
  }, [currency, quantity, value]);
};

export const pixelTrackEvent = (eventName: string): void => {
  if (!eventName) return;
  import('react-facebook-pixel')
    .then(({default: ReactPixel}) => {
      ReactPixel.init('1072919680201622');
      ReactPixel.track(eventName);
    })
    // eslint-disable-next-line no-console
    .catch(console.warn);
};

import {ApolloClient, HttpLink, InMemoryCache, concat} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {CognitoAuth} from '../hooks/cognito';

export const getJwt = async () => {
  try {
    const currentSession = await CognitoAuth.currentSession();
    return currentSession.getAccessToken().getJwtToken();
  } catch {
    return null;
  }
};

const authLink = setContext(async () => {
  const jwt = await getJwt();
  return {
    headers: {
      // The API Requires a value for `Authorization` in every case
      Authorization: jwt || 'logged-out',
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_API_ENDPOINT,
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, httpLink),
});

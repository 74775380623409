import {Box, Button} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import {TransactionDrawerHeader} from './transaction-drawer-header';
import {TransactionDrawerFooter} from './transaction-drawer-footer';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {Routes} from '../../../shared/consts';

interface TransactionDrawerUnavailableProps {
  isActive?: boolean;
}

export const TransactionDrawerUnavailable: React.FC<
  TransactionDrawerUnavailableProps
> = ({isActive = false}) => {
  const {resetDrawer} = useTransactionDrawerContext();
  const router = useRouter();
  return (
    <>
      {isActive && (
        <Box
          pos="absolute"
          zIndex={10}
          top={0}
          left={0}
          width="100%"
          height="100%"
          background="white"
          paddingTop={{base: '32px', md: '80px'}}
          paddingX={{base: '32px', md: '48px'}}
        >
          <TransactionDrawerHeader
            title="All your pieces have been listed"
            intro="To view your listings, go to My Account."
          />
          <TransactionDrawerFooter variation="no-shadow">
            <Button
              colorScheme="aqua"
              height="56px"
              display="block"
              width="100%"
              mb={4}
              onClick={() => {
                router.push(Routes.MY_ACCOUNT);
                if (resetDrawer) resetDrawer();
              }}
            >
              View My Listings
            </Button>
            <Button
              colorScheme="aqua"
              height="56px"
              width="100%"
              variant="outline"
              onClick={() => resetDrawer && resetDrawer()}
            >
              Exit
            </Button>
          </TransactionDrawerFooter>
        </Box>
      )}
    </>
  );
};

import {Text, Box, Button, Flex} from '@chakra-ui/react';
import {useState, useEffect} from 'react';
import {InputCounterField} from '../../forms/input-counter-field';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {Listing} from '../../../graphql/generated';
import {AssetOverviewProps} from '../../../hooks/current-asset-overview';
import {useAssetPurchaseLimitHook} from '../../../hooks/asset-purchase-limit-hook';
import {AssetLimitError} from '../../global/asset-limit-error';
import {InputCover} from '../forms/inputCover';
import {useGeolocation} from '../../../hooks/geolocation';

interface BuyPiecesProps {
  onSubmit: () => void;
  asset: AssetOverviewProps;
  isLoading?: boolean;
  listing?: Listing;
  ownListing: boolean;
}

export const BuyPieces: React.FC<BuyPiecesProps> = ({
  asset,
  onSubmit,
  isLoading,
  listing,
  ownListing,
}) => {
  const {drawerQuantity, updateQuantity} = useTransactionDrawerContext();
  const {
    isPurchasedBlocked,
    isPurchasedBlockedLoading,
    allowedPiecesToPurchase,
  } = useAssetPurchaseLimitHook(
    drawerQuantity,
    asset.maxAssetsPerUser || 0,
    asset.assetId,
  );
  const {triggerKYCAmount} = useGeolocation();

  const [total, setTotal] = useState(0);
  const [maxPieces, setMaxPieces] = useState(0);

  useEffect(() => {
    if (listing) {
      setMaxPieces(listing.quantityAvailable);
    }
  }, [listing]);

  useEffect(() => {
    if (listing && drawerQuantity !== undefined) {
      setTotal(listing.price.value * drawerQuantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerQuantity, listing]);

  return (
    <Box>
      <TransactionDrawerHeader
        title="Review"
        intro="Please review the details of your purchase"
      />

      {ownListing ? (
        <Box mt="16px" width="348px" backgroundColor="#F5F7F7">
          <Text p="10px" textStyle="body2">
            This is your listing. Unfortunately, you are unable to buy your own
            pieces.
          </Text>
        </Box>
      ) : null}

      {maxPieces > 0 && (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb="15px"
            mt="45px"
          >
            <Text textStyle="body3" color="tone3">
              Showpiece Title
            </Text>
            <Text textStyle="body2">{asset.assetName}</Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text textStyle="body3" color="tone3">
              Quantity
            </Text>
            <Box>
              <InputCover
                id="counter"
                label="Number of Pieces"
                visuallyHideLabel
                errorMessageColor="tone3"
                errorMessage={`Max. ${maxPieces} Pieces available`}
                isInvalid={
                  !!(drawerQuantity && maxPieces && drawerQuantity >= maxPieces)
                }
              >
                <InputCounterField
                  value={drawerQuantity || 1}
                  maxValue={maxPieces || 0}
                  size="slim"
                  displayMaxValue
                  onInputChange={(val: number) => {
                    if (updateQuantity) {
                      updateQuantity(val);
                    }
                  }}
                />
              </InputCover>
            </Box>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" mt="15px">
            <Text textStyle="body3" color="tone3">
              Price per Piece
            </Text>
            <Text textStyle="body2">
              {listing?.price?.symbol}
              {listing?.price?.formatPrice
                ? listing?.price.value.toFixed(2)
                : listing?.price.value}
              {listing?.price?.postFix}
            </Text>
          </Flex>

          <Box borderTop="1px solid" borderColor="tone1" mt="15px">
            <Flex justifyContent="space-between" alignItems="center" mt="15px">
              <Text textStyle="h7" color="darkGrey">
                Total
              </Text>
              <Text textStyle="h6" color="aqua.500">
                {asset.pricing?.symbol}
                {asset.pricing?.formatPrice ? total.toFixed(2) : total}
                {asset.pricing?.postFix}
              </Text>
            </Flex>
          </Box>
        </>
      )}

      <TransactionDrawerFooter>
        <Button
          colorScheme="aqua"
          height="56px"
          width="100%"
          isLoading={isLoading || isPurchasedBlockedLoading}
          onClick={() => onSubmit()}
          isDisabled={
            !(drawerQuantity && drawerQuantity > 0) ||
            total >= triggerKYCAmount ||
            isPurchasedBlocked ||
            ownListing
          }
        >
          Continue to payment
        </Button>
        {isPurchasedBlocked && (
          <AssetLimitError allowedPiecesToPurchase={allowedPiecesToPurchase} />
        )}
        {total >= triggerKYCAmount && (
          <Text textStyle="body3" mt="12px" textAlign="center">
            As your transaction is over £10,000, we are legally required to
            conduct Know Your Customer (KYC) checks. Please contact
            info@showpiece.com so we can get your account verified for this
            purchase.
          </Text>
        )}
      </TransactionDrawerFooter>
    </Box>
  );
};

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import {useIFrameModalContext} from '../../context/iframe-modal';
import {StripeModal} from './components/stripe';

export const IFrameModal: React.FC = () => {
  const {isActive, isBlocked, toggleModal, modalType} = useIFrameModalContext();

  const handleCloseModal = () => {
    if (!isBlocked && toggleModal) {
      toggleModal();
    }
  };

  return (
    <Modal isOpen={isActive || false} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton isDisabled={isBlocked} />
        <ModalBody>{modalType === 'stripe' && <StripeModal />}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

import {
  Button,
  Text,
  // eslint-disable-next-line no-restricted-imports
  useToast,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {
  useGenerateConnectAccountLinkQuery,
  useUserQuery,
} from '../../../graphql/generated';
import {TransactionDrawerFooter} from './transaction-drawer-footer';
import {TransactionDrawerHeader} from './transaction-drawer-header';
import {mixpanel} from '../../../utils/mixpanel';

export const VerificationDrawer: React.FC = () => {
  const router = useRouter();
  const toast = useToast();
  const user = useUserQuery();
  const generateStripeLink = useGenerateConnectAccountLinkQuery();
  const {setDrawerLoading, toggleDrawer, isLoading} =
    useTransactionDrawerContext();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);

  useEffect(() => {
    if (!user.loading && !generateStripeLink.loading)
      if (setDrawerLoading) setDrawerLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.loading, generateStripeLink.loading]);

  const handleClick = () => {
    setButtonClicked(true);
    mixpanel.track('Go to stripe');

    if (generateStripeLink.error) {
      toast({
        title: 'Error',
        description: generateStripeLink.error.message,
        status: 'error',
      });
      if (toggleDrawer) toggleDrawer();
      return;
    }

    if (!generateStripeLink.data?.generateConnectAccountLink) {
      toast({
        title: 'Error',
        description: 'Please try later',
        status: 'error',
      });
      return;
    }

    router.push(generateStripeLink.data?.generateConnectAccountLink);
  };

  return (
    <>
      <TransactionDrawerHeader
        title="Verify My Account"
        img=""
        intro="In order to sell on the marketplace, you will need to create an account with Stripe Connect, our payment technology provider. This will open on a new page."
      />

      <Text mt="25px" textStyle="body2">
        Verification can take up to 48 hours. We’ll email you when it’s
        completed!
      </Text>

      <TransactionDrawerFooter variation="no-shadow">
        <Button
          colorScheme="aqua"
          height="56px"
          width="100%"
          type="submit"
          //   disabled={!formState.isValid}
          isLoading={isLoading || buttonClicked}
          onClick={handleClick}
        >
          Go to Stripe
        </Button>
      </TransactionDrawerFooter>
    </>
  );
};

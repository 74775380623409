import {TitleMetaLinkTag} from 'react-datocms';
import {ModalTypes} from '../components/modals';

const isPublicEnv = ['demo', 'production'].includes(
  process.env.STAGE as string,
);

const baseUrlPublic =
  process.env.STAGE === 'demo'
    ? 'https://showpiece-staging.vercel.app'
    : 'https://showpiece.com';

const baseUrlLocalHost = 'http://localhost:3000';

export const baseUrl = isPublicEnv ? baseUrlPublic : baseUrlLocalHost;

export const getPlural: (quantity: number) => 's' | '' = (quantity) => {
  if (quantity > 1) return 's';
  return '';
};

export const stripPublicKey =
  process.env.STAGE === 'production'
    ? 'pk_live_51JgpmcFGoJ2fDqrb990oHnk8MuiRS74kYgN6eHmzGmcSNvRyUggxfIra88dlnbxpqcaA9bRiF9I6ApCfmPGYmBNm00UGECPgb5'
    : 'pk_test_51JgpmcFGoJ2fDqrbMunqu47KxMIXRiuBB2brN2xk7b0rl4xAWqypQi4LxaNKLwLfkV85szQ4HVGzYcY1zJsx9iFY00A8LBpOvX';

export const handleScrollToRef: (ref: any) => void = (ref) => {
  const element = ref.current;
  // height of navbar + 50px buffer
  const yOffset = -150;
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: y, behavior: 'smooth'});
};

export const handleReveal: (ref: any) => void = (ref) => {
  // hate this implementation
  const elem = ref.current as HTMLDivElement;
  const main = elem.getElementsByClassName(
    'chakra-collapse',
  )[0] as HTMLDivElement;
  main.style.display = 'block';
  main.style.height = 'auto';
  main.style.opacity = '1';
  main.style.overflow = 'initial';
};

export type ImageUrl = ModalTypes | 'sharing-img' | 'gift-card-img';
export const getGeneratedImageUrl = (
  type: ImageUrl,
  opts: {
    userId?: string;
    assetId?: string;
    signature?: string;
    transactionId?: string;
    isSale?: boolean;
  },
): string | undefined => {
  switch (type) {
    case 'deed':
    case 'gift-card-img': {
      return `${process.env.BASE_URL}/${type}/${opts.transactionId}?sale=${
        opts.isSale ?? false
      }`;
    }

    case 'certificate': {
      if (!opts.userId || !opts.assetId) return undefined;

      return `${process.env.BASE_URL}/${type}/${opts.userId}/${opts.assetId}/png`;
    }

    case 'wallet': {
      if (!opts.userId || !opts.assetId) return undefined;

      return `${process.env.BASE_URL}/${type}/${opts.userId}/${opts.assetId}`;
    }

    default: {
      if (!opts.userId || !opts.assetId) return undefined;

      return `${process.env.BASE_URL}/${type}/${opts.userId}/${opts.assetId}${
        opts.signature ? `?s=${btoa(opts.signature)}` : ''
      }`;
    }
  }
};

type CmsTheme = {
  name: string;
  primary: string;
  secondary: string;
};

export const cmsThemeColors: CmsTheme[] = [
  {
    name: 'queen',
    primary: 'loulou',
    secondary: 'queenBlue',
  },
  {
    name: 'penny',
    primary: 'royalNavy',
    secondary: 'peach',
  },
  {
    name: 'stamp',
    primary: 'midnight',
    secondary: 'stampRed',
  },
  {
    name: 'book',
    primary: 'black',
    secondary: 'bookYellow',
  },
  {
    name: 'banksy',
    primary: 'banksyPrimary',
    secondary: 'queenBlue',
  },
];

export const getCmsTheme = (name: string): CmsTheme | undefined => {
  return cmsThemeColors.find((theme) => theme.name === name);
};

export const formatDateShorthand = (date: Date): string => {
  return new Date(date).toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  });
};

// chrome bug where SGD isn't reconised as a currency code
export const formatCurrency = (value: number, currencyCode: string): string => {
  const isSGD = currencyCode === 'SGD';
  const formatter = new Intl.NumberFormat('en-US', {
    style: isSGD ? 'decimal' : 'currency',
    currency: currencyCode,
  });

  return `${isSGD ? '$' : ''}${formatter.format(value)}`;
};

export const mapSeoTags = (
  tags: TitleMetaLinkTag[],
): Record<string, string> => {
  const seoTags = {
    title: '',
    description: '',
    ogTitle: '',
    ogDescription: '',
    ogType: '',
    ogLocale: '',
    ogImage: '',
    ogImageAlt: '',
    twitterCard: '',
  };

  tags.forEach(({tag, content, attributes}) => {
    // eslint-disable-next-line default-case
    switch (tag) {
      case 'title':
        seoTags.title = content ?? '';
        break;
    }

    if (attributes) {
      const {name, property, content: attrContent} = attributes;
      // eslint-disable-next-line default-case
      switch (property) {
        case 'og:title':
          seoTags.ogTitle = attrContent;
          break;
        case 'og:description':
          seoTags.ogDescription = attrContent;
          break;
        case 'og:type':
          seoTags.ogType = attrContent;
          break;
        case 'og:locale':
          seoTags.ogLocale = attrContent;
          break;
        case 'og:image':
          seoTags.ogImage = attrContent;
          break;
        case 'og:image:alt':
          seoTags.ogImageAlt = attrContent;
          break;
        case 'twitter:card':
          seoTags.twitterCard = attrContent;
          break;
      }

      // eslint-disable-next-line default-case
      switch (name) {
        case 'description':
          seoTags.description = attrContent;
          break;
        case 'twitter:card':
          seoTags.twitterCard = attrContent;
          break;
      }
    }
  });

  return seoTags;
};

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {useEffect, useState} from 'react';
import {Box, Button, Flex, Text, Checkbox, Link} from '@chakra-ui/react';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {Listing} from '../../../graphql/generated';
import {assetInfo} from '../../../utils/own-a-piece-hero';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {useGeolocation} from '../../../hooks/geolocation';

interface ReviewPaymentDetails {
  onSubmit: () => void;
  onBackClick?: () => void;
  isLoading?: boolean;
  basketItems: Listing[] | [];
}

const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 30);

export const ReviewPaymentDetails: React.FC<ReviewPaymentDetails> = ({
  onSubmit,
  onBackClick,
  isLoading = false,
  basketItems,
}) => {
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const {drawerProduct, drawerQuantity} = useTransactionDrawerContext();
  const {currency, currencyPostfix} = useGeolocation();
  const currentAsset = assetInfo[drawerProduct as string];

  useEffect(() => {
    basketItems.forEach((item) => {
      setTotalPrice((val) => val + item.quantity * item.price.value);
    });
  }, [basketItems]);
  return (
    <Box background="white">
      <TransactionDrawerHeader
        title="Review"
        intro="Please review the details of your purchase"
        backLink={!!onBackClick}
        onBackClick={() => onBackClick && onBackClick()}
      />

      <Box mt="50px">
        <Flex as="p" justify="space-between" mb="15px">
          <Text as="span" color="tone3" textStyle="body3">
            Showpiece Title
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {drawerProduct && assetInfo
              ? assetInfo[drawerProduct as string]?.title
              : ''}
          </Text>
        </Flex>
        <Flex as="p" justify="space-between" mb="15px">
          <Text as="span" color="tone3" textStyle="body3">
            Quantity Purchased
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {drawerQuantity}
          </Text>
        </Flex>
        <Flex as="p" justify="space-between" mb="15px">
          <Text as="span" color="tone3" textStyle="body3">
            Subtotal Sale
          </Text>
          <Text as="span" color="charcoal" textStyle="body2">
            {currency}
            {totalPrice.toFixed(2)}
            <Text as="span" fontSize="0.7em">
              {currencyPostfix}
            </Text>
          </Text>
        </Flex>

        <Flex
          as="p"
          justify="space-between"
          mb="15px"
          pb="10px"
          borderBottom="1px solid"
          borderColor="tone1"
        >
          <Text as="span" color="charcoal" textStyle="h7">
            Total
          </Text>
          <Text as="span" color="aqua.500" textStyle="h6">
            {currency}
            {totalPrice.toFixed(2)}
            <Text as="span" fontSize="0.7em">
              {currencyPostfix}
            </Text>
          </Text>
        </Flex>

        <Flex as="p" justify="space-between" pb="10px">
          <Text as="span" color="tone3" textStyle="body3">
            Transaction fee
          </Text>
          <Text
            as="span"
            color="charcoal"
            textStyle="body2"
            textDecoration="line-through"
          >
            2.90%
          </Text>
        </Flex>
        <Text as="span" textStyle="body3" color="aqua.500">
          To celebrate the launch of the marketplace, we’re covering all
          transaction fees for buyers.
        </Text>
      </Box>

      <TransactionDrawerFooter variation="no-shadow">
        <Checkbox
          color="tone6"
          colorScheme="aqua"
          aria-label="Terms & conditions"
          alignItems="flex-start"
          textStyle="body7_product"
          fontSize="15px"
          mb="16px"
          sx={{
            '.chakra-checkbox__control': {
              marginTop: 3,
            },
            '.chakra-checkbox__label': {
              fontSize: '12px',
            },
          }}
          onChange={() => setTermsAndConditions(!termsAndConditions)}
        >
          Please tick this box to confirm you agree to the{' '}
          <Link
            href={currentAsset?.marketplaceTandCs}
            isExternal
            color="aqua.500"
            textDecoration="underline"
          >
            Marketplace Terms and Conditions
          </Link>
          <span>
            {' '}
            and the {`${currentAsset ? currentAsset?.title : ''}`}{' '}
            <Link
              href={currentAsset ? currentAsset?.termsHref : ''}
              target="_blank"
              rel="noreferrer"
              color="aqua.500"
              textDecoration="underline"
            >
              Terms of Purchase
            </Link>
          </span>
          .
        </Checkbox>
        <Button
          colorScheme="aqua"
          height="56px"
          width="100%"
          type="submit"
          isLoading={isLoading}
          isDisabled={termsAndConditions}
          onClick={() => onSubmit()}
        >
          Pay
        </Button>
      </TransactionDrawerFooter>
    </Box>
  );
};

import {Text, Flex} from '@chakra-ui/react';

interface AssetLimitErrorProps {
  allowedPiecesToPurchase: number;
  error?: boolean;
}
export const AssetLimitError: React.FC<AssetLimitErrorProps> = ({
  allowedPiecesToPurchase,
  error,
}) => {
  let allowedPiecesToPurchaseText = '';
  switch (allowedPiecesToPurchase) {
    case 1: {
      allowedPiecesToPurchaseText = 'you can only buy one more piece';
      break;
    }

    case 0: {
      allowedPiecesToPurchaseText = 'you can not buy anymore pieces';
      break;
    }

    default: {
      allowedPiecesToPurchaseText = `you can only buy up to ${allowedPiecesToPurchase} more pieces`;
      break;
    }
  }

  return (
    <Flex flexWrap="wrap" color={error ? 'stampRed' : ''}>
      <Text
        w="100%"
        textStyle="body3"
        textAlign={error ? 'left' : 'center'}
        mt="12px"
      >
        Sorry, {allowedPiecesToPurchaseText}.
      </Text>
      <Text w="100%" textStyle="body3" textAlign={error ? 'left' : 'center'}>
        There is a cap (10%) on the amount of an asset a user can own!
      </Text>
    </Flex>
  );
};

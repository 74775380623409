import {Box, Button} from '@chakra-ui/react';
import {TransactionDrawerHeader} from '../transaction-drawer/transaction-drawer-header';
import {TransactionDrawerFooter} from '../transaction-drawer/transaction-drawer-footer';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';

// interface NoPiecesAvailableProps {}

export const NoPiecesAvailable: React.FC = () => {
  const {resetDrawer} = useTransactionDrawerContext();

  return (
    <Box
      pos="absolute"
      zIndex={10}
      top={0}
      left={0}
      width="100%"
      height="100%"
      background="white"
      paddingTop={{base: '32px', md: '80px'}}
      paddingX={{base: '32px', md: '48px'}}
    >
      <TransactionDrawerHeader
        title="No Pieces available"
        intro="There are currently no Pieces available on the marketplace to purchase."
      />
      <TransactionDrawerFooter variation="no-shadow">
        <Button
          colorScheme="aqua"
          height="56px"
          display="block"
          width="100%"
          onClick={() => resetDrawer && resetDrawer()}
        >
          Exit
        </Button>
      </TransactionDrawerFooter>
    </Box>
  );
};

import {
  EDWARD_VIII_PENNY_ID,
  ONE_C_MAGENTA_ID,
  S3_ASSET_BUCKET,
  REIGNING_QUEENS_ID,
  ORIGIN_OF_SPECIES,
  VALENTINES_DAY_MASCARA,
} from '../shared/consts';

export const assetInfo = {
  [EDWARD_VIII_PENNY_ID as string]: {
    imgSrc: '/assets/front-coin.png',
    bgColor: 'royalNavy',
    title: 'Edward VIII Penny',
    subTitle: 'One of only two in the hands of private collectors',
    color: 'peach',
    href: '/edward8-penny',
    tandCsHref: `${S3_ASSET_BUCKET}/secondaryMarketTerms-edward.pdf`,
    termsHref: `${S3_ASSET_BUCKET}/terms-edward.pdf`,
    marketplaceTandCs: `${S3_ASSET_BUCKET}/secondaryMarketTerms-edward.pdf`,
  },
  [ONE_C_MAGENTA_ID as string]: {
    imgSrc: '/assets/1c-showpiece-card-no-bg.png',
    bgColor: 'midnight',
    title: '1c Magenta Stamp',
    subTitle: 'The most valuable item in the world by weight',
    color: 'stampRed',
    href: '/1c-magenta',
    termsHref: `${S3_ASSET_BUCKET}/terms.pdf`,
    tandCsHref: 'https://showpiece.com/terms-and-conditions',
    marketplaceTandCs: `${S3_ASSET_BUCKET}/secondaryMarketTerms-edward.pdf`,
  },
  [REIGNING_QUEENS_ID as string]: {
    imgSrc: '/assets/andy-warhol-reigning-queen.png',
    imgSize: {
      width: '270px',
      height: '335px',
    },
    bgColor: 'loulou',
    title: 'Reigning Queens HC 3/3',
    subTitle: 'One of Andy Warhol’s final portraits before his death in 1987.',
    color: 'queenBlue',
    href: '/reigning-queen',
    termsHref: `${S3_ASSET_BUCKET}/terms.pdf`,
    tandCsHref: 'https://showpiece.com/terms-and-conditions',
  },
  [ORIGIN_OF_SPECIES as string]: {
    imgSrc: '/assets/origin-of-species-1.jpg',
    imgSize: {
      width: '270px',
      height: '335px',
    },
    bgColor: 'black',
    title: 'Origin of Species',
    subTitle: 'Origin of Species 1st Edition',
    color: 'bookYellow',
    href: '/origin-of-species',
    termsHref: `${S3_ASSET_BUCKET}/terms.pdf`,
    tandCsHref: 'https://showpiece.com/terms-and-conditions',
  },
  [VALENTINES_DAY_MASCARA as string]: {
    imgSrc: '/assets/origin-of-species-1.jpg',
    imgSize: {
      width: '270px',
      height: '335px',
    },
    bgColor: 'black',
    title: 'Valentines Day Mascara',
    subTitle: 'Banksy',
    color: 'bookYellow',
    href: '/valentines-day-mascara',
    termsHref: `${S3_ASSET_BUCKET}/terms.pdf`,
    tandCsHref: 'https://showpiece.com/terms-and-conditions',
  },
};

import Image from 'next/image';
import {Box, Text, Flex, Icon} from '@chakra-ui/react';
import {IconChevronLeft} from '@tabler/icons';
import Link from 'next/link';
import {mixpanel} from '../../../utils/mixpanel';

export interface TransactionDrawerHeaderProps {
  title: string;
  url?: string;
  img?: string;
  intro?: string;
  backLink?: boolean;
  onBackClick?: () => void;
}

export const TransactionDrawerHeader: React.FC<
  TransactionDrawerHeaderProps
> = ({img, title, url, intro, onBackClick, backLink = false}) => {
  return (
    <Box>
      {backLink && (
        <Flex
          mb={{base: 5, lg: 0}}
          as="button"
          align="center"
          textColor="aqua.500"
          position={{lg: 'absolute'}}
          top="38px"
          left="44px"
          onClick={() => onBackClick && onBackClick()}
        >
          <Icon as={IconChevronLeft} />
          <Text textStyle="body4">Back</Text>
        </Flex>
      )}

      <Flex alignItems="center" justify="flex-start" mb={2}>
        {img && img.length > 0 && (
          <Box marginRight="15px" width="32px" height="32px">
            <Image src={img} width="32" height="32" alt={title} />
          </Box>
        )}
        <Text as="h3" textStyle="h5" color="black">
          {title}
        </Text>
      </Flex>
      {url && url.length > 0 && (
        <Link href={url}>
          <a target="_blank">
            <Text
              as="span"
              textStyle="body6"
              color="aqua.500"
              textDecoration="underline"
              mt={8}
              _hover={{
                textDecoration: 'none',
              }}
              onClick={() => mixpanel.track(`Read story ${title}`)}
            >
              Read the story
            </Text>
          </a>
        </Link>
      )}
      {intro && intro.length > 0 && (
        <Text
          textStyle="body2"
          mt="19px"
          dangerouslySetInnerHTML={{__html: intro}}
        />
      )}
    </Box>
  );
};

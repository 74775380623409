import {useMemo} from 'react';
import {AssetOverview, CollectionOverview} from '../graphql/generated';

export const getRecentPurchaseAsset = (arr: AssetOverview[]): AssetOverview => {
  return arr.reduce((a, b) => {
    return a.recentPurchaseDate > b.recentPurchaseDate ? a : b;
  });
};

const getRecentPurchaseAssetById = (
  arr: AssetOverview[],
  assetId: string,
): AssetOverview | undefined => {
  const assetTransactions = arr.filter((asset) => asset.assetId === assetId);
  if (!assetTransactions.length) return undefined;
  return assetTransactions.reduce((a, b) => {
    return a.recentPurchaseDate > b.recentPurchaseDate ? a : b;
  });
};

export const useMyCollectionOverview = (
  data?: CollectionOverview | null,
  forceAssetId?: string,
): {
  totalPieces: number;
  recentPurchasePieces: number;
  isFirstPurchase: boolean;
  totalValue: string;
  assetId?: string;
  currency?: string;
  recentPurchaseValue?: number;
  piecesAvailableForTransfer: number;
} => {
  let initialAsset = data?.assetOverviews?.[0];

  if (data?.assetOverviews && data?.assetOverviews.length > 1) {
    if (forceAssetId) {
      initialAsset = getRecentPurchaseAssetById(
        data?.assetOverviews,
        forceAssetId,
      );
    } else {
      initialAsset = getRecentPurchaseAsset(data?.assetOverviews);
    }
  }

  const totalPieces = initialAsset?.totalPiecesOwned || 0;

  const piecesAvailableForTransfer = initialAsset?.totalPiecesAvailable || 0;

  const recentPurchasePieces = initialAsset?.recentPurchasePieces || 0;

  const recentPurchaseValue = initialAsset?.recentPurchaseValue || 0;

  const isFirstPurchase = totalPieces === recentPurchasePieces;

  const assetId = initialAsset?.assetId;

  const currency = useMemo(
    () => initialAsset?.localValue?.currency || 'GBP',
    [initialAsset],
  );

  const formatter = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency,
    style: 'currency',
  });
  const totalValue = `${formatter.format(initialAsset?.sumOwnedAssets || 0)}`;
  return {
    totalPieces,
    recentPurchasePieces,
    isFirstPurchase,
    totalValue,
    assetId,
    currency,
    recentPurchaseValue: recentPurchaseValue / 100,
    piecesAvailableForTransfer,
  };
};

import React, {createContext, useContext, useState, useMemo} from 'react';
import {StripeModelExtraParams} from '../components/modals/components/stripe';

type ModalType = 'stripe' | '';

export type IFrameModalType = {
  isActive: boolean;
  isLoading: boolean;
  isBlocked: boolean;
  modalType: ModalType;
  src: string;
  data: StripeModelExtraParams | any;
  openModal: (
    type: ModalType,
    url: string,
    extraParams?: StripeModelExtraParams | any,
  ) => void;
  toggleModal: () => void;
  setPreventExit: (loading: boolean) => void;
  resetModal: () => void;
  setModalLoading: () => void;
};

export const IFrameModalContext = createContext<Partial<IFrameModalType>>({});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useIFrameModalContext = () => useContext(IFrameModalContext);

interface IFrameModalProps {
  children: React.ReactNode;
}

export const IFrameModalProvider: React.FC<IFrameModalProps> = ({children}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalType, setModalType] = useState<ModalType>('');
  const [src, setSrc] = useState<string>('');
  const [data, setData] = useState<any>({});

  const openModal = (
    type: string,
    url: string,
    extraParams?: StripeModelExtraParams | any,
  ) => {
    setIsActive(true);
    setModalType(type as ModalType);
    setSrc(url);
    setIsBlocked(false);

    if (extraParams) {
      setData(extraParams);
    }
  };
  const toggleModal = () => setIsActive((val) => !val);
  const setModalLoading = () => setIsLoading((val) => !val);

  const setPreventExit = (val: boolean) => setIsBlocked(val);

  const resetModal = () => {
    setIsActive(false);
    setModalType('');
    setSrc('');
    setIsBlocked(false);
    setIsLoading(true);
  };

  return (
    <IFrameModalContext.Provider
      value={useMemo(() => {
        return {
          src,
          data,
          isActive,
          isLoading,
          modalType,
          isBlocked,
          toggleModal,
          setPreventExit,
          resetModal,
          openModal,
          setModalLoading,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [src, data, isActive, modalType, isBlocked])}
    >
      {children}
    </IFrameModalContext.Provider>
  );
};

import React, {createContext, useContext, useState, useMemo} from 'react';

type DrawerTypes =
  | 'quickBuy'
  | 'sell'
  | 'verification'
  | 'purchase'
  | 'priceAlert'
  | '';

export type TransactionDrawerType = {
  isActive: boolean;
  isLoading: boolean;
  isBlocked: boolean;
  drawerProduct: string;
  drawerType: DrawerTypes;
  drawerQuantity: number;
  drawerListing: string;
  openDrawer: (
    type: DrawerTypes,
    productId: string,
    listingId?: string,
  ) => void;
  toggleDrawer: () => void;
  setDrawerLoading: (loading: boolean) => void;
  setPreventExit: (loading: boolean) => void;
  updateQuantity: (val: number) => void;
  resetDrawer: () => void;
};

export const transactionDrawerContext = createContext<
  Partial<TransactionDrawerType>
>({});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTransactionDrawerContext = () =>
  useContext(transactionDrawerContext);

interface TransactionDrawerProps {
  children: React.ReactNode;
}

export const TransactionDrawerProvider: React.FC<TransactionDrawerProps> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoading, setIsLoadning] = useState<boolean>(true);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  // Currently I use three useStates, leaving it like this for now, can update this when we link it to the BE
  const [drawerType, setDrawerType] = useState<DrawerTypes>('');
  const [drawerProduct, setDrawerProduct] = useState<string>('');
  const [drawerListing, setDrawerListing] = useState<string>('');
  const [drawerQuantity, setDrawerQuantity] = useState<number>(1);

  const openDrawer = (type: string, productId: string, listingId?: string) => {
    setIsActive(true);
    setDrawerType(type as DrawerTypes);
    setDrawerProduct(productId);
    setDrawerListing(listingId || '');
    setIsLoadning(true);
    setIsBlocked(false);
  };

  const toggleDrawer = () => setIsActive((val) => !val);

  const setDrawerLoading = (val: boolean) => setIsLoadning(val);

  const setPreventExit = (val: boolean) => setIsBlocked(val);

  const updateQuantity = (val: number) => setDrawerQuantity(val);

  const resetDrawer = () => {
    setIsActive(false);
    setDrawerType('');
    setIsLoadning(true);
    setIsBlocked(false);
    setDrawerQuantity(1);
  };

  return (
    <transactionDrawerContext.Provider
      value={useMemo(() => {
        return {
          isActive,
          isLoading,
          isBlocked,
          drawerProduct,
          drawerType,
          drawerQuantity,
          drawerListing,
          openDrawer,
          toggleDrawer,
          setPreventExit,
          updateQuantity,
          resetDrawer,
          setDrawerLoading,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        isActive,
        isLoading,
        drawerProduct,
        drawerType,
        drawerQuantity,
        drawerListing,
        isBlocked,
      ])}
    >
      {children}
    </transactionDrawerContext.Provider>
  );
};

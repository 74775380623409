import {useEffect, useState} from 'react';
import {useMyCollectionQuery} from '../graphql/generated';
import {useMyCollectionOverview} from './my-collection-overview';

export const useAssetPurchaseLimitHook = (
  quantity?: number,
  assetTotalPieces?: number,
  assetId?: string,
): {
  isPurchasedBlockedLoading: boolean;
  isPurchasedBlocked: boolean;
  allowedPiecesToPurchase: number;
} => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [allowedPiecesToPurchase, setAllowedPiecesToPurchase] = useState(0);
  const userCollection = useMyCollectionQuery({
    fetchPolicy: 'cache-and-network',
  });
  const {totalPieces} = useMyCollectionOverview(
    userCollection.data?.user?.collectionOverview,
    assetId,
  );

  useEffect(() => {
    if (!userCollection.loading && assetTotalPieces) {
      if (quantity) {
        const totalPiecesAllowedToBuy =
          assetTotalPieces > totalPieces ? assetTotalPieces - totalPieces : 0;
        setIsBlocked(quantity > totalPiecesAllowedToBuy);
        setAllowedPiecesToPurchase(totalPiecesAllowedToBuy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCollection.loading, assetTotalPieces, quantity]);

  return {
    isPurchasedBlockedLoading: userCollection.loading,
    isPurchasedBlocked: isBlocked,
    allowedPiecesToPurchase,
  };
};

import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import {useState, useLayoutEffect, useEffect} from 'react';
import {QuickBuyDrawer} from './quick-buy-drawer';
import {PurchaseDrawer} from './purchase-drawer';
import {SellDrawer} from './sell-drawer';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {TransactionDrawerErrorOverlay} from './transaction-drawer-error-overlay';
import {VerificationDrawer} from './verification-drawer';
import {PriceAlertDrawer} from './price-alert-drawer';

export const TransactionDrawer: React.FC = () => {
  const [drawerPlacement, setDrawerPlacement] = useState<'right' | 'bottom'>(
    'right',
  );
  const [toggleErrorOverlay, setToggleErrorOverlay] = useState(false);
  const {
    isActive,
    toggleDrawer,
    resetDrawer,
    drawerType,
    isLoading,
    isBlocked,
    setPreventExit,
  } = useTransactionDrawerContext();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window) {
        if (window.innerWidth > 768) {
          setDrawerPlacement('right');
        } else {
          setDrawerPlacement('bottom');
        }
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isBlocked && setPreventExit) {
      setPreventExit(false);
      setToggleErrorOverlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlocked]);

  return (
    <Drawer
      isOpen={isActive || false}
      placement={drawerPlacement}
      closeOnOverlayClick={false}
      size="sm"
      onClose={() => {
        if (!isBlocked) {
          if (toggleDrawer) toggleDrawer();
          if (resetDrawer) resetDrawer();
          setToggleErrorOverlay(false);
        } else {
          setToggleErrorOverlay(true);
        }
      }}
    >
      <DrawerOverlay />
      <DrawerContent
        background="white"
        maxW={drawerPlacement === 'right' ? '435px' : '100%'}
        paddingTop={{base: '32px', md: '80px'}}
        height={drawerPlacement === 'right' ? '100vh' : 'calc(100vh - 80px)'}
        borderTopRadius={drawerPlacement === 'right' ? '0' : '16px'}
      >
        <DrawerCloseButton color="aqua.500" top="30px" right="38px" size="lg" />

        <DrawerBody paddingY="0" paddingX={{base: '32px', md: '48px'}}>
          {drawerType === 'quickBuy' && <QuickBuyDrawer />}
          {drawerType === 'purchase' && <PurchaseDrawer />}
          {drawerType === 'sell' && <SellDrawer />}
          {drawerType === 'verification' && <VerificationDrawer />}
          {drawerType === 'priceAlert' && <PriceAlertDrawer />}
        </DrawerBody>

        <TransactionDrawerErrorOverlay
          isActive={toggleErrorOverlay}
          onReturnClick={() => setToggleErrorOverlay(false)}
        />

        {!!isLoading && (
          <Flex
            align="center"
            justify="center"
            w="100%"
            height="100%"
            position="absolute"
            left="0"
            top="0"
            background="white"
            zIndex={100}
          >
            <Spinner thickness="5px" color="midGrey" />
          </Flex>
        )}
      </DrawerContent>
    </Drawer>
  );
};

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VisuallyHidden,
} from '@chakra-ui/react';

interface InputCoverProps {
  label?: string;
  labelColor?: string;
  id?: string;
  visuallyHideLabel?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  errorMessageColor?: string;
  children: React.ReactNode;
}

export const InputCover: React.FC<InputCoverProps> = ({
  children,
  isInvalid,
  errorMessage,
  visuallyHideLabel = false,
  label,
  labelColor = 'tone3',
  id,
  errorMessageColor = 'stampRed',
}) => {
  return (
    <FormControl isInvalid={isInvalid} mt={2}>
      {!visuallyHideLabel && label && (
        <FormLabel color="tone3" mb={3} htmlFor={id} width="100%">
          {label}
        </FormLabel>
      )}
      {visuallyHideLabel && label && (
        <VisuallyHidden as="label" color={labelColor} mb={1} htmlFor={id}>
          {label}
        </VisuallyHidden>
      )}

      <Box position="relative">
        {children}
        {errorMessage && (
          <FormErrorMessage
            color={errorMessageColor}
            textStyle="body6_1c"
            fontFamily="Poppins"
            mt={1}
          >
            {errorMessage}
          </FormErrorMessage>
        )}
      </Box>
    </FormControl>
  );
};

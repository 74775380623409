import {ApolloError} from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import {useToast, UseToastOptions} from '@chakra-ui/react';

export const useAppToast = (): {
  showErrorToast: (messageOrError: string | Error, title?: string) => void;
  showInfoToast: (message: string, title?: string) => void;
  showToast: (opts: UseToastOptions) => void;
} => {
  const toast = useToast();
  const showErrorToast = (e: Error | string, title?: string) => {
    let message: string;
    if (typeof e === 'string') {
      message = e;
    } else if (e instanceof ApolloError) {
      // Any apollo error should have been sanitised on the server side wo we
      // are safe to show that
      message = e.message;
    } else {
      message = 'Something went wrong';
    }
    toast({
      title: title || 'Error',
      description: message,
      status: 'error',
      duration: 10000,
      isClosable: true,
    });
  };

  const showInfoToast = (message: string, title?: string) => {
    toast({
      title: title || 'Info',
      description: message,
      status: 'success',
      duration: 10000,
      isClosable: true,
    });
  };
  const showToast = (opts: UseToastOptions) => {
    toast({
      ...opts,
    });
  };

  return {
    showErrorToast,
    showInfoToast,
    showToast,
  };
};

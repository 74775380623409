import {Flex, Spinner} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import {useCallback, useEffect, useState} from 'react';
import {useIFrameModalContext} from '../../../context/iframe-modal';
import {useTransactionDrawerContext} from '../../../context/transaction-drawer';
import {useGetPaymentStatusMutation} from '../../../graphql/generated';
import {LogoWithText} from '../../global/logo-with-text';

export interface StripeModelExtraParams {
  paymentId: string;
}

/* eslint-disable jsx-a11y/iframe-has-title */
export const StripeModal: React.FC = () => {
  const router = useRouter();
  const {
    src,
    data,
    isBlocked,
    setModalLoading,
    resetModal,
    openModal,
    setPreventExit,
  } = useIFrameModalContext();
  const {resetDrawer} = useTransactionDrawerContext();

  const [getPaymentStatus] = useGetPaymentStatusMutation();

  const [isLoading, setIsLoading] = useState(true);
  const [executed, setIsExecuted] = useState(false);

  const handle3DSPayment = useCallback(async (paymentId: string) => {
    if (executed) return;

    getPaymentStatus({
      variables: {
        paymentId,
      },
    }).then((result) => {
      const status = result.data?.getPaymentStatus.status;
      const redirectUrl = result.data?.getPaymentStatus.url;
      switch (status) {
        // payment is still processing so will execute again in 5 seconds.
        case 'processing':
          setTimeout(async () => {
            await handle3DSPayment(paymentId);
          }, 5000);
          break;

        // another validation is required from the bank.
        case 'requires_action':
          if (resetModal) resetModal();
          if (openModal && redirectUrl)
            openModal('stripe', redirectUrl, {
              paymentId,
            });
          break;

        // on failure we will redirect to /my-account and on success to /queue.
        case 'fail':
        case 'succeeded':
          if (resetDrawer) resetDrawer();
          if (resetModal) resetModal();
          if (redirectUrl) router.push(redirectUrl);
          break;

        // case of multiple executions.
        default:
        case 'executed':
          break;
      }

      setIsExecuted(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener(
      'message',
      async (ev) => {
        // the 3DS completed so need to take action
        if (ev.data === '3DS-authentication-complete' && !executed) {
          setIsLoading(true);
          if (setPreventExit) setPreventExit(true);

          // call the backend to check the payment status
          if (data && data.paymentId) {
            await handle3DSPayment(data.paymentId);
          }
        }
      },
      false,
    );
  }, [data, executed, handle3DSPayment, setPreventExit]);

  return (
    <Flex
      w={{base: '400px'}}
      h={{base: '600px'}}
      flexWrap="wrap"
      align="center"
      justifyContent="center"
    >
      {isLoading && (
        <Flex w="100%" flexWrap="wrap" align="center" justifyContent="center">
          <LogoWithText />
          <Flex w="100%" align="center" justifyContent="center">
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="aqua.500"
              size="lg"
            />
          </Flex>
        </Flex>
      )}
      <iframe
        src={src}
        frameBorder="0"
        width={isLoading ? '0' : '100%'}
        height="100%"
        onLoad={() => {
          if (!isBlocked) setIsLoading(false);
          if (setModalLoading) setModalLoading();
        }}
      />
    </Flex>
  );
};
